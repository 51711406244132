import React from 'react';
import {Table, Button, Modal, message, Popconfirm} from 'antd';
import moment from 'moment';
import {CopyOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {t} from 'i18next';
import {useCopyToClipboard} from "usehooks-ts";
import {useHistory} from "react-router-dom";

const {confirm} = Modal;

interface CompanyTableProps {
    companies: any[];
    handleEdit: (company: any) => void;
    deleteGroup: (id: string) => void;
}

const MCompanyGroupsTable: React.FC<CompanyTableProps> = ({companies, handleEdit, deleteGroup}) => {
    const [_, copy] = useCopyToClipboard()
    const [messageApi, contextHolder] = message.useMessage();
    const history = useHistory();
    
    const columns = [
        {
            width: 50,
            height: 50,
            title: "", dataIndex: 'logo', key: 'name',
            render: (_: any, record: any) => {
                if (record.logo) {
                    return <img alt={`${record?.name}`}
                                src={`${process.env.REACT_APP_API_BASE_URL}/api/image/images/companygroups/${record?.logo}`}
                                style={{maxWidth: '50px', maxHeight: "50px", aspectRatio: "auto"}}/>
                } else {
                    return null
                }
            }


        },
        {title: t('name'), dataIndex: 'groupName', key: 'groupName'},
        {
            title: t('Başlama Zamanı'), dataIndex: 'startDate', key: 'startDate',
            render: (_: any, record: any) => `${moment(record.startDate).format("DD.MM.YYYY")}`,
        },
        {
            title: "",
            width: 150,
            render: (_: any, record: any) => {
                return (
                    <div className={"flex flex-row"}>
                        <Button type={"primary"} danger={false} className={"mr-3"}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleEdit(record)
                                }}
                                style={{marginRight: 8}}>
                            <EditOutlined />
                        </Button>
                        <Popconfirm
                            title="Şirket Grubu silme"
                            description="Seçtiğiniz şirket grubu silinecektir. Onaylıyor musunuz?"
                            onConfirm={(e) => {
                                e?.stopPropagation();
                                deleteGroup(record.id);
                            }}
                            onCancel={(e) => {
                                e?.stopPropagation();
                            }}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Button type={"primary"} danger={true}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}
                                    style={{marginRight: 8}}>
                                <DeleteOutlined/>
                            </Button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ];

    return (
        <>
            {contextHolder}
            <Table
                dataSource={companies}
                columns={columns}
                rowKey="id"
                rowClassName={"min-h-[60px] h-[60px] max-h-[60px]"}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            history.push(`/${record.id}/companies/${record.id}`);
                        }
                    };
                }}
            />
        </>
    );
};

export default MCompanyGroupsTable;
