import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, DatePicker, Select, Button} from 'antd';
import {OneToOneMeetingDto, usePostApiOneToOneMeetingUpdateMeetingMutation} from "../../api/services/OneToOneMeeting";
import moment from "moment";
import {setError, setLoading} from "../../features/app/appSlice";
import {ParameterType, usePostApiParameterGetParametersMutation} from "../../api/services/parameters";
import {extractParam} from "../../helper/paramHelper";
import {t} from "i18next";
import {useLazyGetApiUserTeamMembersQuery, UserDto} from "../../api/services/user";
import {
    PeriodDto,
    useLazyGetApiPeriodAllQuery,
    useLazyGetApiPeriodGetActivePeriodsQuery
} from "../../api/services/period";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {DataItem} from "../../models/DataItem";
import {
    useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery
} from "../../api/services/performanceCards";

const {TextArea} = Input;
const {Option} = Select;

interface OneOnOneDetailsPopupProps {
    visible: boolean;
    onClose: () => void;
    data: OneToOneMeetingDto;
    onSave: (updatedData: any) => void;
    toManager: boolean
    isReadOnly?: boolean;
}

const OneOnOneDetailsPopup: React.FC<OneOnOneDetailsPopupProps> = ({visible, onClose, data, onSave, toManager, isReadOnly}) => {
    const [form] = Form.useForm();
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch()
    const [getParameters, {data: parameters}] = usePostApiParameterGetParametersMutation()
    const [fetchActivePeriods, {data: activePeriods}] = useLazyGetApiPeriodAllQuery()
    const [getTeamUsers, {data: teamUsers}] = useLazyGetApiUserTeamMembersQuery()
    const [OKRMeetingName, setOKRMeetingName] = useState<DataItem[]>([])
    const [KPIMeetingName, setKPIMeetingName] = useState<DataItem[]>([])
    const [MeetingKind, setMeetingKind] = useState<DataItem[]>([])
    const [fetchActivePerformance, {data: activePerformance}] = useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery()
    const [updateMeeting] = usePostApiOneToOneMeetingUpdateMeetingMutation()

    const [selectedMember, setSelectedMember] = useState<string | null>(null);
    const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);

    const [IsManagerStarted, setIsManagerStarted] = useState<boolean>(false)
    const [IsInitiatorMe, setIsInitiatorMe] = useState<boolean>(false)

    useEffect(() => {
        if (data) {
            setIsManagerStarted(data.managerId === data.initiatorId)
            setIsInitiatorMe(data?.initiatorId !== user?.id)

            var iv = {
                employee: data?.employeeId,
                period: data?.periodId,
                name: data?.employeeName,
                date: moment(data?.meetingDate),
                type: data?.meetingTypeId,
                managerComment: data?.managerComment,
                employeeComment: data?.employeeComment,
                managerName: data?.managerName,
                meetingNameId: data?.meetingNameId,
            };
            form.setFieldsValue(iv);
        }
    }, [data])

    useEffect(() => {
        if (user) {
            getActivePeriods()
            FetcParams()
            FetchTeamUsers()
        }
    }, [user])

    const FetcParams = async () => {
        try {
            dispatch(setLoading(true));
            var parameters = await getParameters({
                companyId: user?.companyId ?? "",
                body: [
                    21 as ParameterType, //OKR Meeting Name
                    22 as ParameterType, //KPI Meeting Name
                    23 as ParameterType, //Meeting Kind
                ]
            }).unwrap()
            setOKRMeetingName(extractParam(parameters, 21))
            setKPIMeetingName(extractParam(parameters, 22))
            setMeetingKind(extractParam(parameters, 23))
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Parametreler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const FetchTeamUsers = async () => {
        try {
            dispatch(setLoading(true));
            await getTeamUsers().unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Kullanıcılar çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getActivePeriods = async () => {
        try {
            dispatch(setLoading(true));
            await fetchActivePeriods().unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getActivePerformance = async (employeeId: string, periodId: string) => {
        if (!employeeId || !periodId)
            return

        try {
            dispatch(setLoading(true));
            await fetchActivePerformance({
                employeeId: employeeId,
                periodId: periodId
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Performans sistemleri sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const updateMeetingDetails = async (details: any) => {
        var role = IsManagerStarted ? "Employee" : "Manager"

        try {
            dispatch(setLoading(true));
            await updateMeeting({
                meetingId: data.id,
                comment: IsManagerStarted ? details.employeeComment : details.managerComment,
                role: role
            }).unwrap()

            onSave(details);
            onClose();
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Performans sistemleri sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleFinish = (values: any) => {
        updateMeetingDetails(values)
    };

    const handlePeriodChange = (value: string) => {
        setSelectedPeriod(value);
        getActivePerformance(selectedMember ?? "", value)
    };

    const handleMemberChange = (value: string) => {
        setSelectedMember(value);
        getActivePerformance(value, selectedPeriod ?? "")
    };

    const getMeetingName = () => {
        return OKRMeetingName
        
        // if (activePerformance && activePerformance.length > 0) {
        //     if (activePerformance[0].performanceSystemType == "OKR") {
        //         return OKRMeetingName
        //     } else if (activePerformance[0].performanceSystemType == "KPI") {
        //         return KPIMeetingName
        //     }
        // } else {
        //     return OKRMeetingName
        // }
    }

    return (
        <Modal
            visible={visible}
            title="1-1 Görüşme Detayları"
            footer={null}
            onCancel={onClose}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={handleFinish}
            >
                {data?.managerId === user?.id &&
                    <Form.Item label="Çalışan" name="employee">
                        <Select
                            placeholder="Ekip üyesi seçiniz"
                            disabled={true}
                            onChange={handleMemberChange}
                            value={selectedMember || undefined} // Seçili eleman
                            showSearch={true}
                            filterOption={(input, option) =>
                                `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                            }
                            options={((teamUsers ?? []) as UserDto[]).map(k => {
                                return {value: k.id, label: `${k.firstName} ${k.lastName} ${k.title ? "(" + k.title + ")" : ""}`};
                                }
                            )}
                        />

                    </Form.Item>
                }
                {data?.managerId !== user?.id &&
                    <Form.Item label="Yönetici" name="managerName">
                        <input disabled={true} className={"h-[35px] w-full rounded-md border-[2px] p-2 text-gray-400"}/>
                    </Form.Item>
                }

                <Form.Item label="Dönem" name="period">
                    <Select placeholder="Dönem seçiniz"
                            disabled={true}
                            onChange={handlePeriodChange}
                            showSearch={true}
                            filterOption={(input, option) =>
                                `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                            }
                            options={((activePeriods ?? []) as PeriodDto[]).map(k => {
                                    return {value: k.id, label: `${k.periodName}`};
                                }
                            )}
                    />
                </Form.Item>
                <Form.Item label="Görüşme Adı" name="meetingNameId">
                    <Select placeholder="Görüşme adı seçiniz"
                            disabled={true}
                            showSearch={true}
                            filterOption={(input, option) =>
                                `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                            }
                            options={((getMeetingName() ?? []) as DataItem[]).map(k => {
                                    return {value: k.id, label: `${k.name}`};
                                }
                            )}
                    />
                </Form.Item>
                <Form.Item
                    label="Tarih"
                    name="date"
                    rules={[{required: true, message: 'Lütfen tarihi seçin!'}]}
                >
                    <DatePicker disabled={true} style={{width: '100%'}}/>
                </Form.Item>
                <Form.Item
                    label="Görüşme Şekli"
                    name="type"
                    rules={[{required: true, message: 'Lütfen görüşme şeklini seçin!'}]}
                >
                    <Select placeholder="Görüşme şekli seçiniz"
                            showSearch={true}
                            disabled={true}
                            filterOption={(input, option) =>
                                `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                            }
                            options={((MeetingKind ?? []) as DataItem[]).map(k => {
                                    return {value: k.id, label: `${k.name}`};
                                }
                            )}
                    />
                </Form.Item>
                <Form.Item label="Yönetici Yorumları" name="managerComment">
                    <TextArea rows={4}
                              placeholder="Yorumlarınızı girin"
                              disabled={(!(!IsManagerStarted && (data?.managerId === user?.id)) || data?.status === "Completed") || isReadOnly}
                    />
                </Form.Item>
                <Form.Item label="Çalışan Yorumları"
                           name="employeeComment">
                    <TextArea rows={4}
                              placeholder="Yorumlarınızı girin"
                              disabled={(!(IsManagerStarted && !(data?.managerId === user?.id)) || data?.status === "Completed") || isReadOnly}
                    />

                </Form.Item>
                {data?.status !== "Completed" && isReadOnly !== true &&
                    <div style={{textAlign: 'right'}}>
                        <Button onClick={onClose} style={{marginRight: 8}}>
                            İptal
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Kaydet
                        </Button>
                    </div>
                }
            </Form>
        </Modal>
    );
};

export default OneOnOneDetailsPopup;
