import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiPerformanceMatrixGetPerformanceMatrix: build.query<
      GetApiPerformanceMatrixGetPerformanceMatrixApiResponse,
      GetApiPerformanceMatrixGetPerformanceMatrixApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceMatrix/GetPerformanceMatrix`,
        params: { periodId: queryArg.periodId },
      }),
    }),
    getApiPerformanceMatrixGetCalibrationPerformanceMatrix: build.query<
      GetApiPerformanceMatrixGetCalibrationPerformanceMatrixApiResponse,
      GetApiPerformanceMatrixGetCalibrationPerformanceMatrixApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceMatrix/GetCalibrationPerformanceMatrix`,
        params: { periodId: queryArg.periodId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiPerformanceMatrixGetPerformanceMatrixApiResponse = unknown;
export type GetApiPerformanceMatrixGetPerformanceMatrixApiArg = {
  periodId?: string;
};
export type GetApiPerformanceMatrixGetCalibrationPerformanceMatrixApiResponse =
  unknown;
export type GetApiPerformanceMatrixGetCalibrationPerformanceMatrixApiArg = {
  periodId?: string;
};
export const {
  useGetApiPerformanceMatrixGetPerformanceMatrixQuery,
  useLazyGetApiPerformanceMatrixGetPerformanceMatrixQuery,
  useGetApiPerformanceMatrixGetCalibrationPerformanceMatrixQuery,
  useLazyGetApiPerformanceMatrixGetCalibrationPerformanceMatrixQuery,
} = injectedRtkApi;
