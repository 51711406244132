import React, {useEffect, useState} from 'react';
import {Button, Drawer, Form, message, Modal, notification, Space, Spin} from "antd";
import {t} from "i18next";
import CompanyTable from "../Companies/CompanyTable";
import {useDispatch, useSelector} from "react-redux";
import {
    CompanyDto,
    useLazyGetApiCompanyByGroupByGroupIdQuery,
    usePostApiCompanyMutation,
    usePutApiCompanyByIdMutation
} from "../../../api/services/company";
import {RootState} from "../../../app/store";
import {useParams} from "react-router-dom";
import {useLazyGetApiMCompanyGroupsCompanygroupsByIdQuery} from "../../../api/services/MCompanyGroup";
import {setError, setLoading} from "../../../features/app/appSlice";
import MCompanyForm from "./MCompanyForm";
import {
    useLazyGetApiMCompanyCompaniesByGroupQuery,
    usePostApiMCompanyCompaniesMutation,
    usePutApiMCompanyCompaniesByIdMutation
} from "../../../api/services/MCompany";
import moment from "moment/moment";
import dayjs from "dayjs";
import {CopyOutlined} from "@ant-design/icons";
import {useCopyToClipboard} from "usehooks-ts";

export interface IMCompanyGroupCompanies {
    groupId: string
}

const MCompanyGroupCompanies: React.FC<IMCompanyGroupCompanies> = ({groupId}) => {
    const [_, copy] = useCopyToClipboard()
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    const [getCompanies, {data: companies, isLoading: loading, error}] = useLazyGetApiMCompanyCompaniesByGroupQuery()
    const user = useSelector((state: RootState) => state.auth?.user);
    const [createCompany, {
        data: createdCompany,
        isSuccess: companyCreationSuccess,
        isLoading: companyCreating,
        error: creationError
    }] = usePostApiMCompanyCompaniesMutation()
    const [updateCompany, {
        isSuccess: companyUpdateSuccess,
        isLoading: companyUpdating,
        error: updateError
    }] = usePutApiMCompanyCompaniesByIdMutation()

    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState<CompanyDto | null>(null);
    const [showManagerUserPopup, setShowManagerUserPopup] = useState(false)

    const [form] = Form.useForm();
    const {confirm} = Modal;


    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
        form.resetFields();
    };

    const loadCompanies = async () => {
        try {
            dispatch(setLoading(true));
            await getCompanies({
                groupId: groupId
            }).unwrap()
        } catch (err) {
            dispatch(setError(t('sirketleri-cekerken-problem-olustu')));
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (groupId)
            loadCompanies();
    }, [groupId]);

    const onFinish = (values: any) => {

        const createUserFunc = async () => {
            dispatch(setLoading(true));
            try {

                const formData = new FormData();
                formData.append('Name', values.name);  // Zorunlu alan
                formData.append('Address', values.address);    // Zorunlu alan
                formData.append('Email', values.email);    // Zorunlu alan
                formData.append('CompanyGroupId', groupId);    // Zorunlu alan

                formData.append('StartDate', dayjs(values.startDate).format());    // Zorunlu alan
                formData.append('EndDate', values.endDate ? dayjs(values.endDate).format() : "");    // Zorunlu alan

                if (values.FaviconU !== undefined) {
                    formData.append('Favicon', values.FaviconU.fileList[0].originFileObj as File); // Dosya
                }

                if (values.LoginIconU !== undefined) {
                    formData.append('LoginIcon', values.LoginIconU.fileList[0].originFileObj as File); // Dosya
                }

                if (values.LogoU !== undefined) {
                    formData.append('Logo', values.LogoU.fileList[0].originFileObj as File); // Dosya
                }

                await createCompany({
                    // @ts-ignore
                    body: formData
                }).unwrap()

                setShowManagerUserPopup(true)
            } catch (err) {
                dispatch(setError(t('sirket-eklerken-hata-olustu')));
            } finally {
                dispatch(setLoading(false));
            }
        };

        const updateUserFunc = async () => {
            try {
                dispatch(setLoading(true));
                const formData = new FormData();
                formData.append('Name', values.name);  // Zorunlu alan
                formData.append('Address', values.address ?? "");    // Zorunlu alan
                formData.append('Email', values.email ?? "");    // Zorunlu alan
                formData.append('CompanyGroupId', groupId);    // Zorunlu alan

                formData.append('StartDate', dayjs(values.startDate).format());    // Zorunlu alan
                formData.append('EndDate', values.endDate ? dayjs(values.endDate).format() : "");    // Zorunlu alan

                if (values.FaviconU !== undefined) {
                    formData.append('Favicon', values.FaviconU.fileList[0].originFileObj as File); // Dosya
                }

                if (values.LoginIconU !== undefined) {
                    formData.append('LoginIcon', values.LoginIconU.fileList[0].originFileObj as File); // Dosya
                }

                if (values.LogoU !== undefined) {
                    formData.append('Logo', values.LogoU.fileList[0].originFileObj as File); // Dosya
                }


                await updateCompany({
                    id: selectedCompany?.id ?? "",
                    // @ts-ignore
                    body: formData
                }).unwrap()
            } catch (err) {
                dispatch(setError(t('failed-to-add-user')));
            } finally {
                dispatch(setLoading(false));
            }
        };

        if (!isEditMode)
            createUserFunc()
        else
            updateUserFunc()
    };

    useEffect(() => {
        if (companyCreationSuccess) {
            notification.success({message: t('yeni-sirket-eklendi')});
            closeDrawer();
            loadCompanies();
        }
    }, [companyCreationSuccess])

    useEffect(() => {
        if (companyUpdateSuccess) {
            closeDrawer();
            loadCompanies();
        }
    }, [companyUpdateSuccess])

    const handleEdit = (company: CompanyDto) => {
        setSelectedCompany(company);
        setIsEditMode(true);
        form.setFieldsValue({
            ...company,
            startDate: dayjs(company.startDate),
            endDate: company.endDate ? dayjs(company.endDate) : ""
        });
        showDrawer();
    };

    const handleAdd = () => {
        setIsEditMode(false);
        showDrawer();
    }

    if (loading) return null;
    if (error) return <div>{t('sirket-eklerken-hata-olustu')}</div>;

    const handleCancel = () => {
        setIsDrawerVisible(false);
        form.resetFields();
    };

    return (<>
        {contextHolder}
        <Button type="primary" style={{marginBottom: 16}} onClick={handleAdd}>
            {t('add-company')}
        </Button>
        <Modal
            title={isEditMode ? "Şirket Düzenleme" : "Şirket Ekleme"}
            width={720}
            onCancel={handleCancel}
            onClose={() => {
                handleCancel()
            }}
            closable={true}
            maskClosable={false}
            destroyOnClose={true}
            open={isDrawerVisible}
            footer={<Space>
                <Button onClick={handleCancel}>
                    İptal
                </Button>
                <Button type="primary" onClick={() => {
                    form.submit()
                }}>
                    Kaydet
                </Button>
            </Space>}
        >
            <Spin spinning={loading}>
                <MCompanyForm form={form}
                              selectedCompany={selectedCompany}
                              isEditMode={isEditMode}
                              onFinish={onFinish}/>
            </Spin>
        </Modal>
        <Modal title="Yönetici Kullancısı"
               open={showManagerUserPopup}
               onCancel={() => {
                   setShowManagerUserPopup(false)
               }}
               footer={null}>
            <p>Yönetici kullanıcısı şifresi aşağıdaki gibi oluşturuldu. Sıfırlayana dek tekrar gösterilmeyecek.</p>
            <p className={"mt-2"}><span className={"font-semibold"}>E Posta: </span> admin@co-hr.com.tr</p>
            <p className={"mt-5 h-[40px] w-full rounded-lg bg-amber-200 flex flex-row justify-between items-center p-2"}>
                <span>{(createdCompany as any)?.adminPass ?? ""}</span>
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        copy((createdCompany as any)?.adminPass ?? "")
                        messageApi.open({
                            type: 'success',
                            content: 'Şifre Kopyalandı',
                        });

                    }}>
                    <CopyOutlined/>
                </Button>
            </p>
        </Modal>
        <CompanyTable
            companies={companies as any[]}
            handleEdit={handleEdit}
            groupId={groupId}
            deleteGroup={(id: string) => {

            }}
        />

    </>)
};

export default MCompanyGroupCompanies;