import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Modal, Popover, Row, Select, Spin, Tooltip} from "antd";
import {
    PeriodDto,
    useLazyGetApiPeriodAllQuery,
    useLazyGetApiPeriodGetActivePeriodsQuery, useLazyGetApiPeriodWithCardsQuery
} from "../../api/services/period";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import ReportDetail from "../Reports/Components/ReportDetail";
import EvaluationDetail from "./EvaluationDetail";
import {
    useLazyGetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdQuery
} from "../../api/services/performanceCards";
import dayjs from "dayjs";
import {
    useLazyGetApiPerformanceMatrixGetCalibrationPerformanceMatrixQuery,
    useLazyGetApiPerformanceMatrixGetPerformanceMatrixQuery
} from "../../api/services/PerformanceMatrix";
import moment from "moment/moment";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {UserDto} from "../../api/services/user";
import {
    ManagerEvaluationCompletionType,
    useLazyGetApiManagerEvaluationGetCompletionInfoQuery,
    usePostApiManagerEvaluationMarkCompletionMutation
} from "../../api/services/ManagerEvaluation";

export interface ICalibrationEvaluation {

}

const {confirm, error} = Modal;
const CalibrationEvaluation: React.FC<ICalibrationEvaluation> = ({}) => {
    const [selectedBlock, setSelectedBlock] = useState<any | undefined>()
    const [selectedPeriod, setSelectedPeriod] = useState<PeriodDto | undefined>();
    const [fetchActivePeriods, {data: activePeriods}] = useLazyGetApiPeriodWithCardsQuery()
    const [fetchCardsForPeriod, {data: cards}] = useLazyGetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdQuery()
    const [getCompletion] = useLazyGetApiManagerEvaluationGetCompletionInfoQuery()
    const [markCompletion] = usePostApiManagerEvaluationMarkCompletionMutation()
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch()
    const [fetchPerformanceMatrix, {data: performanceMatrix}] = useLazyGetApiPerformanceMatrixGetCalibrationPerformanceMatrixQuery()
    const [isLoading, setIsLoading] = useState(true)
    const [firstEvaluationCompleted, setFirstEvaluationCompleted] = useState(false)
    const [secondEvaluationCompleted, setSecondEvaluationCompleted] = useState(false)
    const [calibrationType, setCalibrationType] = useState(0)

    useEffect(() => {
        if (user) {
            getActivePeriods()
        }
    }, [user])


    const getActivePeriods = async () => {
        try {
            setIsLoading(true)
            var periods = await fetchActivePeriods().unwrap()

            var finished = periods.filter(k => dayjs(k.endDate).isBefore())
            if (finished.length > 0) {
                var first = finished[0]
                setSelectedPeriod(first);
                await checkSelectedPeriod(first)
            } else {
                var firstPeriod = periods?.[0]
                setSelectedPeriod(firstPeriod);
                await checkSelectedPeriod(firstPeriod)
            }

            setIsLoading(false)
        } catch (err: any) {
            if (err.status != "PARSING_ERROR") {
                dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
                setIsLoading(false)
            }
        }
    }

    const getFirstCompletionInfo = async (periodId: string) => {
        try {
            dispatch(setLoading(true));
            var completionInfo = await getCompletion({
                managerId: user?.id ?? "",
                periodId: periodId,
                completionType: 1,
            }).unwrap()

            setFirstEvaluationCompleted(completionInfo != null)
        } catch (err: any) {
            setFirstEvaluationCompleted(false)
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getSecondCompletionInfo = async (periodId: string) => {
        try {
            dispatch(setLoading(true));
            var completionInfo = await getCompletion({
                managerId: user?.id ?? "",
                periodId: periodId,
                completionType: 2,
            }).unwrap()

            setSecondEvaluationCompleted(completionInfo != null)
        } catch (err: any) {
            setSecondEvaluationCompleted(false)
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getPerformanceMatrix = async (periodId: string) => {
        return fetchPerformanceMatrix({
            periodId: periodId
        }).unwrap()
    }

    const getCardsForPeriod = async (periodId: string) => {
        return fetchCardsForPeriod({
            periodId: periodId,
        }).unwrap()
    }
    const showDetail = (block: string) => {
        setSelectedBlock(block)
    }

    var getLegendTemplate = (color: string, title: string, desc: string, point: string, range: string) => (
        <Popover content={
            <>
                <p className={"font-medium"}>{desc}</p>
                <p className={"font-medium"}>{point}%</p>
                <p className={"font-light"}>{range}</p>
            </>
        }
                 overlayStyle={{maxWidth: 200}}
                 title={title}
                 overlayInnerStyle={{backgroundColor: color}}>
            <Card style={{backgroundColor: color}} className={"cursor-pointer min-w-[150px]"}>
                <p className={"font-semibold text-center"}>{title}</p>
            </Card>
        </Popover>
    )

    const getLegendData = () => {
        if (cards && cards.length > 0) {
            return cards?.[0].scale?.evaluationMetrics
        }

        return []
    }

    const getCellData = (rowIndex: number, colIndex: number) => {
        var cells = (performanceMatrix as any)?.cells
        if (cells && cells.length > 0) {
            var cell = cells.find((k: any) => k.whatId == rowIndex && k.howId == colIndex)

            return cell
        }

        return null

    }

    const hasWhatOverValue = (whatId: string) => {
        var what = (performanceMatrix as any)?.whats.find((k: any) => k.id == whatId)
        var whatCells = (performanceMatrix as any)?.cells.filter((k: any) => k.whatId == whatId)

        if (what.isQuotaChecked) {
            var sumOfResults = (whatCells ?? []).reduce((n: any, newVal: any, index: any) => {
                return n + ((newVal?.count / (newVal?.ratio == 0 ? 1 : newVal?.ratio)) * 100)
            }, 0)

            if (what.quotaResult < sumOfResults)
                return true
        }

        return false
    }

    const hasHowOverValue = (howId: string) => {
        var how = (performanceMatrix as any)?.hows.find((k: any) => k.id == howId)
        var howCells = (performanceMatrix as any)?.cells.filter((k: any) => k.howId == howId)

        if (how.isQuotaChecked) {
            var sumOfResults = (howCells ?? []).reduce((n: any, newVal: any, index: any) => {
                return n + ((newVal?.count / (newVal?.ratio == 0 ? 1 : newVal?.ratio)) * 100)
            }, 0)

            if (how.quotaResult < sumOfResults)
                return true
        }

        return false
    }

    const showCompleteConfirm = () => {
        if (validateCompletion()) return

        confirm({
            title: "Dönem Tamamlama",
            icon: <ExclamationCircleOutlined/>,
            content: "Yaptığınız düzenlemeler kaydedilerek dönem tamamlanacaktır. Onaylıyor musunuz?",
            okText: t('yes'),
            okType: 'danger',
            cancelText: t('no'),
            onOk() {
                markEvCompleted()
            },
        });
    };

    const validateCompletion = () => {
        var hasError = false

        var hows = (performanceMatrix as any)?.hows
        var whats = (performanceMatrix as any)?.whats

        for (const what of whats) {
            var has = hasWhatOverValue(what.id)
            if (has) {
                hasError = true
                break
            }
        }
        if (!hasError)
            for (const how of hows) {
                var has = hasHowOverValue(how.id)
                if (has) {
                    hasError = true
                    break
                }
            }

        if (hasError) {
            error({
                title: "Kalibrasyon Tamamlama",
                icon: <ExclamationCircleOutlined/>,
                content: "Bütün kalibrasyonlar henüz tamamlanmadı. Tamamlama sonrasında dönemi tamamlayabilirsiniz.",
                okText: t('OK'),
                okType: 'danger',
            });
        }
        return hasError
    }

    const isInEvaluationPeriod = () => {
        var isInFirstCalibration = dayjs(selectedPeriod?.startDateFirstCalibration).isBefore() &&
            dayjs(selectedPeriod?.endDateFirstCalibration).isAfter()
        var isInSecondtCalibration = dayjs(selectedPeriod?.startDateSecondCalibration).isBefore() &&
            dayjs(selectedPeriod?.endDateSecondCalibration).isAfter()

        return isInFirstCalibration || isInSecondtCalibration
    }

    const checkSelectedPeriod = async (selectedPeriod: PeriodDto) => {
        await getCardsForPeriod(selectedPeriod.id ?? "")
        await getPerformanceMatrix(selectedPeriod.id ?? "")
        await getFirstCompletionInfo(selectedPeriod.id ?? "")
        await getSecondCompletionInfo(selectedPeriod.id ?? "")

        setSelectedPeriod(selectedPeriod)

        var isInFirstCalibration = dayjs(selectedPeriod?.startDateFirstCalibration).isBefore() &&
            dayjs(selectedPeriod?.endDateFirstCalibration).isAfter()
        var isInSecondtCalibration = dayjs(selectedPeriod?.startDateSecondCalibration).isBefore() &&
            dayjs(selectedPeriod?.endDateSecondCalibration).isAfter()

        if (isInFirstCalibration) {
            setCalibrationType(1)
        } else if (isInSecondtCalibration) {
            setCalibrationType(2)
        }
    }

    const markEvCompleted = async () => {
        try {
            dispatch(setLoading(true));
            debugger
            await markCompletion({
                managerEvaluationCompletionDto: {
                    managerId: user?.id ?? "",
                    periodId: selectedPeriod?.id ?? "",
                    completionType: calibrationType as ManagerEvaluationCompletionType
                }
            }).unwrap()

            await getFirstCompletionInfo(selectedPeriod?.id ?? "")
            await getSecondCompletionInfo(selectedPeriod?.id ?? "")
        } catch (err: any) {

        } finally {
            dispatch(setLoading(false));
        }
    }

    const isEvaluationCompleted = () => {
        if (calibrationType == 1 && firstEvaluationCompleted)
            return true;

        if (calibrationType == 2 && secondEvaluationCompleted)
            return true;

        return false;
    }

    return (<>
        <Spin spinning={isLoading} className={"min-h-full"}>
            {isLoading && (<div className={"h-[300px]"}></div>)}
            {!isLoading &&
                <>
                    <div className={"flex flex-col"}>
                        <div className={"flex flex-row justify-between items-baseline"}>
                            <div className={"flex flex-row items-baseline"}>
                                <text className={"font-semibold mr-2"}>Seçili Dönem:</text>
                                <Select
                                    placeholder="Dönem Seçin"
                                    onChange={async (value) => {
                                        var v = (activePeriods ?? []).find(k => k.id == value)
                                        checkSelectedPeriod(v!)
                                    }}
                                    style={{width: 300}}
                                    value={selectedPeriod?.id || undefined} // Seçili eleman
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={((activePeriods ?? []) as PeriodDto[]).map(k => {
                                            return {value: k.id, label: `${k.periodName}`};
                                        }
                                    )}
                                />
                            </div>
                            <div>
                                {
                                    isEvaluationCompleted() &&
                                    <>
                                        <Alert message={"Değerlendirme Tamamlandı"}
                                               type="info"/>
                                    </>
                                }
                                {!isEvaluationCompleted() && isInEvaluationPeriod() &&
                                    <Button type="primary"
                                            htmlType="submit"
                                            className={"ml-2"}
                                            onClick={() => {
                                                showCompleteConfirm()
                                            }}>
                                        {calibrationType == 1 && "1. Kalibrasyonu Tamamla"}
                                        {calibrationType == 2 && "2. Kalibrasyonu Tamamla"}
                                    </Button>
                                }
                            </div>
                        </div>
                        {
                            !isInEvaluationPeriod() &&
                            <>
                                <Row className={"h-[400px] p-3"}>
                                    <Col span={24} className={"bg-[#E5E1DA] rounded-md h-full p-3"}>
                                        <div className={"h-full w-full flex justify-center items-center"}>
                                            <Alert message={"Henüz değerlendirmeler tamamlanmadı"}
                                                   type="warning"
                                                   className={"w-2/3 text-center"}/>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                        {
                            isInEvaluationPeriod() &&
                            <>
                                <div className="flex mt-8 align-center justify-center">
                                    <table
                                        className="border-collapse text-center rounded-lg overflow-hidden border-1 border-black ">
                                        <thead>
                                        <tr>
                                            <th
                                                rowSpan={2}
                                                className="diagonal border border-gray-300 bg-[#00906e] font-bold text-white p-2 w-[150px]"
                                            >
                                                <div className="text-top">BECERİLER</div>
                                                <div className="text-bottom">İŞ<br/> SONUÇLARI</div>
                                            </th>
                                            <th
                                                rowSpan={2}
                                                className="border border-gray-300 bg-[#00906e] font-bold p-2 text-white"
                                            >
                                                DEĞERLENDİRME KATEGORİSİ
                                            </th>
                                            <th
                                                colSpan={[...((performanceMatrix as any)?.whats ?? [])].length}
                                                className="border border-gray-300 bg-[#00906e] font-bold p-2 text-white"
                                            >
                                                "NASIL YAPTIK"
                                            </th>
                                            <th
                                                rowSpan={2}
                                                className="border border-gray-300 bg-[#00906e] font-bold p-2 text-white"
                                            >
                                                MAX KOTA<br />(NE YAPTIK)
                                            </th>
                                        </tr>
                                        <tr>
                                            {[...((performanceMatrix as any)?.hows ?? [])]
                                                .sort((a, b) => a.maxScore - b.maxScore)
                                                .map((k: any) =>
                                                    <th className="border border-gray-300 bg-[#c8ede5] p-2">
                                                        {k.result}
                                                    </th>
                                                )}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {[...((performanceMatrix as any)?.whats ?? [])]
                                            .sort((a, b) => b.maxScore - a.maxScore)
                                            .map((row: any, rowIndex: number) => (
                                                <tr>
                                                    {rowIndex == 0 &&
                                                        <td
                                                            rowSpan={[...((performanceMatrix as any)?.whats ?? [])].length}
                                                            className="border border-gray-300 bg-[#c8ede5] font-bold p-2"
                                                        >
                                                            "NE YAPTIK"
                                                        </td>
                                                    }
                                                    <td className="border border-gray-300 bg-[#c8ede5] p-2 h-[80px] w-[150px]  font-bold">{row.result}</td>
                                                    {[...((performanceMatrix as any)?.hows ?? [])]
                                                        .sort((a, b) => a.maxScore - b.maxScore)
                                                        .map((col: any, colIndex: number) => {
                                                            var data = getCellData(row.id, col.id)

                                                            return (
                                                                <td
                                                                    className="border h-[80px] w-[160px]">
                                                                    <Button style={{
                                                                        backgroundColor: data?.color ?? "",
                                                                        borderColor: "white",
                                                                        color: "black"
                                                                    }}
                                                                            className={"w-full h-full rounded-none transform hover:scale-110 hover:text-black hover:rounded-md hover:z-50"}
                                                                            onClick={() => {
                                                                                if (data.users.length > 0)
                                                                                    showDetail(data)
                                                                            }}>
                                                                        <div className={"flex flex-col items-center justify-center"}>
                                                                            <span
                                                                                className={"font-semibold text-[16px]"}>{((data?.count / (data?.ratio == 0 ? 1 : data?.ratio)) * 100).toFixed(2)}%</span>
                                                                            <span
                                                                                className={"text-[12px] -mt-1"}>{data?.count} Kişi</span>
                                                                        </div>
                                                                    </Button>

                                                                </td>
                                                            )
                                                        })
                                                    }

                                                    <td className="border border-gray-300 p-2 h-[80px] w-[150px]  font-bold">
                                                        <div className="relative h-full w-full">
                                                            <div
                                                                className={"flex justify-center items-center h-full w-full"}>
                                                                {row.quotaResult}%
                                                            </div>
                                                            <div className="absolute text-xl right-[5px] top-[5px]">
                                                                {
                                                                    hasWhatOverValue(row.id) &&
                                                                    <Tooltip
                                                                        overlayInnerStyle={{textAlign: "center"}}
                                                                        title="Belirlenen kota aşılmıştır. Lütfen kota sınırının içinde kalınız">
                                                                        <ExclamationCircleOutlined
                                                                            className="text-red-500"/>
                                                                    </Tooltip>
                                                                }
                                                            </div>
                                                        </div>

                                                    </td>
                                                </tr>
                                            ))}
                                        <tr>
                                            <td
                                                colSpan={2}
                                                className="border border-gray-300 bg-[#c8ede5] font-bold p-2 h-[80px]"
                                            >
                                                MAX KOTA (NASIL YAPTIK)
                                            </td>

                                            {[...((performanceMatrix as any)?.hows ?? [])]
                                                .sort((a, b) => a.maxScore - b.maxScore)
                                                .map((k: any) => {
                                                        var has = hasHowOverValue(k.id)
                                                        return (
                                                            <td className="border border-gray-300 p-2 font-bold">
                                                                <div className="relative h-full w-full">
                                                                    <div>
                                                                        {k.quotaResult}%
                                                                    </div>
                                                                    <div
                                                                        className="absolute text-xl right-[5px] -top-[20px]">
                                                                        {
                                                                            has &&
                                                                            <Tooltip
                                                                                overlayInnerStyle={{textAlign: "center"}}
                                                                                title="Belirlenen kota aşılmıştır. Lütfen kota sınırının içinde kalınız">
                                                                                <ExclamationCircleOutlined
                                                                                    className="text-red-500"/>
                                                                            </Tooltip>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        )
                                                    }
                                                )}

                                            <td className="border border-gray-300 p-2 font-bold">%100</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex flex-row mt-8 justify-between">
                                    {
                                        [...(getLegendData() ?? [])]
                                            .sort((k, l) => (k.order ?? 0) - (l.order ?? 0))
                                            .map(k => {
                                                return getLegendTemplate(k.color ?? "", k.personelType ?? "", k.description ?? "", k.ratio ?? "", k.pointRange ?? "")
                                            })
                                    }
                                </div>
                            </>
                        }
                    </div>
                </>}
        </Spin>
        <EvaluationDetail
            isModalActive={selectedBlock !== undefined}
            toggleModal={() => setSelectedBlock(undefined)}
            blockDetails={selectedBlock}
            matrix={performanceMatrix}
            period={selectedPeriod}
            reloadMatrix={() => {
                getPerformanceMatrix(selectedPeriod?.id ?? "")
            }}
        />
    </>)
};

export default CalibrationEvaluation;