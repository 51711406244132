import React, { useState } from 'react';
import { Card, Avatar, Descriptions, Button, Modal, Form, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../app/store';
import { t } from 'i18next';

const Profile: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth?.user);

    return (
        <Card style={{ maxWidth: 600, margin: 'auto', marginTop: 50 }}>
            <Avatar size={100} icon={<UserOutlined />} style={{ marginBottom: 20 }} />
            <Descriptions column={1}>
                <Descriptions.Item label="Employee Number">{user?.employeeNumber}</Descriptions.Item>
                <Descriptions.Item label={t('name-surname')}>{user?.firstName} {user?.lastName}</Descriptions.Item>
                <Descriptions.Item label={t('company')}>{user?.company?.name}</Descriptions.Item>
                <Descriptions.Item label={"Grup"}>{user?.company?.companyGroup?.groupName}</Descriptions.Item>
                <Descriptions.Item label={"Birim"}>{user?.department}</Descriptions.Item>
                <Descriptions.Item label={"1. Yönetici"}>{user?.managerName} {user?.managerSurname}</Descriptions.Item>
            </Descriptions>
        </Card>
    );
};

export default Profile;
