export const isColorShade = (color: string): boolean => {
    // '#' karakterini kaldır
    let hex = color.startsWith('#') ? color.slice(1) : color;

    // 3 haneli hex kodunu 6 haneye dönüştür
    if (hex.length === 3) {
        hex = hex.split('').map(c => c + c).join('');
    }

    // RGB bileşenlerini hesapla
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Parlaklık hesaplama: (r*299 + g*587 + b*114) / 1000
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Parlaklık değeri 128'den büyükse açık renk, değilse koyu renk olarak kabul edilir.
    return brightness > 128;
};

export const getTextColorFor = (color: string): string => {
    if(isColorShade(color)) {
        return "#000000"
    }
    
    return "#FFFFFF"
}