import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Switch, useHistory} from 'react-router-dom';
import {Provider, useSelector} from 'react-redux';
import {RootState, store} from './app/store';
import BaseView from './components/BaseView';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import Home from './pages/Home';
import {ConfigProvider} from "antd";
import NotFound from "./components/NotFound";
import TenantManager from "./components/TenantManager";
import trTR from 'antd/locale/tr_TR';
import ResetPasswordPage from "./pages/Users/ResetPasswordPage";

const App: React.FC = () => {
    // const [bg, setBg] = useState("#89A8B2")
    const themeConfig = useSelector((state: RootState) => state.app?.themeConfig);
    const DebugRouter = ({children}: { children: any }) => {
        const {location} = useHistory()
        if (process.env.NODE_ENV === 'development') {
            console.log(
                `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`,
            )
        }

        return children
    }

    return (
        <Router>
            <TenantManager>
                <ConfigProvider
                    locale={trTR}
                    theme={themeConfig}
                >
                    <BaseView>
                        <DebugRouter>

                            <Switch>
                                <Route path={`/:id/resetpassword/:token`} component={ResetPasswordPage}/>
                                <Route path="/:id/login" component={Login}/>
                                <PrivateRoute path="/:id/">
                                    <Home/>
                                </PrivateRoute>
                                <Route path={`/404`} component={NotFound}/>
                                <Route path="*" component={NotFound}/>
                            </Switch>

                        </DebugRouter>
                    </BaseView>
                </ConfigProvider>
            </TenantManager>
        </Router>
    );
};

export default App;
