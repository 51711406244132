import React, {useEffect, useState} from 'react';
import {Table, Button, Modal, Select, Input, DatePicker, Badge} from 'antd';
import OneOnOnePopup from '../../components/OneOnOnes/OneOnOnePopup';
import OneOnOneDetailsPopup from "../../components/OneOnOnes/OneOnOneDetailsPopup";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {useLazyGetApiOneToOneMeetingGetMeetingsByManagerByManagerIdQuery} from "../../api/services/OneToOneMeeting";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import moment from "moment/moment";
import {useLazyGetApiUserTeamMembersQuery, UserDto} from "../../api/services/user";

const WithEmployees: React.FC = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isDetailsPopupVisible, setIsDetailsPopupVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const [fetchMeetings, {data: meetings}] = useLazyGetApiOneToOneMeetingGetMeetingsByManagerByManagerIdQuery()
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch()
    const [getTeamUsers, {data: teamUsers}] = useLazyGetApiUserTeamMembersQuery()

    useEffect(() => {
        if (user) {
            getMeetings()
            FetchTeamUsers()
        }
    }, [user])

    const getMeetings = async () => {
        try {
            dispatch(setLoading(true));
            await fetchMeetings({
                managerId: user?.id ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Görüşmeler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleNewMeeting = () => {
        setIsPopupVisible(true);
    };

    const handleRowClick = (record: any) => {
        setSelectedRecord(record);
        setIsDetailsPopupVisible(true);
    };

    const FetchTeamUsers = async () => {
        try {
            dispatch(setLoading(true));
            await getTeamUsers().unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Kullanıcılar çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const columns = [
        {title: 'Çalışan', dataIndex: 'employeeName', key: 'employeeName'},
        {title: 'Dönem', dataIndex: 'periodName', key: 'periodName'},
        {title: 'Görüşme Adı', dataIndex: 'meetingName', key: 'meetingName'},
        {title: 'Tip', dataIndex: 'meetingTypeName', key: 'meetingTypeName'},
        {
            title: 'Tarih', dataIndex: 'meetingDate', key: 'meetingDate',
            render: (_: any, record: any) => moment(record.meetingDate).format('DD.MM.YYYY'),
        },

        {
            title: 'Durum', dataIndex: 'status', key: 'status',
            render: (_: any, record: any) => {
                if (record.status == 'Waiting' && !record.employeeComment) {
                    return <Badge status="error" text={t('Çalışan yorumu bekleniyor')}/>
                } else if (record.status == 'Waiting' && !record.managerComment) {
                    return <Badge status="error" text={t('Yönetici yorumu bekleniyor')}/>
                } else if (record.status == 'Completed') {
                    return <Badge status="success" text={t('Tamamlandı')}/>
                }
            }
        },
    ];

    return (
        <div>
            {((teamUsers as UserDto[])?.length ?? 0) > 0 &&
                <Button type="primary" onClick={handleNewMeeting} style={{marginBottom: 16}}>
                    Görüşme Başlat
                </Button>
            }
            <Table dataSource={meetings}
                   columns={columns}
                   rowKey="id"
                   pagination={false}
                   onRow={(record) => ({
                       onClick: () => handleRowClick(record),
                   })}/>

            <OneOnOnePopup
                visible={isPopupVisible}
                onClose={() => setIsPopupVisible(false)}
                toManager={false}
                onSave={() => {
                    getMeetings()
                }}
            />

            <OneOnOneDetailsPopup
                visible={isDetailsPopupVisible}
                onClose={() => {
                    setIsDetailsPopupVisible(false);
                    setSelectedRecord(null)
                }}
                data={selectedRecord}
                toManager={false}
                onSave={(updatedData) => {
                    getMeetings()
                }}
            />
        </div>
    );
};

export default WithEmployees;
