import React, {useEffect, useState} from 'react';
import {data, IDetailItem} from "../Reports/data";
import ReportDetailTab, {ReportDetailItemType} from "../Reports/Components/ReportDetailTab";
import {Avatar, Col, List, Modal, Row, Tabs} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {PerformanceCardDto, PeriodDto} from "../../api/services/period";
import {UserDto} from "../../api/services/user";
import EvaluationTab from "./EvaluationTab";
import {setError} from "../../features/app/appSlice";
import {t} from "i18next";
import {
    ManagerEvaluationDto,
    useLazyGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery,
    usePostApiManagerEvaluationSaveFirstCalibrationMutation,
    usePostApiManagerEvaluationSaveManagerEvaluationMutation
} from "../../api/services/ManagerEvaluation";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";

export interface IEvaluationDetail {
    isModalActive: boolean
    toggleModal: (isActive: boolean) => void
    blockDetails: any | undefined
    matrix: any | undefined
    period: PeriodDto | undefined
    reloadMatrix: () => void
}

const EvaluationDetail: React.FC<IEvaluationDetail> = ({
                                                           isModalActive,
                                                           toggleModal,
                                                           blockDetails,
                                                           matrix,
                                                           period,
                                                           reloadMatrix
                                                       }) => {
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch()
    const [saveEvaluation] = usePostApiManagerEvaluationSaveFirstCalibrationMutation()
    const [isActive, setIsActive] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<any | undefined>()
    const [isEvaluating, setIsEvaluating] = useState(false)
    const [evaluationCompleted, setEvaluationCompleted] = useState(false)
    const [getManagerEvaluations, {data: oldEvaluations}] = useLazyGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery()
    
    useEffect(() => {
        if (blockDetails) {
            if (blockDetails.users.length > 0) {
                var user = blockDetails.users[0]
                setSelectedItem(user)
            }
        }
    }, [blockDetails]);

    useEffect(() => {
        setIsActive(isModalActive)
    }, [isModalActive]);

    const handleCancel = () => {
        setIsActive(false)
        toggleModal(false)
    };


    const tabs = [
        {
            label: `Performans Değerlendirmesi`,
            key: "ps1",
            children: <EvaluationTab
                selectedItem={{...selectedItem, scaleId: matrix?.scaleId, period: period}}
                evaluating={isEvaluating}
                evaluationCompleted={evaluationCompleted}
                howSelected={(cardId: string, ev, id) => {
                    SaveEvaluation(cardId, id, null)
                }}
                whatSelected={(cardId: string, ev, id) => {
                    SaveEvaluation(cardId, null, id)
                }}
            />

        },
        {
            label: `Performans Kartları`,
            key: "ps2",
            children: <ReportDetailTab type={ReportDetailItemType.performanceCards}
                                       selectedItem={{
                                           ...selectedItem,
                                           periodId: period?.id
                                       }}/>
        },
        {
            label: `1-1 Görüşmeler`,
            key: "ps3",
            children: <ReportDetailTab type={ReportDetailItemType.OntoOneMeetins} selectedItem={selectedItem}/>
        },
        {
            label: `Geri Bildirimler`,
            key: "ps4",
            children: <ReportDetailTab type={ReportDetailItemType.Feedbacks} selectedItem={selectedItem}/>
        },
    ]

    const SaveEvaluation = async (cardId: string, how: string | null, what: string | null) => {

        try {
            setIsEvaluating(true)
            debugger
            await saveEvaluation({
                managerEvaluationDto: {
                    userId: selectedItem?.id ?? "",
                    managerId: user?.id ?? "",
                    periodId: period?.id ?? "",
                    cardId: cardId ?? "",
                    how: how,
                    what: what
                }
            }).unwrap()
            reloadMatrix()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Periyoda ait kart çekilirken hata oluştu')));
        } finally {
            setIsEvaluating(false)
        }
    }
    
    return (<>
            <Modal
                title={<>
                    <h2>{blockDetails?.metricName ?? ""}</h2>
                </>}
                visible={isActive}
                onCancel={handleCancel}
                maskClosable={false}
                footer={null}
                width={1200}
                height={500}
                destroyOnClose={true}
            >
                <div className={"flex flex-row mt-5 w-full h-[600px]"}>
                    <Row gutter={[16, 30]} className={"w-full h-full"}>
                        <Col span={6} className={"bg-[#B3C8CF] rounded-md p-3 h-[calc(100%-0px)] "}>
                            <List
                                className={"h-full overflow-scroll no-scrollbar"}
                                itemLayout="horizontal"
                                dataSource={(blockDetails?.users ?? [])}
                                renderItem={(item: any, index) => (
                                    <List.Item
                                        style={{padding: '0px', height: "60px"}}
                                        onClick={() => {
                                            setSelectedItem(item)
                                        }}>
                                        <List.Item.Meta
                                            style={{backgroundColor: selectedItem?.id === item.id ? "#89A8B2" : "transparent"}}
                                            className={`bg-[#B3C8CF] px-2 py-1 cursor-pointer transform rounded-md h-[60px]`}
                                            avatar={<Avatar icon={<UserOutlined/>}/>}
                                            title={`${item.name}`}
                                            description={`${item.title}`}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col span={18} className={"h-[calc(100%-50px)]"}>
                            <>
                                {selectedItem &&
                                    <div className={"flex flex-row items-center ml-2"}>
                                        <Avatar icon={<UserOutlined/>} size={"large"}/>
                                        <h2 className={"ml-2"}>{selectedItem?.name} ({selectedItem?.title ?? ""})</h2>
                                    </div>
                                }
                            </>
                            <Tabs
                                className={"mt-3"}
                                rootClassName={"max-h-full overflow-scroll"}
                                hideAdd={true}
                                type="card"
                                items={tabs}
                            />

                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
};

export default EvaluationDetail;