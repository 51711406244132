import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiMCompanyGroupsCompanygroups: build.query<
      GetApiMCompanyGroupsCompanygroupsApiResponse,
      GetApiMCompanyGroupsCompanygroupsApiArg
    >({
      query: () => ({ url: `/api/MCompanyGroups/companygroups` }),
    }),
    postApiMCompanyGroupsCompanygroups: build.mutation<
      PostApiMCompanyGroupsCompanygroupsApiResponse,
      PostApiMCompanyGroupsCompanygroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompanyGroups/companygroups`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getApiMCompanyGroupsCompanygroupsById: build.query<
      GetApiMCompanyGroupsCompanygroupsByIdApiResponse,
      GetApiMCompanyGroupsCompanygroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompanyGroups/companygroups/${queryArg.id}`,
      }),
    }),
    putApiMCompanyGroupsCompanygroupsById: build.mutation<
      PutApiMCompanyGroupsCompanygroupsByIdApiResponse,
      PutApiMCompanyGroupsCompanygroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompanyGroups/companygroups/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteApiMCompanyGroupsCompanygroupsById: build.mutation<
      DeleteApiMCompanyGroupsCompanygroupsByIdApiResponse,
      DeleteApiMCompanyGroupsCompanygroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompanyGroups/companygroups/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiMCompanyGroupsCompanygroupsApiResponse = unknown;
export type GetApiMCompanyGroupsCompanygroupsApiArg = void;
export type PostApiMCompanyGroupsCompanygroupsApiResponse = unknown;
export type PostApiMCompanyGroupsCompanygroupsApiArg = {
  body: {
    GroupName?: string;
    Nickname?: string;
    StartDate?: string;
    EndDate?: string;
    Favicon?: Blob;
    LoginIcon?: Blob;
    Logo?: Blob;
  };
};
export type GetApiMCompanyGroupsCompanygroupsByIdApiResponse = unknown;
export type GetApiMCompanyGroupsCompanygroupsByIdApiArg = {
  id: string;
};
export type PutApiMCompanyGroupsCompanygroupsByIdApiResponse = unknown;
export type PutApiMCompanyGroupsCompanygroupsByIdApiArg = {
  id: string;
  body: {
    GroupName?: string;
    Nickname?: string;
    StartDate?: string;
    EndDate?: string;
    Favicon?: Blob;
    LoginIcon?: Blob;
    Logo?: Blob;
  };
};
export type DeleteApiMCompanyGroupsCompanygroupsByIdApiResponse = unknown;
export type DeleteApiMCompanyGroupsCompanygroupsByIdApiArg = {
  id: string;
};
export const {
  useGetApiMCompanyGroupsCompanygroupsQuery,
  useLazyGetApiMCompanyGroupsCompanygroupsQuery,
  usePostApiMCompanyGroupsCompanygroupsMutation,
  useGetApiMCompanyGroupsCompanygroupsByIdQuery,
  useLazyGetApiMCompanyGroupsCompanygroupsByIdQuery,
  usePutApiMCompanyGroupsCompanygroupsByIdMutation,
  useDeleteApiMCompanyGroupsCompanygroupsByIdMutation,
} = injectedRtkApi;
