import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiDialogAddDialogSchedule: build.mutation<
      PostApiDialogAddDialogScheduleApiResponse,
      PostApiDialogAddDialogScheduleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Dialog/add-dialog-schedule`,
        method: "POST",
        body: queryArg.dialogScheduleDto,
      }),
    }),
    getApiLogs: build.query<GetApiLogsApiResponse, GetApiLogsApiArg>({
      query: (queryArg) => ({
        url: `/api/Logs`,
        params: { date: queryArg.date },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiDialogAddDialogScheduleApiResponse = unknown;
export type PostApiDialogAddDialogScheduleApiArg = {
  dialogScheduleDto: DialogScheduleDto;
};
export type GetApiLogsApiResponse = unknown;
export type GetApiLogsApiArg = {
  date?: string;
};
export type DialogScheduleDto = {
  periodId?: string;
  performanceSystemId?: string;
  dialogTypeId?: string;
  startDate?: string;
  endDate?: string;
};
export const {
  usePostApiDialogAddDialogScheduleMutation,
  useGetApiLogsQuery,
  useLazyGetApiLogsQuery,
} = injectedRtkApi;
