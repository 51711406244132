import { useSelector } from 'react-redux';
import {RootState} from "../../app/store";


// Kullanıcının rollerini kontrol eden hook
export function useIsInRole(requiredRoleIds: string[]): boolean {
    const userRoles = useSelector(
        (state: RootState) => state.persisted?.authorizationInfo?.roleIds || []
    ) as string[];

    // Verilen requiredRoleIds içerisindeki herhangi bir id, kullanıcının rollerinde varsa true döner.
    return requiredRoleIds.some(roleId => userRoles.includes(roleId));
}

// Kullanıcının izinlerini kontrol eden hook
export function useIsInPermission(requiredPermissionIds: string[]): boolean {
    const userPermissions = useSelector(
        (state: RootState) => state.persisted?.authorizationInfo?.permissionIds || []
    ) as string[];

    // Verilen requiredPermissionIds içerisindeki herhangi bir id, kullanıcının izinleri arasında varsa true döner.
    return requiredPermissionIds.some(permissionId => userPermissions.includes(permissionId));
}
