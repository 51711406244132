import React from 'react';
import {EvaluationMetricDto, ScaleDetailDto} from "../../api/services/scale";
import {ColorPicker, Form, FormInstance, Input} from "antd";

export interface IRowScaleMetric {
    row: EvaluationMetricDto;
    onRowChange: (key: string, updatedRow: ScaleDetailDto) => void;
    index: number
    form: FormInstance
}

const RowScaleMetric: React.FC<IRowScaleMetric> = ({row, form, onRowChange, index}) => {
    var defaultColors = ["#acb8c0", "#fdd1f3", "#e3dbfe", "#3b6fd8", "#00cce6"]

    return (<>
            <tr>
                <td className={"w-[50px]"}>

                    <Form.Item
                        style={{display: "none"}}
                        name={[`evaluationMetric`, index ?? 0, "id"]}
                        initialValue={row?.id ?? ""}
                    >
                        <input type={"hidden"}/>
                    </Form.Item>
                    <Form.Item
                        style={{display: "none"}}
                        name={[`evaluationMetric`, index ?? 0, "order"]}
                        initialValue={row?.order ?? index}
                    >
                        <input type={"hidden"}/>
                    </Form.Item>

                    <Form.Item
                        name={[`evaluationMetric`, index ?? 0, "color"]}
                        initialValue={row?.color ?? defaultColors[defaultColors.length - 1 - index]}
                        getValueFromEvent={color => {
                            return color.toHexString()
                        }}
                    >
                        <ColorPicker defaultValue={row?.color ?? defaultColors[defaultColors.length - 1 - index]}
                                     onChangeComplete={(color) => {
                                         var fieldName = `evaluationMetric_${index ?? 0}_color`
                                         var strColor = color.toHexString()
                                         form.setFieldsValue({fieldName: strColor});
                                     }}
                                     showText/>
                    </Form.Item>
                </td>
                <td>
                    <Form.Item
                        name={[`evaluationMetric`, index ?? 0, "personelType"]}
                        rules={[
                            {required: true, message: "Bir Çalışan Tipi yazmalısınız"}
                        ]}
                        initialValue={row.personelType}
                    >
                        <Input style={{height: 40, margin: 5}}/>
                    </Form.Item>
                </td>
                <td>
                    <Form.Item
                        name={[`evaluationMetric`, index ?? 0, "description"]}
                        rules={[
                            {required: true, message: "Açıklama yazmalısınız"}
                        ]}
                        initialValue={row.description}
                    >
                        <Input.TextArea value={row.description ?? ''} className="no-resize"
                                        style={{height: 40, margin: 5}}/>
                    </Form.Item>
                </td>
                <td>
                    <Form.Item
                        name={[`evaluationMetric`, index ?? 0, "ratio"]}

                        rules={[
                            {
                                required: true, message: "Bir oran yazmalısınız",

                            }
                        ]}
                        initialValue={row.ratio}
                    >
                        <Input type="number" style={{height: 40, margin: 5}} min={0} max={100} suffix="%"
                               className="no-spin"
                               onWheel={(e) => e.currentTarget.blur()}/>
                    </Form.Item>
                </td>
                <td>
                    <Form.Item
                        name={[`evaluationMetric`, index ?? 0, "pointRange"]}
                        rules={[
                            {required: true, message: "Puan aralığı yazmalısınız"}
                        ]}
                        initialValue={row.pointRange}
                    >
                        <Input style={{height: 40, margin: 5}}/>
                    </Form.Item>
                </td>
            </tr>

        </>
    )
};

export default RowScaleMetric;