import React, {useEffect, useState} from 'react';
import {Table, Button, Drawer, Form, Input, DatePicker, Space, Badge, Modal, Card} from 'antd';
import moment from 'moment';
import {t} from 'i18next';
import {PeriodDto, useLazyGetApiPeriodAllQuery, usePostApiPeriodSaveMutation} from '../../api/services/period';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading, setError} from '../../features/app/appSlice';
import {RootState} from '../../app/store';
import dayjs from "dayjs";

const {Column} = Table;

interface Period {
    id: number;
    number: string;
    name: string;
    startDate: string;
    endDate: string;
}

const PeriodDefinition: React.FC = () => {
    const [visible, setVisible] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState<PeriodDto | null>(null);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth?.user);

    const [fetchPeriods, {data: periods}] = useLazyGetApiPeriodAllQuery()
    const [savePeriod] = usePostApiPeriodSaveMutation()

    useEffect(() => {
        fetchPeriodDatas()
    }, [user])

    const fetchPeriodDatas = async () => {
        try {
            dispatch(setLoading(true));

            await fetchPeriods().unwrap()
        } catch (err) {
            dispatch(setError(t('Dönemler yüklenirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const SavePeriodDatas = async (data: any) => {
        try {
            dispatch(setLoading(true));

            await savePeriod({
                    periodDto: {
                        periodName: data.name,
                        periodNo: data.number,
                        endDate: data.endDate,
                        startDate: data.startDate,
                        id: selectedPeriod?.id,
                        companyGroupId: user?.company?.companyGroupId ?? "",
                        endDateFirstManager: data.endDate1,
                        startDateFirstManager: data.startDate1,
                        endDateFirstCalibration: data.endDate2,
                        startDateFirstCalibration: data.startDate2,
                        endDateSecondCalibration: data.endDate3,
                        startDateSecondCalibration: data.startDate3,

                    }
                }
            ).unwrap()

            fetchPeriodDatas()
        } catch (err) {
            dispatch(setError(t('Dönem kaydedilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const showDrawer = () => {
        var endDate = dayjs().add(5, "day")
        
        var payload: any = {
            number: getPeriodNo(),
            startDate: dayjs().add(1, "day"),
            endDate: endDate,
            startDate1: endDate,
            endDate1: endDate.add(5, "days"),
            startDate2: endDate.add(5, "days"),
            endDate2: endDate.add(10, "days"),
            startDate3: endDate.add(10, "days"),
            endDate3: endDate.add(15, "days"),
        }
        
        if (selectedPeriod) {
            payload = {
                number: selectedPeriod?.periodNo ?? "",
                name: selectedPeriod?.periodName ?? "",
                startDate: dayjs(selectedPeriod?.startDate),
                endDate: dayjs(selectedPeriod?.endDate),
                startDate1: dayjs(selectedPeriod?.startDateFirstManager),
                endDate1: dayjs(selectedPeriod?.endDateFirstManager),
                startDate2: dayjs(selectedPeriod?.startDateFirstCalibration),
                endDate2: dayjs(selectedPeriod?.endDateFirstCalibration),
                startDate3: dayjs(selectedPeriod?.startDateSecondCalibration),
                endDate3: dayjs(selectedPeriod?.endDateSecondCalibration),
            }
        }

        form.setFieldsValue(payload)


        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (visible == false) {
            setSelectedPeriod(null);
            form.resetFields();
        }
    }, [visible])


    const onFinish = async (values: any) => {
        await SavePeriodDatas(values)

        onClose();
    };

    const handleEdit = (record: PeriodDto) => {
        setSelectedPeriod(record);

    };

    useEffect(() => {
        if (selectedPeriod) {
            showDrawer();
        }
    }, [selectedPeriod]);

    const getStatusBadge = (record: Period) => {
        const now = moment();
        if (moment(record.endDate).isBefore(now)) {
            return <Badge status="error" text={t('bitti')}/>;
        } else if (moment(record.startDate).isAfter(now)) {
            return <Badge status="warning" text={t('baslayacak')}/>;
        } else {
            return <Badge status="success" text={t('devam-ediyor')}/>;
        }
    };

    const getPeriodNo = () => {
        return `P-${moment().format("yyyy-MM-DD-mm")}`;
    }

    return (
        <div>
            <Button type="primary" onClick={showDrawer}>
                Dönem Ekle
            </Button>
            <Table dataSource={periods} rowKey="id" style={{marginTop: 16}} onRow={(record) => ({
                onClick: () => handleEdit(record),
            })}>
                <Column title={t('doenem-no')} dataIndex="periodNo" key="periodNo"/>
                <Column title={t('doenem-adi')} dataIndex="periodName" key="periodName"/>
                <Column title={t('baslangic-tarihi')}
                        dataIndex="startDate"
                        key="startDate"
                        render={(text, record: Period) => moment(text).format("DD/MM/yyyy")}/>
                <Column title={t('bitis-tarihi')}
                        dataIndex="endDate"
                        key="endDate"
                        render={(text, record: Period) => moment(text).format("DD/MM/yyyy")}/>
                <Column
                    title={t('durum')}
                    key="status"
                    render={(text, record: Period) => getStatusBadge(record)}
                />
            </Table>
            <Modal
                title={selectedPeriod ? t('doenem-guencelle') : t('doenem-ekle')}
                width={800}
                height={500}
                onCancel={onClose}
                onClose={onClose}
                visible={visible}
                styles={{
                    body: {
                        height: "500px",
                        overflowY: "auto",
                    }
                }}
                footer={
                    <Space>
                        <Button onClick={onClose}>{t('iptal')}</Button>
                        <Button type="primary" htmlType="submit" onClick={() => {
                            form.submit()
                        }}>{t('save')}</Button>
                    </Space>}
                bodyStyle={{paddingBottom: 80}}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Card title="Dönem Bilgileri" bordered={true} type={"inner"} className={"bg-transparent"}>
                        <Form.Item
                            name="number"
                            label={t('doenem-no-0')}
                            rules={[{required: true, message: t('luetfen-doenem-numarasi-girin')}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label={t('doenem-adi-0')}
                            rules={[{required: true, message: t('luetfen-doenem-adi-girin')}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="startDate"
                            label={t('baslangic-tarihi-0')}
                            rules={[{required: true, message: t('luetfen-baslangic-tarihini-girin')}]}
                            style={{width: '100%'}}
                        >
                            <DatePicker
                                style={{width: '100%'}}
                                // disabledDate={(current) => current && (current < moment().subtract(1, 'month') || current > moment().add(1, 'month'))}
                                format="DD/MM/YYYY"
                            />
                        </Form.Item>
                        <Form.Item
                            name="endDate"
                            label={t('bitis-tarihi-0')}
                            rules={[{required: true, message: t('luetfen-bitis-tarihini-girin')}]}
                            style={{width: '100%'}}
                        >
                            <DatePicker
                                style={{width: '100%'}}
                                // disabledDate={(current) => current && (current < moment().subtract(1, 'month') || current > moment().add(1, 'year'))}
                                format="DD/MM/YYYY"
                            />
                        </Form.Item>
                    </Card>

                    <Card title="1. Yönetici Değerlendirme Dönemi" bordered={true} type={"inner"}
                          className={"bg-transparent mt-3"}>
                        <Form.Item
                            name="startDate1"
                            label={t('baslangic-tarihi-0')}
                            rules={[{required: true, message: t('luetfen-baslangic-tarihini-girin')}]}
                            style={{width: '100%'}}
                        >
                            <DatePicker
                                style={{width: '100%'}}
                                // disabledDate={(current) => current && (current < moment().subtract(1, 'month') || current > moment().add(1, 'month'))}
                                format="DD/MM/YYYY"
                            />
                        </Form.Item>
                        <Form.Item
                            name="endDate1"
                            label={t('bitis-tarihi-0')}
                            rules={[{required: true, message: t('luetfen-bitis-tarihini-girin')}]}
                            style={{width: '100%'}}
                        >
                            <DatePicker
                                style={{width: '100%'}}
                                // disabledDate={(current) => current && (current < moment().subtract(1, 'month') || current > moment().add(1, 'year'))}
                                format="DD/MM/YYYY"
                            />
                        </Form.Item>
                    </Card>

                    <Card title="1. Kalibrasyon Yöneticisi Değerlendirme Dönemi" bordered={true} type={"inner"}
                          className={"bg-transparent mt-3"}>
                        <Form.Item
                            name="startDate2"
                            label={t('baslangic-tarihi-0')}
                            rules={[{required: true, message: t('luetfen-baslangic-tarihini-girin')}]}
                            style={{width: '100%'}}
                        >
                            <DatePicker
                                style={{width: '100%'}}
                                // disabledDate={(current) => current && (current < moment().subtract(1, 'month') || current > moment().add(1, 'month'))}
                                format="DD/MM/YYYY"
                            />
                        </Form.Item>
                        <Form.Item
                            name="endDate2"
                            label={t('bitis-tarihi-0')}
                            rules={[{required: true, message: t('luetfen-bitis-tarihini-girin')}]}
                            style={{width: '100%'}}
                        >
                            <DatePicker
                                style={{width: '100%'}}
                                // disabledDate={(current) => current && (current < moment().subtract(1, 'month') || current > moment().add(1, 'year'))}
                                format="DD/MM/YYYY"
                            />
                        </Form.Item>
                    </Card>

                    <Card title="2. Kalibrasyon Yöneticisi Değerlendirme Dönemi" bordered={true} type={"inner"}
                          className={"bg-transparent mt-3"}>
                        <Form.Item
                            name="startDate3"
                            label={t('baslangic-tarihi-0')}
                            rules={[{required: true, message: t('luetfen-baslangic-tarihini-girin')}]}
                            style={{width: '100%'}}
                        >
                            <DatePicker
                                style={{width: '100%'}}
                                // disabledDate={(current) => current && (current < moment().subtract(1, 'month') || current > moment().add(1, 'month'))}
                                format="DD/MM/YYYY"
                            />
                        </Form.Item>
                        <Form.Item
                            name="endDate3"
                            label={t('bitis-tarihi-0')}
                            rules={[{required: true, message: t('luetfen-bitis-tarihini-girin')}]}
                            style={{width: '100%'}}
                        >
                            <DatePicker
                                style={{width: '100%'}}
                                // disabledDate={(current) => current && (current < moment().subtract(1, 'month') || current > moment().add(1, 'year'))}
                                format="DD/MM/YYYY"
                            />
                        </Form.Item>
                    </Card>
                </Form>
            </Modal>
        </div>
    );
};

export default PeriodDefinition;
