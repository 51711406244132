import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiRolePermissionRolesUserRolePermissions: build.query<
      GetApiRolePermissionRolesUserRolePermissionsApiResponse,
      GetApiRolePermissionRolesUserRolePermissionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles/userRolePermissions`,
        params: { companyId: queryArg.companyId, userId: queryArg.userId },
      }),
    }),
    getApiRolePermissionRoles: build.query<
      GetApiRolePermissionRolesApiResponse,
      GetApiRolePermissionRolesApiArg
    >({
      query: () => ({ url: `/api/RolePermission/roles` }),
    }),
    getApiRolePermissionPermissions: build.query<
      GetApiRolePermissionPermissionsApiResponse,
      GetApiRolePermissionPermissionsApiArg
    >({
      query: () => ({ url: `/api/RolePermission/permissions` }),
    }),
    postApiRolePermissionAssign: build.mutation<
      PostApiRolePermissionAssignApiResponse,
      PostApiRolePermissionAssignApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/assign`,
        method: "POST",
        body: queryArg.rolePermissionDto,
      }),
    }),
    getApiRolePermissionRolesWithPermissionsByCompanyId: build.query<
      GetApiRolePermissionRolesWithPermissionsByCompanyIdApiResponse,
      GetApiRolePermissionRolesWithPermissionsByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles-with-permissions/${queryArg.companyId}`,
      }),
    }),
    postApiRolePermissionAssignUsersToRoleByCompanyId: build.mutation<
      PostApiRolePermissionAssignUsersToRoleByCompanyIdApiResponse,
      PostApiRolePermissionAssignUsersToRoleByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/assign-users-to-role/${queryArg.companyId}`,
        method: "POST",
        body: queryArg.roleAssignmentDto,
      }),
    }),
    getApiRolePermissionRolesWithUsersByCompanyId: build.query<
      GetApiRolePermissionRolesWithUsersByCompanyIdApiResponse,
      GetApiRolePermissionRolesWithUsersByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles-with-users/${queryArg.companyId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiRolePermissionRolesUserRolePermissionsApiResponse =
  /** status 200 OK */ string[];
export type GetApiRolePermissionRolesUserRolePermissionsApiArg = {
  companyId?: string;
  userId?: string;
};
export type GetApiRolePermissionRolesApiResponse =
  /** status 200 OK */ RoleDto[];
export type GetApiRolePermissionRolesApiArg = void;
export type GetApiRolePermissionPermissionsApiResponse =
  /** status 200 OK */ PermissionDto[];
export type GetApiRolePermissionPermissionsApiArg = void;
export type PostApiRolePermissionAssignApiResponse = unknown;
export type PostApiRolePermissionAssignApiArg = {
  rolePermissionDto: RolePermissionDto;
};
export type GetApiRolePermissionRolesWithPermissionsByCompanyIdApiResponse =
  /** status 200 OK */ RoleWithPermissionsDto[];
export type GetApiRolePermissionRolesWithPermissionsByCompanyIdApiArg = {
  companyId: string;
};
export type PostApiRolePermissionAssignUsersToRoleByCompanyIdApiResponse =
  unknown;
export type PostApiRolePermissionAssignUsersToRoleByCompanyIdApiArg = {
  companyId: string;
  roleAssignmentDto: RoleAssignmentDto;
};
export type GetApiRolePermissionRolesWithUsersByCompanyIdApiResponse =
  /** status 200 OK */ RoleWithUsersDto[];
export type GetApiRolePermissionRolesWithUsersByCompanyIdApiArg = {
  companyId: string;
};
export type RoleDto = {
  id?: string;
  name?: string | null;
};
export type PermissionDto = {
  id?: string;
  name?: string | null;
};
export type RolePermissionDto = {
  roleId?: string;
  permissionIds?: string[] | null;
  companyId?: string;
};
export type RoleWithPermissionsDto = {
  roleId?: string;
  roleName?: string | null;
  permissions?: PermissionDto[] | null;
};
export type FilterDto = {
  action?: string | null;
  variable?: string | null;
  value?: string[] | null;
};
export type RoleAssignmentDto = {
  roleId?: string;
  filters?: FilterDto[] | null;
};
export type RoleWithUsersDto = {
  roleId?: string;
  roleName?: string | null;
  userCount?: number;
  formula?: FilterDto[] | null;
};
export const {
  useGetApiRolePermissionRolesUserRolePermissionsQuery,
  useLazyGetApiRolePermissionRolesUserRolePermissionsQuery,
  useGetApiRolePermissionRolesQuery,
  useLazyGetApiRolePermissionRolesQuery,
  useGetApiRolePermissionPermissionsQuery,
  useLazyGetApiRolePermissionPermissionsQuery,
  usePostApiRolePermissionAssignMutation,
  useGetApiRolePermissionRolesWithPermissionsByCompanyIdQuery,
  useLazyGetApiRolePermissionRolesWithPermissionsByCompanyIdQuery,
  usePostApiRolePermissionAssignUsersToRoleByCompanyIdMutation,
  useGetApiRolePermissionRolesWithUsersByCompanyIdQuery,
  useLazyGetApiRolePermissionRolesWithUsersByCompanyIdQuery,
} = injectedRtkApi;
