import React, {useEffect, useState} from 'react';
import {Select, Table, Button, Badge} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import {
    useLazyGetApiOneToOneMeetingGetMeetingsByManagerByManagerIdQuery,
    useLazyGetApiOneToOneMeetingGetMeetingsForTeamMemberByTeamMemberIdQuery
} from "../../api/services/OneToOneMeeting";
import {PeriodDto, useLazyGetApiPeriodGetActivePeriodsQuery} from "../../api/services/period";
import {useLazyGetApiUserTeamMembersQuery, UserDto} from "../../api/services/user";
import moment from "moment";
import OneOnOneDetailsPopup from "../../components/OneOnOnes/OneOnOneDetailsPopup";


const TeamOneOnOnes: React.FC = () => {
    const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);
    const [selectedEmployee, setSelectedEmployee] = useState<string | null>(null);
    const [fetchMeetings, {data: meetings}] = useLazyGetApiOneToOneMeetingGetMeetingsForTeamMemberByTeamMemberIdQuery()
    const [fetchActivePeriods, {data: activePeriods}] = useLazyGetApiPeriodGetActivePeriodsQuery()
    const [getTeamUsers, {data: teamUsers}] = useLazyGetApiUserTeamMembersQuery()
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch()
    const [isDetailsPopupVisible, setIsDetailsPopupVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<any>(null);

    useEffect(() => {
        if (user) {
            FetchTeamUsers()
            getActivePeriods()
        }
    }, [user])

    useEffect(() => {
        if (activePeriods && teamUsers) {
            var firstEmployee = (teamUsers as UserDto[])?.[0]?.id ?? ""
            setSelectedEmployee(firstEmployee)

            var firstPeriod = activePeriods?.[0]?.id ?? ""
            setSelectedPeriod(firstPeriod)

            getMeetings()
        }
    }, [teamUsers, activePeriods])
    const getMeetings = async () => {
        if (!selectedPeriod || !selectedEmployee)
            return

        try {
            dispatch(setLoading(true));
            await fetchMeetings({
                teamMemberId: selectedEmployee ?? "",
                periodId: selectedPeriod ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Görüşmeler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const FetchTeamUsers = async () => {
        try {
            dispatch(setLoading(true));
            await getTeamUsers().unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Kullanıcılar çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getActivePeriods = async () => {
        try {
            dispatch(setLoading(true));
            await fetchActivePeriods().unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }


    const handleSearch = () => {
        getMeetings()
    };

    const handleRowClick = (record: any) => {
        setSelectedRecord(record);
        setIsDetailsPopupVisible(true);
    };

    const columns = [
        {title: 'Çalışan', dataIndex: 'employeeName', key: 'employeeName'},
        {title: 'Dönem', dataIndex: 'periodName', key: 'periodName'},
        {title: 'Görüşme Adı', dataIndex: 'meetingName', key: 'meetingName'},
        {title: 'Tip', dataIndex: 'meetingTypeName', key: 'meetingTypeName'},
        {
            title: 'Tarih', dataIndex: 'meetingDate', key: 'meetingDate',
            render: (_: any, record: any) => moment(record.meetingDate).format('DD.MM.YYYY'),
        },
        {
            title: 'Durum', dataIndex: 'status', key: 'status',
            render: (_: any, record: any) => {
                if (record.status == 'Waiting' && !record.employeeComment) {
                    return <Badge status="error" text={t('Çalışan yorumu bekleniyor')}/>
                } else if (record.status == 'Waiting' && !record.managerComment) {
                    return <Badge status="error" text={t('Yönetici yorumu bekleniyor')}/>
                } else if (record.status == 'Completed') {
                    return <Badge status="success" text={t('Tamamlandı')}/>
                }
            }
        },
    ];

    return (
        <div>
            <div style={{display: 'flex', gap: '16px', marginBottom: 16}}>
                <Select
                    placeholder="Dönem Seçin"
                    onChange={(value) => setSelectedPeriod(value)}
                    style={{width: 200}}
                    value={selectedPeriod || undefined} // Seçili eleman
                    showSearch={true}
                    filterOption={(input, option) =>
                        `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                    }
                    options={((activePeriods ?? []) as PeriodDto[]).map(k => {
                            return {value: k.id, label: `${k.periodName}`};
                        }
                    )}
                />

                <Select
                    placeholder="Çalışan Seçin"
                    onChange={(value) => setSelectedEmployee(value)}
                    style={{width: 200}}
                    value={selectedEmployee || undefined} // Seçili eleman
                    showSearch={true}
                    filterOption={(input, option) =>
                        `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                    }
                    options={((teamUsers ?? []) as UserDto[]).map(k => {
                        return {value: k.id, label: `${k.firstName} ${k.lastName} ${k.title ? "(" + k.title + ")" : ""}`};
                        }
                    )}
                />

                <Button type="primary" onClick={handleSearch}>
                    Ara
                </Button>
            </div>

            <Table dataSource={meetings}
                   columns={columns}
                   rowKey="id"
                   pagination={false}
                   onRow={(record) => ({
                       onClick: () => handleRowClick(record),
                   })}
            />

            <OneOnOneDetailsPopup
                visible={isDetailsPopupVisible}
                onClose={() => setIsDetailsPopupVisible(false)}
                data={selectedRecord}
                toManager={false}
                isReadOnly={true}
                onSave={(updatedData) => {
                    getMeetings()
                }}
            />
        </div>
    );
};

export default TeamOneOnOnes;
