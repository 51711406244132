import {combineReducers, configureStore, Middleware, Reducer} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'

import persistedSlice, {AppState} from './persistedSlice'
import { coreApi } from '../api/core/BaseApi'
import appSlice from '../features/app/appSlice'
import authSlice from '../features/auth/authSlice'
import userSlice from '../features/users/userSlice'
import companySlice from '../features/companies/companySlice'
import storageSession from 'redux-persist/lib/storage/session'
import { createEncryptedTransform } from './encryptTransform'
import {PersistedState} from "redux-persist/es/types"; // 👈 Dosyayı import ediyoruz

const encryptedTransform = createEncryptedTransform()

const persistConfig = {
    key: 'root',
    version: 1,
    storage: storageSession,
    whitelist: ['persisted'],
    transforms: [encryptedTransform], // 👈 Şifreleme burada
    debug: true,
}


const persistedReducer = persistReducer(
    persistConfig,
    // @ts-ignore
    combineReducers({
        app: appSlice,
        auth: authSlice,
        // @ts-ignore
        persisted: persistedSlice,
        users: userSlice,
        companies: companySlice,
        [coreApi.reducerPath]: coreApi.reducer,
    })
)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(coreApi.middleware as Middleware),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const persistor = persistStore(store)
export { store, persistor }
