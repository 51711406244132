import React, { useEffect, useState } from 'react';
import { Table, Modal, Button, Form, Select } from 'antd';
import SuitableUsers, { FilterRow } from '../../components/SuitableUsers/SuitableUsers';
import { useLazyGetApiRolePermissionRolesWithUsersByCompanyIdQuery, usePostApiRolePermissionAssignUsersToRoleByCompanyIdMutation } from '../../api/services/rolePermission';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { t } from 'i18next';
import { setLoading, setError } from '../../features/app/appSlice';
import {AuthorizationIds} from "../../hooks/Authorization/AuthorizationIds";

const { Option } = Select;

const UserRoleDefinitions: React.FC = () => {
    const [selectedRole, setSelectedRole] = useState<any>(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth?.user);
    const [fetchRolesWithCounts, { data: rolesWithCount }] = useLazyGetApiRolePermissionRolesWithUsersByCompanyIdQuery()
    const [assignUsersToRole] = usePostApiRolePermissionAssignUsersToRoleByCompanyIdMutation()

    const [filters, setFilters] = useState<FilterRow[]>([]);

    useEffect(() => {
        if (user) {
            fetchRolesWithCountDatas()
        }
    }, [user])

    const assignRoleToUsers = async (filters: any, roleId: string) => {
        try {
            dispatch(setLoading(true));

            await assignUsersToRole({
                companyId: user?.companyId ?? "",
                roleAssignmentDto: {
                    filters: filters,
                    roleId: roleId
                }
            }).unwrap()

        } catch (err) {
            dispatch(setError(t('Roller atanırken yüklenirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const fetchRolesWithCountDatas = async () => {
        try {
            dispatch(setLoading(true));

            await fetchRolesWithCounts({
                companyId: user?.companyId ?? ""
            }).unwrap()
        } catch (err) {
            dispatch(setError(t('Roller yüklenirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleRowClick = (record: any) => {
        setSelectedRole(record);
        setModalVisible(true);
        form.setFieldsValue({
            roleName: record.roleName,
        });
    };

    const handleSave = async (values: any) => {
        var filterToSave = filters
        var role = rolesWithCount?.find((k: any) => k.roleName == values.roleName)
        await assignRoleToUsers(filterToSave, role?.roleId ?? "")
        await fetchRolesWithCountDatas()
        form.resetFields();
        setModalVisible(false);
        setSelectedRole(null);
    };

    const handleFilterChange = (filters: FilterRow[]) => {
        setFilters(filters);

        console.log(filters)
        // setResultsVisible(true);
    };


    const columns = [
        {
            title: 'Rol Adı',
            dataIndex: 'roleName',
            key: 'roleName',
        },
        {
            title: 'Kullanıcı Sayısı',
            dataIndex: 'userCount',
            key: 'userCount',
        },
    ];

    return (
        <>
            <Table
                dataSource={rolesWithCount?.filter((r) => r.roleId !== AuthorizationIds.Roles.SuperAdmin) ?? []}
                columns={columns}
                rowKey="id"
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
                pagination={false}
            />

            <Modal
                destroyOnClose={true}
                title={selectedRole ? 'Rolü Güncelle' : 'Yeni Rol Ekle'}
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                width={800}
                bodyStyle={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
                footer={[
                    <Button key="back" onClick={() => setModalVisible(false)}>
                        İptal
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => form.submit()}>
                        Kaydet
                    </Button>,
                ]}
            >
                <Form form={form} onFinish={handleSave} layout="vertical">
                    <Form.Item name="roleName" label="Kullanıcı Rolü">
                        <Select placeholder="Bir rol seçin" style={{ width: '100%' }} disabled>
                            {rolesWithCount?.map((role: any) => (
                                <Option key={role.roleId} value={role.roleName}>
                                    {role.roleName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <SuitableUsers
                        onFilterChange={handleFilterChange}
                        initialData={
                            selectedRole?.formula != null && selectedRole?.formula.length > 0 ?
                                { filters: selectedRole?.formula } :
                                null
                        } />
                </Form>
            </Modal>
        </>
    );
};

export default UserRoleDefinitions;