import React, {useEffect, useState} from 'react';
import {Card, Col, Divider, Row, Spin, Statistic} from "antd";
import {PerformanceResultTypes} from "../Reports/Components/PerformanceResultType";
import {IDetailItem} from "../Reports/data";
import {PerformanceCardDto, PeriodDto, useLazyGetApiPeriodAllQuery} from "../../api/services/period";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {
    ManagerEvaluationDto,
    useLazyGetApiManagerEvaluationGetCompletionInfoQuery,
    useLazyGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery
} from "../../api/services/ManagerEvaluation";
import {
    useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery,
    useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery
} from "../../api/services/performanceCards";
import {setError} from "../../features/app/appSlice";
import {t} from "i18next";
import {ScaleDetailDto} from "../../api/services/scale";

export interface IEvaluationTab {
    selectedItem: any | undefined
    card?: PerformanceCardDto | undefined
    howSelected: (cardId: string, evaluation: ManagerEvaluationDto | undefined, id: string) => void
    whatSelected: (cardId: string, evaluation: ManagerEvaluationDto | undefined, id: string) => void
    evaluating: boolean
    evaluationCompleted: boolean
}

const EvaluationTab: React.FC<IEvaluationTab> = ({
                                                     selectedItem,
                                                     howSelected,
                                                     whatSelected,
                                                     evaluating,
                                                     evaluationCompleted
                                                 }) => {
    const [selectedPeriod, setSelectedPeriod] = useState<PeriodDto | undefined>();
    const [fetchActivePeriods, {data: activePeriods}] = useLazyGetApiPeriodAllQuery()
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch()
    const [getCompletion] = useLazyGetApiManagerEvaluationGetCompletionInfoQuery()
    // const [evaluationCompleted, setEvaluationCompleted] = useState(false)
    const [getManagerEvaluations, {data: oldEvaluations}] = useLazyGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery()
    // const [selectedEvaluation, setSelectedEvaluation] = useState<ManagerEvaluationDto | undefined>(undefined)
    const [fetchPerformanceCards, {data: performanceCardList}] = useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery()
    const [getCardForPeriod] = useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery()
    const [performanceCard, setPerformanceCard] = useState<PerformanceCardDto | undefined>(undefined)
    const [translatedValue, setTranslatedValue] = useState<any | undefined>()
    const [selectedEvaluation, setSelectedEvaluation] = useState<any | undefined>(undefined)

    useEffect(() => {
        if (selectedItem) {
            setSelectedPeriod(selectedItem.period);
            FetchEvaluationDetails(selectedItem.period)
        }
    }, [selectedItem]);

    const FetchCardForPeriod = async (sp: PeriodDto, evaluation: any) => {

        try {
            var cards = await getCardForPeriod({
                employeeId: selectedItem?.id ?? "",
                periodId: sp?.id ?? "",
            }).unwrap()

            if (cards.length > 0) {
                var card = cards[0]
                setPerformanceCard(card)

                translateEvaluations(card?.scale?.scaleDetails, evaluation)
            } else {
                setPerformanceCard(undefined)
            }

        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Periyoda ait kart çekilirken hata oluştu')));
        }
    }
    const FetchEvaluationDetails = async (selectedPeriod: PeriodDto) => {
        if (selectedPeriod == undefined)
            return

        try {


            var evaluations = await getManagerEvaluations({
                periodId: selectedPeriod?.id ?? "",
                userId: selectedItem?.id ?? "",
            }).unwrap()

            FetchCardForPeriod(selectedPeriod, evaluations)

            // @ts-ignore
            var selectedEvaluation = (evaluations as any[]).find(k => k.userId === selectedItem?.id ?? "")
            setSelectedEvaluation(selectedEvaluation)
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Periyoda ait kart çekilirken hata oluştu')));
        } finally {
        }
    }

    const checkSelectedPeriod = (selectedPeriod: PeriodDto) => {
        FetchEvaluationDetails(selectedPeriod)
    }

    const getEvaluations = () => {
        // @ts-ignore
        if (oldEvaluations && oldEvaluations?.length > 0) {
            // @ts-ignore
            return oldEvaluations[0]
        }

        return null
    }

    const translateEvaluations = (scale: ScaleDetailDto[] | undefined | null, evaluation: any) => {
        if (!scale) return

        var ev = evaluation
        if (ev.length == 0) return;

        var sorted1 = [...scale].filter(k => k.evaluationType == 1).sort((a, b) => (b.maxScore ?? 0) - (a.maxScore ?? 0))
        var sorted2 = [...scale].filter(k => k.evaluationType == 2).sort((a, b) => (b.maxScore ?? 0) - (a.maxScore ?? 0))

        var pr: any = {
            how: scale.find(k => k.id == ev[0]?.managerHow),
            calibratedHow: scale.find(k => k.id == ev[0]?.firstCalibrationHow),
            what: scale.find(k => k.id == ev[0]?.managerWhat),
            calibratedWhat: scale.find(k => k.id == ev[0]?.firstCalibrationWhat),

        }

        var calibratedHowindex = sorted2.find(k => k.id == pr.calibratedHow?.id)
        var howindex = sorted2.find(k => k.id == pr.how?.id)
        var whatindex = sorted1.find(k => k.id == pr.what?.id)
        var calibratedWhatindex = sorted1.find(k => k.id == pr.calibratedWhat?.id)


        pr = {
            ...pr, ...{
                howi: howindex ? sorted2.indexOf(howindex) + 1 : null,
                whati: whatindex ? sorted1.indexOf(whatindex) + 1 : null,

                calibratedHowi: calibratedHowindex ? sorted2.indexOf(calibratedHowindex) + 1 : null,
                calibratedWhati: calibratedWhatindex ? sorted1.indexOf(calibratedWhatindex) + 1 : null,
            }
        }

        setTranslatedValue(pr)
    }

    return (<>
        <div>
            <Spin spinning={evaluating}>
                <div>
                    <Divider orientation="left">Yönetici Değerlendirmesi</Divider>
                    {getEvaluations()?.managerWhat &&
                        <Row gutter={24}>
                            <Col span={12}>
                                <Card bordered={false}>
                                    <Statistic
                                        title="İş Sonuçlarım (What)"
                                        value={translatedValue?.what?.result ?? ""}
                                        valueStyle={{color: '#3f8600'}}
                                        prefix={PerformanceResultTypes.GetType(translatedValue?.whati)?.icon}
                                    />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card bordered={false}>
                                    <Statistic
                                        title="İş Sonuçlarına Nasıl Ulaştığım (How)"
                                        value={translatedValue?.how?.result ?? ""}
                                        valueStyle={{color: '#3f8600'}}
                                        prefix={PerformanceResultTypes.GetType(translatedValue?.howi)?.icon}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    }
                    {
                        !(getEvaluations()?.managerWhat) &&
                        <Card bordered={false}>
                            <Statistic
                                valueStyle={{fontSize: 14, textAlign: "center"}}
                                value={"Değerlendirme henüz tamamlanmadı"}
                            />
                        </Card>
                    }
                </div>
                <div className={"mt-[30px]"}>
                    <Divider orientation="left">Kalibrasyon Değerlendirmesi</Divider>
                    <div className={"ml-2"}>
                        <Row gutter={16}>
                            <Card title={"İş Sonuçları (What)"} bordered={true} className={"w-full"}>
                                <div className={"flex flex-row overflow-scroll  no-scrollbar"}>
                                    {[...(performanceCard?.scale?.scaleDetails ?? [])]
                                        .filter(k => k.evaluationType == 1)
                                        .sort((k, l) => ((k?.maxScore ?? 0) > (l?.maxScore ?? 0)) ? -1 : 1)
                                        .map((item, index) => {
                                            var t = PerformanceResultTypes.GetType(index + 1)
                                            return (<Card bordered={true}
                                                          className={`${selectedEvaluation?.firstCalibrationWhat === item.id ?? "" ? "bg-[#89A8B2]" : ""} ml-2 cursor-pointer`}
                                                          onClick={() => {
                                                              whatSelected(performanceCard?.id ?? "", selectedEvaluation, item.id ?? "")
                                                          }}>
                                                {t!.icon} {item!.result}
                                            </Card>)
                                        })}
                                </div>
                            </Card>
                        </Row>
                        <Row gutter={16} className={"mt-3"}>
                            <Card title={"İş Sonuçlarına Nasıl Ulaştığı (How)"} bordered={true}
                                  className={"w-full"}>
                                <div className={"flex flex-row overflow-scroll no-scrollbar"}>
                                    {[...(performanceCard?.scale?.scaleDetails ?? [])]
                                        .filter(k => k.evaluationType == 2)
                                        .sort((k, l) => ((k?.maxScore ?? 0) > (l?.maxScore ?? 0)) ? -1 : 1)
                                        .map((item, index) => {
                                            var t = PerformanceResultTypes.GetType(index + 1)
                                            return (<Card bordered={true}
                                                          className={`${selectedEvaluation?.firstCalibrationHow === item.id ?? "" ? "bg-[#89A8B2]" : ""} ml-2 cursor-pointer`}
                                                          onClick={() => {
                                                              howSelected(performanceCard?.id ?? "", selectedEvaluation, item.id ?? "")
                                                          }}>
                                                {t!.icon} {item.result}
                                            </Card>)
                                        })}
                                </div>
                            </Card>
                        </Row>
                    </div>
                </div>
            </Spin>
        </div>
    </>)
};

export default EvaluationTab;