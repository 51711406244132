import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCompanySyncUsersFromSystem: build.query<
      GetApiCompanySyncUsersFromSystemApiResponse,
      GetApiCompanySyncUsersFromSystemApiArg
    >({
      query: () => ({ url: `/api/Company/syncUsersFromSystem` }),
    }),
    getApiFeedbackGetGivenFeedbacksByUserId: build.query<
      GetApiFeedbackGetGivenFeedbacksByUserIdApiResponse,
      GetApiFeedbackGetGivenFeedbacksByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Feedback/GetGivenFeedbacks/${queryArg.userId}`,
      }),
    }),
    getApiFeedbackGetReceivedFeedbacksByUserId: build.query<
      GetApiFeedbackGetReceivedFeedbacksByUserIdApiResponse,
      GetApiFeedbackGetReceivedFeedbacksByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Feedback/GetReceivedFeedbacks/${queryArg.userId}`,
      }),
    }),
    getApiFeedbackGetTeamFeedbacksByUserId: build.query<
      GetApiFeedbackGetTeamFeedbacksByUserIdApiResponse,
      GetApiFeedbackGetTeamFeedbacksByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Feedback/GetTeamFeedbacks/${queryArg.userId}`,
      }),
    }),
    getApiFeedbackGetFeedbackRequestsByUserId: build.query<
      GetApiFeedbackGetFeedbackRequestsByUserIdApiResponse,
      GetApiFeedbackGetFeedbackRequestsByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Feedback/GetFeedbackRequests/${queryArg.userId}`,
      }),
    }),
    getApiFeedbackGetRequestedFeedbacksByUserId: build.query<
      GetApiFeedbackGetRequestedFeedbacksByUserIdApiResponse,
      GetApiFeedbackGetRequestedFeedbacksByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Feedback/GetRequestedFeedbacks/${queryArg.userId}`,
      }),
    }),
    getApiPerformanceCardsGetActivePerformanceCardsForUserByUserId: build.query<
      GetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdApiResponse,
      GetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/GetActivePerformanceCardsForUser/${queryArg.userId}`,
      }),
    }),
    postApiPerformanceCardsAddUserOkr: build.mutation<
      PostApiPerformanceCardsAddUserOkrApiResponse,
      PostApiPerformanceCardsAddUserOkrApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/AddUserOKR`,
        method: "POST",
        body: queryArg.userOkrdto,
      }),
    }),
    postApiPerformanceCardsAddOrUpdateUserKr: build.mutation<
      PostApiPerformanceCardsAddOrUpdateUserKrApiResponse,
      PostApiPerformanceCardsAddOrUpdateUserKrApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/AddOrUpdateUserKR`,
        method: "POST",
        body: queryArg.userKrdto,
      }),
    }),
    getApiRolePermissionRolesUserRolePermissions: build.query<
      GetApiRolePermissionRolesUserRolePermissionsApiResponse,
      GetApiRolePermissionRolesUserRolePermissionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles/userRolePermissions`,
        params: { companyId: queryArg.companyId, userId: queryArg.userId },
      }),
    }),
    postApiRolePermissionAssignUsersToRoleByCompanyId: build.mutation<
      PostApiRolePermissionAssignUsersToRoleByCompanyIdApiResponse,
      PostApiRolePermissionAssignUsersToRoleByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/assign-users-to-role/${queryArg.companyId}`,
        method: "POST",
        body: queryArg.roleAssignmentDto,
      }),
    }),
    getApiRolePermissionRolesWithUsersByCompanyId: build.query<
      GetApiRolePermissionRolesWithUsersByCompanyIdApiResponse,
      GetApiRolePermissionRolesWithUsersByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles-with-users/${queryArg.companyId}`,
      }),
    }),
    postApiTargetAudienceResolveSuitableUsers: build.mutation<
      PostApiTargetAudienceResolveSuitableUsersApiResponse,
      PostApiTargetAudienceResolveSuitableUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TargetAudience/resolve-suitable-users`,
        method: "POST",
        body: queryArg.resolveSuitableUsersRequest,
      }),
    }),
    getApiUser: build.query<GetApiUserApiResponse, GetApiUserApiArg>({
      query: () => ({ url: `/api/User` }),
    }),
    postApiUser: build.mutation<PostApiUserApiResponse, PostApiUserApiArg>({
      query: (queryArg) => ({
        url: `/api/User`,
        method: "POST",
        body: queryArg.createUserDto,
      }),
    }),
    getApiUserById: build.query<
      GetApiUserByIdApiResponse,
      GetApiUserByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/User/${queryArg.id}` }),
    }),
    putApiUserById: build.mutation<
      PutApiUserByIdApiResponse,
      PutApiUserByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateUserDto,
      }),
    }),
    deleteApiUserById: build.mutation<
      DeleteApiUserByIdApiResponse,
      DeleteApiUserByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiUserByCompanyByCompanyId: build.query<
      GetApiUserByCompanyByCompanyIdApiResponse,
      GetApiUserByCompanyByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/byCompany/${queryArg.companyId}`,
      }),
    }),
    getApiUserByCompanyGroupByCompanyGroupId: build.query<
      GetApiUserByCompanyGroupByCompanyGroupIdApiResponse,
      GetApiUserByCompanyGroupByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/byCompanyGroup/${queryArg.companyGroupId}`,
      }),
    }),
    postApiUserImportAndCompare: build.mutation<
      PostApiUserImportAndCompareApiResponse,
      PostApiUserImportAndCompareApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/import-and-compare`,
        method: "POST",
        body: queryArg.body,
        params: { companyId: queryArg.companyId },
      }),
    }),
    postApiUserProcessImportedUsers: build.mutation<
      PostApiUserProcessImportedUsersApiResponse,
      PostApiUserProcessImportedUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/process-imported-users`,
        method: "POST",
        body: queryArg.importResultDto,
      }),
    }),
    postApiUserGetUserParamsByCompanyGroupId: build.mutation<
      PostApiUserGetUserParamsByCompanyGroupIdApiResponse,
      PostApiUserGetUserParamsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/get-user-params/${queryArg.companyGroupId}`,
        method: "POST",
      }),
    }),
    getApiUserTeamMembers: build.query<
      GetApiUserTeamMembersApiResponse,
      GetApiUserTeamMembersApiArg
    >({
      query: () => ({ url: `/api/User/team-members` }),
    }),
    getApiUserUsersForComboByCompanGroupId: build.query<
      GetApiUserUsersForComboByCompanGroupIdApiResponse,
      GetApiUserUsersForComboByCompanGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/users-for-combo/${queryArg.companGroupId}`,
      }),
    }),
    getApiUserUsersForComboByCardByCompanGroupId: build.query<
      GetApiUserUsersForComboByCardByCompanGroupIdApiResponse,
      GetApiUserUsersForComboByCardByCompanGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/users-for-combo-by-card/${queryArg.companGroupId}`,
        params: { cardId: queryArg.cardId },
      }),
    }),
    postApiUserUpdateUserParamsByCompanyGroupId: build.mutation<
      PostApiUserUpdateUserParamsByCompanyGroupIdApiResponse,
      PostApiUserUpdateUserParamsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/update-user-params/${queryArg.companyGroupId}`,
        method: "POST",
      }),
    }),
    postApiUserUpdateUsersMaster: build.mutation<
      PostApiUserUpdateUsersMasterApiResponse,
      PostApiUserUpdateUsersMasterApiArg
    >({
      query: () => ({ url: `/api/User/update-users-master`, method: "POST" }),
    }),
    postApiUserUpdateUserLogins: build.mutation<
      PostApiUserUpdateUserLoginsApiResponse,
      PostApiUserUpdateUserLoginsApiArg
    >({
      query: () => ({ url: `/api/User/update-user-logins`, method: "POST" }),
    }),
    postApiUserInitiateResetPassword: build.mutation<
      PostApiUserInitiateResetPasswordApiResponse,
      PostApiUserInitiateResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/initiateResetPassword`,
        method: "POST",
        body: queryArg.initiatePasswordResetDto,
      }),
    }),
    postApiUserCompletePasswordReset: build.mutation<
      PostApiUserCompletePasswordResetApiResponse,
      PostApiUserCompletePasswordResetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/completePasswordReset`,
        method: "POST",
        body: queryArg.completePasswordResetDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiCompanySyncUsersFromSystemApiResponse = unknown;
export type GetApiCompanySyncUsersFromSystemApiArg = void;
export type GetApiFeedbackGetGivenFeedbacksByUserIdApiResponse =
  /** status 200 OK */ FeedbackDto[];
export type GetApiFeedbackGetGivenFeedbacksByUserIdApiArg = {
  userId: string;
};
export type GetApiFeedbackGetReceivedFeedbacksByUserIdApiResponse =
  /** status 200 OK */ FeedbackDto[];
export type GetApiFeedbackGetReceivedFeedbacksByUserIdApiArg = {
  userId: string;
};
export type GetApiFeedbackGetTeamFeedbacksByUserIdApiResponse =
  /** status 200 OK */ FeedbackDto[];
export type GetApiFeedbackGetTeamFeedbacksByUserIdApiArg = {
  userId: string;
};
export type GetApiFeedbackGetFeedbackRequestsByUserIdApiResponse =
  /** status 200 OK */ FeedbackDto[];
export type GetApiFeedbackGetFeedbackRequestsByUserIdApiArg = {
  userId: string;
};
export type GetApiFeedbackGetRequestedFeedbacksByUserIdApiResponse =
  /** status 200 OK */ FeedbackDto[];
export type GetApiFeedbackGetRequestedFeedbacksByUserIdApiArg = {
  userId: string;
};
export type GetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdApiResponse =
  /** status 200 OK */ ActivePerformanceCardDto[];
export type GetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdApiArg =
  {
    userId: string;
  };
export type PostApiPerformanceCardsAddUserOkrApiResponse = unknown;
export type PostApiPerformanceCardsAddUserOkrApiArg = {
  userOkrdto: UserOkrdto;
};
export type PostApiPerformanceCardsAddOrUpdateUserKrApiResponse = unknown;
export type PostApiPerformanceCardsAddOrUpdateUserKrApiArg = {
  userKrdto: UserKrdto;
};
export type GetApiRolePermissionRolesUserRolePermissionsApiResponse =
  /** status 200 OK */ string[];
export type GetApiRolePermissionRolesUserRolePermissionsApiArg = {
  companyId?: string;
  userId?: string;
};
export type PostApiRolePermissionAssignUsersToRoleByCompanyIdApiResponse =
  unknown;
export type PostApiRolePermissionAssignUsersToRoleByCompanyIdApiArg = {
  companyId: string;
  roleAssignmentDto: RoleAssignmentDto;
};
export type GetApiRolePermissionRolesWithUsersByCompanyIdApiResponse =
  /** status 200 OK */ RoleWithUsersDto[];
export type GetApiRolePermissionRolesWithUsersByCompanyIdApiArg = {
  companyId: string;
};
export type PostApiTargetAudienceResolveSuitableUsersApiResponse =
  /** status 200 OK */ UserDto[];
export type PostApiTargetAudienceResolveSuitableUsersApiArg = {
  resolveSuitableUsersRequest: ResolveSuitableUsersRequest;
};
export type GetApiUserApiResponse = /** status 200 OK */ UserDto[];
export type GetApiUserApiArg = void;
export type PostApiUserApiResponse = /** status 200 OK */ UserDto;
export type PostApiUserApiArg = {
  createUserDto: CreateUserDto;
};
export type GetApiUserByIdApiResponse = /** status 200 OK */ UserDto;
export type GetApiUserByIdApiArg = {
  id: string;
};
export type PutApiUserByIdApiResponse = unknown;
export type PutApiUserByIdApiArg = {
  id: string;
  updateUserDto: UpdateUserDto;
};
export type DeleteApiUserByIdApiResponse = unknown;
export type DeleteApiUserByIdApiArg = {
  id: string;
};
export type GetApiUserByCompanyByCompanyIdApiResponse =
  /** status 200 OK */ UserDto[];
export type GetApiUserByCompanyByCompanyIdApiArg = {
  companyId: string;
};
export type GetApiUserByCompanyGroupByCompanyGroupIdApiResponse =
  /** status 200 OK */ UserDto[];
export type GetApiUserByCompanyGroupByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type PostApiUserImportAndCompareApiResponse =
  /** status 200 Import successful */ ImportResultDto;
export type PostApiUserImportAndCompareApiArg = {
  companyId?: string;
  body: {
    file?: Blob;
  };
};
export type PostApiUserProcessImportedUsersApiResponse = unknown;
export type PostApiUserProcessImportedUsersApiArg = {
  importResultDto: ImportResultDto;
};
export type PostApiUserGetUserParamsByCompanyGroupIdApiResponse = unknown;
export type PostApiUserGetUserParamsByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type GetApiUserTeamMembersApiResponse = unknown;
export type GetApiUserTeamMembersApiArg = void;
export type GetApiUserUsersForComboByCompanGroupIdApiResponse = unknown;
export type GetApiUserUsersForComboByCompanGroupIdApiArg = {
  companGroupId: string;
};
export type GetApiUserUsersForComboByCardByCompanGroupIdApiResponse = unknown;
export type GetApiUserUsersForComboByCardByCompanGroupIdApiArg = {
  companGroupId: string;
  cardId?: string;
};
export type PostApiUserUpdateUserParamsByCompanyGroupIdApiResponse = unknown;
export type PostApiUserUpdateUserParamsByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type PostApiUserUpdateUsersMasterApiResponse = unknown;
export type PostApiUserUpdateUsersMasterApiArg = void;
export type PostApiUserUpdateUserLoginsApiResponse = unknown;
export type PostApiUserUpdateUserLoginsApiArg = void;
export type PostApiUserInitiateResetPasswordApiResponse = unknown;
export type PostApiUserInitiateResetPasswordApiArg = {
  initiatePasswordResetDto: InitiatePasswordResetDto;
};
export type PostApiUserCompletePasswordResetApiResponse = unknown;
export type PostApiUserCompletePasswordResetApiArg = {
  completePasswordResetDto: CompletePasswordResetDto;
};
export type FeedbackDto = {
  id?: string;
  fromUserId?: string;
  fromUserName?: string | null;
  toUserId?: string;
  toUserName?: string | null;
  feedbackReasonId?: string;
  feedbackReason?: string | null;
  responseDetail?: string | null;
  responseDate?: string | null;
  requestDetail?: string | null;
  detail?: string | null;
  feedbackDate?: string;
  status?: string | null;
  type?: string | null;
};
export type ActivePerformanceCardDto = {
  cardId?: string;
  cardName?: string | null;
  periodId?: string;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
};
export type UserOkrStatus = 0 | 1 | 2;
export type UserOkrdto = {
  id?: string | null;
  userId?: string;
  creatorId?: string;
  performanceCardAssignmentId?: string;
  status?: UserOkrStatus;
  categoryId?: string;
  isLinked?: boolean;
  linkedStrategicOKRId?: string | null;
  linkedODepartmentOKRId?: string | null;
  description?: string | null;
  targetCompletionDate?: string;
  isPrivate?: boolean;
  progress?: number;
  isManagerApproved?: boolean;
  managerToApproveId?: string | null;
  isWeightened?: boolean;
  teamList?: string[] | null;
  weight?: number;
};
export type UserKrdto = {
  id?: string | null;
  okrId?: string;
  description?: string | null;
  targetCompletionDate?: string;
  isPrivate?: boolean;
  progress?: number;
  createDate?: string | null;
  userId?: string;
  creatorId?: string | null;
  okrTeamGroupId?: string | null;
};
export type FilterDto = {
  action?: string | null;
  variable?: string | null;
  value?: string[] | null;
};
export type RoleAssignmentDto = {
  roleId?: string;
  filters?: FilterDto[] | null;
};
export type RoleWithUsersDto = {
  roleId?: string;
  roleName?: string | null;
  userCount?: number;
  formula?: FilterDto[] | null;
};
export type CompanyGroupDto = {
  id?: string;
  groupName?: string | null;
};
export type CompanyDto = {
  id?: string;
  name?: string | null;
  address?: string | null;
  email?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  logo?: string | null;
  faviconName?: string | null;
  loginIcon?: string | null;
  companyGroupId?: string;
  companyGroup?: CompanyGroupDto;
  adminPass?: string | null;
};
export type UserDto = {
  id?: string;
  userName?: string | null;
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerId?: string;
  managerNumber?: string | null;
  managerName?: string | null;
  managerSurname?: string | null;
  email?: string | null;
  phone?: string | null;
  company?: CompanyDto;
};
export type ResolveSuitableUsersRequest = {
  id?: string;
  targetAudienceName?: string | null;
  targetAudienceNo?: string | null;
  filters?: FilterDto[] | null;
};
export type CreateUserDto = {
  userName?: string | null;
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerNumber?: string | null;
  managerName?: string | null;
  managerSurname?: string | null;
  email?: string | null;
  phone?: string | null;
};
export type UpdateUserDto = {
  userName?: string | null;
  password?: string | null;
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerNumber?: string | null;
  managerName?: string | null;
  managerSurname?: string | null;
  manager?: string | null;
  email?: string | null;
  phone?: string | null;
};
export type UserImportResultDto = {
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerNumber?: string | null;
  managerName?: string | null;
  managerSurname?: string | null;
  email?: string | null;
  phone?: string | null;
  status?: string | null;
};
export type CompanyImportResultDto = {
  companyName?: string | null;
  address?: string | null;
  email?: string | null;
  status?: string | null;
};
export type ImportResultDto = {
  addableUsers?: UserImportResultDto[] | null;
  missingCompanies?: CompanyImportResultDto[] | null;
  usersLinkedToMissingCompanies?: UserImportResultDto[] | null;
};
export type InitiatePasswordResetDto = {
  email?: string | null;
  companyId?: string | null;
};
export type CompletePasswordResetDto = {
  token?: string | null;
  newPassword?: string | null;
};
export const {
  useGetApiCompanySyncUsersFromSystemQuery,
  useLazyGetApiCompanySyncUsersFromSystemQuery,
  useGetApiFeedbackGetGivenFeedbacksByUserIdQuery,
  useLazyGetApiFeedbackGetGivenFeedbacksByUserIdQuery,
  useGetApiFeedbackGetReceivedFeedbacksByUserIdQuery,
  useLazyGetApiFeedbackGetReceivedFeedbacksByUserIdQuery,
  useGetApiFeedbackGetTeamFeedbacksByUserIdQuery,
  useLazyGetApiFeedbackGetTeamFeedbacksByUserIdQuery,
  useGetApiFeedbackGetFeedbackRequestsByUserIdQuery,
  useLazyGetApiFeedbackGetFeedbackRequestsByUserIdQuery,
  useGetApiFeedbackGetRequestedFeedbacksByUserIdQuery,
  useLazyGetApiFeedbackGetRequestedFeedbacksByUserIdQuery,
  useGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery,
  useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery,
  usePostApiPerformanceCardsAddUserOkrMutation,
  usePostApiPerformanceCardsAddOrUpdateUserKrMutation,
  useGetApiRolePermissionRolesUserRolePermissionsQuery,
  useLazyGetApiRolePermissionRolesUserRolePermissionsQuery,
  usePostApiRolePermissionAssignUsersToRoleByCompanyIdMutation,
  useGetApiRolePermissionRolesWithUsersByCompanyIdQuery,
  useLazyGetApiRolePermissionRolesWithUsersByCompanyIdQuery,
  usePostApiTargetAudienceResolveSuitableUsersMutation,
  useGetApiUserQuery,
  useLazyGetApiUserQuery,
  usePostApiUserMutation,
  useGetApiUserByIdQuery,
  useLazyGetApiUserByIdQuery,
  usePutApiUserByIdMutation,
  useDeleteApiUserByIdMutation,
  useGetApiUserByCompanyByCompanyIdQuery,
  useLazyGetApiUserByCompanyByCompanyIdQuery,
  useGetApiUserByCompanyGroupByCompanyGroupIdQuery,
  useLazyGetApiUserByCompanyGroupByCompanyGroupIdQuery,
  usePostApiUserImportAndCompareMutation,
  usePostApiUserProcessImportedUsersMutation,
  usePostApiUserGetUserParamsByCompanyGroupIdMutation,
  useGetApiUserTeamMembersQuery,
  useLazyGetApiUserTeamMembersQuery,
  useGetApiUserUsersForComboByCompanGroupIdQuery,
  useLazyGetApiUserUsersForComboByCompanGroupIdQuery,
  useGetApiUserUsersForComboByCardByCompanGroupIdQuery,
  useLazyGetApiUserUsersForComboByCardByCompanGroupIdQuery,
  usePostApiUserUpdateUserParamsByCompanyGroupIdMutation,
  usePostApiUserUpdateUsersMasterMutation,
  usePostApiUserUpdateUserLoginsMutation,
  usePostApiUserInitiateResetPasswordMutation,
  usePostApiUserCompletePasswordResetMutation,
} = injectedRtkApi;
