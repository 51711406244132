import React, { useEffect, useState } from 'react';
import { Form, Select, Input, Button, FormInstance } from 'antd';
import { PerformanceCardReviewDto, PerformanceCardReviewRequestDto, useLazyGetApiPerformanceCardsGetPerformanceCardOptionsQuery, usePostApiPerformanceCardsReviewMutation } from '../../../api/services/performanceCards';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { t } from 'i18next';
import { setLoading, setError } from '../../../features/app/appSlice';


const { Option } = Select;

interface ParameterSelectionProps {
    onNext: (reviewedData: PerformanceCardReviewDto, cardOptions: PerformanceCardReviewRequestDto) => void;
    onCancel: () => void;
    form: FormInstance
}

const ParameterSelectionStep: React.FC<ParameterSelectionProps> = ({ onNext, onCancel, form }) => {
    const [cardOptions, setCardOptions] = useState<PerformanceCardReviewRequestDto | null>()
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch();
    const [fetchCardParameters, { data: cardParameters }] = useLazyGetApiPerformanceCardsGetPerformanceCardOptionsQuery()
    const [reviewCardParameters, { data: reviewedData }] = usePostApiPerformanceCardsReviewMutation()


    useEffect(() => {
        if (user) {
            getCardParameters()
        }
    }, [user])

    const getCardParameters = async () => {
        try {
            dispatch(setLoading(true));
            await fetchCardParameters({
                companyId: user?.companyId ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Parametreler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const reviewCardData = async (values: any) => {
        try {
            dispatch(setLoading(true));
            var payload = {
                companyId: user?.companyId ?? "",
                cardName: values.name,
                performanceSystemId: values.system,
                periodId: values.period,
                scaleTypeId: values.scaleType,
                targetAudienceId: values.targetAudience
            }
            setCardOptions(payload)

            await reviewCardParameters({
                performanceCardReviewRequestDto: payload
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Parametreler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        if (reviewedData) {
            onNext(reviewedData!, cardOptions!);
        }
    }, [reviewedData])


    const handleNext = () => {
        form.validateFields().then((values) => {
            console.log('Seçili Değerler:', values);
            reviewCardData(values)
        }).catch(e => { });
    };

    return (
        <Form form={form} layout="vertical">
            <Form.Item name="name" label="Kart Adı" rules={[{ required: true, message: 'Kart adı girilmelidir!' }]}>
                <Input placeholder="Kart adı girin" />
            </Form.Item>

            <Form.Item name="period" label="Performans Dönemi" rules={[{ required: true, message: 'Performans dönemi seçilmelidir!' }]}>
                <Select placeholder="Dönem seçin">
                    {(cardParameters?.activePeriods ?? []).map((period) => (
                        <Option key={period.key} value={period.key}>
                            {period.value}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item name="system" label="Performans Sistemi" rules={[{ required: true, message: 'Performans sistemi seçilmelidir!' }]}>
                <Select placeholder="Performans sistemini seçin">
                    {(cardParameters?.performanceSystems ?? []).map((system) => (
                        <Option key={system.key} value={system.key}>
                            {system.value}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item name="targetAudience" label="Hedef Kitle" rules={[{ required: true, message: 'Hedef kitle seçilmelidir!' }]}>
                <Select placeholder="Hedef kitle seçin">
                    {(cardParameters?.targetAudiences ?? []).map((audience) => (
                        <Option key={audience.key} value={audience.key}>
                            {audience.value}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item name="scaleType" label="Skala Tipi" rules={[{ required: true, message: 'Skala tipi seçilmelidir!' }]}>
                <Select placeholder="Skala tipi seçin">
                    {(cardParameters?.scaleTypes ?? []).map((scale) => (
                        <Option key={scale.key} value={scale.key}>
                            {scale.value}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <div style={{ textAlign: 'right' }}>
                <Button onClick={onCancel} style={{ marginRight: 8 }}>
                    İptal
                </Button>
                <Button type="primary" onClick={handleNext}>
                    Devam
                </Button>
            </div>
        </Form>
    );
};

export default ParameterSelectionStep;
