import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ThemeConfig} from 'antd/lib';

interface ThemeColors {

    primaryColor: string | null
    secondaryColor: string | null
    ternaryColor: string | null
}

interface AppState {
    loading: boolean;
    error: string | null
    themeConfig: any | null
    themeColors: ThemeColors | null
}

const initialState: AppState = {
    loading: false,
    error: null,
    themeColors: {
        primaryColor: "#89A8B2",
        secondaryColor: "#001529",
        ternaryColor: "#E5E1DA",
    },
    themeConfig: {
        token: {
            "colorBgElevated": "#E5E1DA",
            "colorPrimary": "#89A8B2",
            "colorPrimaryBg": "#89A8B2",
            "colorPrimaryBgHover": "#B3C8CF",
            "colorPrimaryHover": "#B3C8CF"
        },
        components: {
            Layout: {
                "siderBg": "#89A8B2",
                "triggerColor": "#000000",
                "bodyBg": "#F1F0E8",
                "headerBg": "#89A8B2",
                "headerColor": "#001529",
            },
            Menu: {
                "darkItemBg": "#89A8B2",
                "darkItemColor": "#001529",
                "darkPopupBg": "#89A8B2",
            },
            Button: {},
            Progress: {
                "circleTextFontSize": "10px"
            },
            Slider: {
                handleColor: "green",
            }
        },
    }
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setError(state, action: PayloadAction<string | null>) {
            state.error = action.payload;
        },
        clearError(state) {
            state.error = null;
        },
        setTheme(state, action: PayloadAction<any>) {
            state.themeConfig = action.payload;
        },
        setThemeColors(state, action: PayloadAction<ThemeColors>) {
            state.themeColors = action.payload;
        },
    },
});

export const {setLoading, setError, clearError, setTheme, setThemeColors} = appSlice.actions;

export default appSlice.reducer;
