// encryptTransform.ts
import { Transform } from 'redux-persist';
import CryptoJS from 'crypto-js';

const SECRET_KEY: string = process.env.REACT_APP_PERSIST_KEY || 'default-secret';

function encrypt(data: string): string {
    return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
}

function decrypt(data: string): string {
    const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
}

// Helper: boş bir state döndür, ya da slice için default değer istersen buraya ekleyebilirsin
function getFallbackState<T>(): T {
    return {} as T;
}

export function createEncryptedTransform<T = unknown>(): Transform<T, string> {
    return {
        in: (inboundState: T): string => {
            try {
                const serialized = JSON.stringify(inboundState);
                return encrypt(serialized);
            } catch (err) {
                console.error('[EncryptTransform] Encrypt error:', err);
                return JSON.stringify(inboundState); // fallback
            }
        },
        out: (outboundState: string): T => {
            try {
                const decrypted = decrypt(outboundState);
                const parsed = JSON.parse(decrypted) as T;
                return parsed;
            } catch (err) {
                console.error('[EncryptTransform] Decrypt error: returning fallback state', err);
                return getFallbackState<T>();
            }
        },
    };
}
