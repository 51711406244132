import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useLazyGetApiMCompanyGroupsCompanygroupsByIdQuery} from "../../../api/services/MCompanyGroup";
import {setError, setLoading} from "../../../features/app/appSlice";
import {t} from "i18next";
import {TabsProps} from "antd/lib";
import MCompanyGroupCompanies from "../Companies/MCompanyGroupCompanies";
import MCompanyGroupLicence from "../Companies/MCompanyGroupLicence";
import {Card, Tabs} from "antd";
import moment from "moment/moment";
import {useLazyGetApiMCompanyCompaniesByIdQuery} from "../../../api/services/MCompany";
import MCompanyDetailsInfo from "./MCompanyDetailsInfo";
import MCompanyDetailsUsers from "./MCompanyDetailsUsers";
import MCompanyDetailsEmail from "./MCompanyDetailsEmail";
import MCompanyDetailsLicence from "./MCompanyDetailsLicence";
import MCompanyDetailsTheme from "./MCompanyDetailsTheme";

export interface IMCompanyDetails {
    
}

interface RouteParams {
    groupId: string;
    companyId: string
}


const MCompanyDetails: React.FC<IMCompanyDetails> = ({}) => {
    const dispatch = useDispatch();
    const {groupId, companyId} = useParams<RouteParams>();
    
    const [fetchCompany, {
        data: company,
        isLoading: ccompanyLoading,
        error: companyError
    }] = useLazyGetApiMCompanyCompaniesByIdQuery()

    useEffect(() => {
        if (companyId)
            loadCompany()
    }, [companyId]);
    const loadCompany = async () => {
        try {
            dispatch(setLoading(true));
            await fetchCompany({
                id: companyId
            }).unwrap()
        } catch (err) {
            dispatch(setError(t('sirketleri-cekerken-problem-olustu')));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Bilgiler',
            children: <MCompanyDetailsInfo companyId={companyId} />,
        },
        {
            key: '2',
            label: 'Tema',
            children: <MCompanyDetailsTheme companyId={companyId} />,
        },
        {
            key: '3',
            label: 'Kullanıcılar',
            children: <MCompanyDetailsUsers companyId={companyId} />
        },
        {
            key: '4',
            label: 'E Posta Ayarları',
            children: <MCompanyDetailsEmail companyId={companyId} />
        },
        {
            key: '5',
            label: 'Lisans Bilgileri',
            children: <MCompanyDetailsLicence />
        }
    ];

    return (
        <div>
            <Card>
                <div className={"flex flex-row"}>
                    <img alt={`${(company as any)?.groupName}`}
                         src={`${process.env.REACT_APP_API_BASE_URL}/api/image/images/companies/${(company as any)?.logo}`}
                         style={{maxWidth: 'auto', maxHeight: "50px", aspectRatio: "auto"}}/>
                    <div className={"ml-5 font-medium"}>
                        <p>{(company as any)?.name}</p>
                        <p>{moment((company as any)?.startDate).format("DD.MM.YYYY")}</p>
                    </div>
                </div>

            </Card>

            <Tabs defaultActiveKey="1" items={items} className={"mt-3"}/>

        </div>
    );
};

export default MCompanyDetails;