import React from 'react';

export interface IMCompanyDetailsLicence {

}

const MCompanyDetailsLicence: React.FC<IMCompanyDetailsLicence> = ({}) => {
    return (<>


    </>)
};

export default MCompanyDetailsLicence;