import React, {useEffect, useState} from 'react';
import {setError, setLoading} from "../../../features/app/appSlice";
import {t} from "i18next";
import {Button, Card, Checkbox, ColorPicker, Form} from "antd";
import {useDispatch} from "react-redux";
import {
    CompanySettingDto,
    useLazyGetApiMCompanySettingsByCompanyIdQuery,
    usePostApiMCompanySettingsMutation
} from "../../../api/services/MCompany";

export interface IMCompanyDetailsEmail {
    companyId: string
}

const MCompanyDetailsEmail: React.FC<IMCompanyDetailsEmail> = ({companyId}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [fetchSettings] = useLazyGetApiMCompanySettingsByCompanyIdQuery()
    const [saveSetting] = usePostApiMCompanySettingsMutation()
    const [update, setUpdate] = useState(false)
    useEffect(() => {
        if (companyId)
            getEmailSettings()
    }, [companyId]);

    const getEmailSettings = async () => {
        try {
            dispatch(setLoading(true));
            var settings = await fetchSettings({
                companyId: companyId,
                keys: ["SmtpServer", "SmtpPort", "Username", "Password", "FromEmail", "EnableSsl"],
            }).unwrap()

            form.setFieldsValue({"SmtpServer": (settings as any[])?.find(k => k.key == "SmtpServer")?.value});
            form.setFieldsValue({"SmtpPort": (settings as any[])?.find(k => k.key == "SmtpPort")?.value});
            form.setFieldsValue({"Username": (settings as any[])?.find(k => k.key == "Username")?.value});
            form.setFieldsValue({"Password": (settings as any[])?.find(k => k.key == "Password")?.value});
            form.setFieldsValue({"FromEmail": (settings as any[])?.find(k => k.key == "FromEmail")?.value});

            var checkValue = (settings as any[])?.find(k => k.key == "EnableSsl")?.value
            form.setFieldsValue({"EnableSsl": checkValue == "true" ? "checked" : undefined});
            

            setUpdate(!update)
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('E Posta çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const saveSettings = async (values: any) => {
        try {
            dispatch(setLoading(true));
            await saveSetting({
                body: [
                    {
                        companyId: companyId,
                        key: "SmtpServer",
                        value: values.SmtpServer
                    } as CompanySettingDto,
                    {
                        companyId: companyId,
                        key: "SmtpPort",
                        value: values.SmtpPort
                    } as CompanySettingDto,
                    {
                        companyId: companyId,
                        key: "Username",
                        value: values.Username
                    } as CompanySettingDto,
                    {
                        companyId: companyId,
                        key: "Password",
                        value: values.Password
                    } as CompanySettingDto,
                    {
                        companyId: companyId,
                        key: "FromEmail",
                        value: values.FromEmail
                    } as CompanySettingDto,
                    {
                        companyId: companyId,
                        key: "EnableSsl",
                        value: values.EnableSsl ? "true" : "false"
                    } as CompanySettingDto
                ]
            }).unwrap()
            await getEmailSettings()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('E posta ayarları kaydedilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }
    return (<>
        <Card title={
            <div className={"flex flex-row justify-between items-center"}>
                <span>E Posta Ayarları</span>
                <div>
                    <Button type={"primary"} onClick={async () => {
                        form.submit()
                    }}> Kaydet </Button>
                </div>
            </div>
        }
        >
            <Form form={form} layout={"vertical"} onFinish={saveSettings}>
                <Form.Item
                    label={"Smtp Server"}
                    name={[`SmtpServer`]}
                >
                    <input className={"border-black border-[1px] rounded-md p-2 w-full"}/>
                </Form.Item>
                <Form.Item
                    label={"Smtp Port"}
                    name={["SmtpPort"]}
                >
                    <input className={"border-black border-[1px] rounded-md p-2 w-full"}/>
                </Form.Item>
                <Form.Item
                    label={"User Name"}
                    name={["Username"]}
                >
                    <input className={"border-black border-[1px] rounded-md p-2 w-full"}/>
                </Form.Item>
                <Form.Item
                    label={"Password"}
                    name={["Password"]}
                >
                    <input className={"border-black border-[1px] rounded-md p-2 w-full"}/>
                </Form.Item>
                <Form.Item
                    label={"From Email"}
                    name={["FromEmail"]}
                >
                    <input className={"border-black border-[1px] rounded-md p-2 w-full"}/>
                </Form.Item>
                <Form.Item
                    label={"Enable Ssl"}
                    name={["EnableSsl"]}
                    valuePropName={"checked"}
                >
                    <Checkbox/>
                </Form.Item>

            </Form>
        </Card>

    </>)
};

export default MCompanyDetailsEmail;