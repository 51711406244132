import React, {useEffect, useState} from 'react';
import {Button, Drawer, Form, Input, Modal, notification, Space, Spin, Table} from "antd";
import SuitableUsers from "../../../components/SuitableUsers/SuitableUsers";
import Column from "antd/es/table/Column";
import {useDispatch, useSelector} from "react-redux";
import {
    CompanyDto,
    useLazyGetApiCompanyByGroupByGroupIdQuery,
    usePostApiCompanyMutation,
    usePutApiCompanyByIdMutation
} from "../../../api/services/company";
import {RootState} from "../../../app/store";
import {setError, setLoading} from "../../../features/app/appSlice";
import {t} from "i18next";
import {
    useDeleteApiMCompanyGroupsCompanygroupsByIdMutation,
    useLazyGetApiMCompanyGroupsCompanygroupsQuery,
    usePostApiMCompanyGroupsCompanygroupsMutation, usePutApiMCompanyGroupsCompanygroupsByIdMutation
} from "../../../api/services/MCompanyGroup";
import MCompanyGroupsTable from "./MCompanyGroupsTable";
import moment from "moment";
import {GetProp, UploadProps} from "antd/lib";
import {useHistory} from "react-router-dom";
import MCompanyGroupForm from "./MCompanyGroupForm";

export interface IMCompanies {
}

const MCompanyGroups: React.FC<IMCompanies> = ({}) => {
    const dispatch = useDispatch();
    const [fetchCompanyGroups, {
        data: companyGroups,
        isLoading: loading,
        error
    }] = useLazyGetApiMCompanyGroupsCompanygroupsQuery()
    const user = useSelector((state: RootState) => state.auth?.user);
    const [createCompany, {
        data: createdCompany,
        isSuccess: companyCreationSuccess,
        isLoading: companyCreating,
        error: creationError
    }] = usePostApiMCompanyGroupsCompanygroupsMutation()
    const [updateCompany, {
        isSuccess: companyUpdateSuccess,
        isLoading: companyUpdating,
        error: updateError
    }] = usePutApiMCompanyGroupsCompanygroupsByIdMutation()
    const [deleteGroupService] = useDeleteApiMCompanyGroupsCompanygroupsByIdMutation()
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState<any | null>(null);
    const [form] = Form.useForm();
    const {confirm} = Modal;

    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
        form.resetFields();
    };

    const loadCompanies = async () => {
        try {
            dispatch(setLoading(true));
            await fetchCompanyGroups().unwrap()
        } catch (err) {
            dispatch(setError(t('sirketleri-cekerken-problem-olustu')));
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (user)
            loadCompanies();
    }, [user]);

    const onFinish = (values: any) => {
        const createUserFunc = async () => {
            try {
                
                const formData = new FormData();
                formData.append('GroupName', values.groupName);  // Zorunlu alan
                formData.append('Nickname', values.nickname);    // Zorunlu alan
                formData.append('StartDate', values.startDate);    // Zorunlu alan
                formData.append('EndDate', values.endDate ? moment(values.endDate).format() : "");    // Zorunlu alan

                if (values.FaviconU !== undefined) {
                    formData.append('Favicon', values.FaviconU.fileList[0].originFileObj as File); // Dosya
                }

                if (values.LoginIconU !== undefined) {
                    formData.append('LoginIcon', values.LoginIconU.fileList[0].originFileObj as File); // Dosya
                }

                if (values.LogoU !== undefined) {
                    formData.append('Logo', values.LogoU.fileList[0].originFileObj as File); // Dosya
                }
                
                await createCompany({
                    // @ts-ignore
                    body: formData
                }).unwrap()
            } catch (err) {
                dispatch(setError(t('sirket-eklerken-hata-olustu')));
            }
        };

        const updateUserFunc = async () => {

            try {
                const formData = new FormData();
                formData.append('id', selectedCompany?.id ?? "");  // Zorunlu alan
                formData.append('GroupName', values.groupName);  // Zorunlu alan
                formData.append('Nickname', values.nickname);    // Zorunlu alan
                formData.append('StartDate', moment(values.startDate).format());    // Zorunlu alan
                formData.append('EndDate', values.endDate ? moment(values.endDate).format() : "");    // Zorunlu alan

                if (values.FaviconU !== undefined) {
                    formData.append('Favicon', values.FaviconU.fileList[0].originFileObj as File); // Dosya
                }

                if (values.LoginIconU !== undefined) {
                    formData.append('LoginIcon', values.LoginIconU.fileList[0].originFileObj as File); // Dosya
                }

                if (values.LogoU !== undefined) {
                    formData.append('Logo', values.LogoU.fileList[0].originFileObj as File); // Dosya
                }
                
                await updateCompany({
                    id: selectedCompany?.id ?? "",
                    // @ts-ignore
                    body: formData
                }).unwrap()
            } catch (err) {
                dispatch(setError(t('failed-to-add-user')));
            } finally {
                dispatch(setLoading(false));
            }
        };

        if (!isEditMode)
            createUserFunc()
        else
            updateUserFunc()
    };

    useEffect(() => {
        if (companyCreationSuccess) {
            notification.success({message: t('yeni-sirket-eklendi')});
            closeDrawer();
            loadCompanies();
        }
    }, [companyCreationSuccess])

    useEffect(() => {
        if (companyUpdateSuccess) {
            closeDrawer();
            loadCompanies();
        }
    }, [companyUpdateSuccess])

    const handleEdit = (company: any) => {

        setSelectedCompany(company);
        setIsEditMode(true);
        form.setFieldsValue({
            ...company,
            startDate: moment(company.startDate),
            endDate: company.endDate != null ? moment(company.endDate) : undefined,
        });
        showDrawer();
    };

    const handleAdd = () => {
        setIsEditMode(false);
        showDrawer();
    }

    const handleCancel = () => {
        setIsDrawerVisible(false);
        form.resetFields();
    };

    const deleteGroup = async (value: string) => {
        try {
            await deleteGroupService({
                id: value
            }).unwrap()
            loadCompanies();
        } catch (err) {
            dispatch(setError(t('sirket-eklerken-hata-olustu')));
        }
    };

    return (
        <div>
            <Button type="primary" style={{marginBottom: 16}} onClick={handleAdd}>
                Yeni Şirket Grubu Ekle
            </Button>
            <Modal
                title={isEditMode ? "Şirket Grubu Düzenleme" : "Şirket Grubu Ekleme"}
                width={720}
                onCancel={handleCancel}
                onClose={() => {
                    handleCancel()
                }}
                closable={true}
                maskClosable={false}
                destroyOnClose={true}
                open={isDrawerVisible}
                footer={<Space>
                    <Button onClick={handleCancel}>
                        İptal
                    </Button>
                    <Button type="primary" onClick={() => {
                        form.submit()
                    }}>
                        Kaydet
                    </Button>
                </Space>}
            >
                <Spin spinning={loading}>
                    <MCompanyGroupForm form={form} selectedCompany={selectedCompany} isEditMode={isEditMode}
                                 onFinish={onFinish}/>
                </Spin>
            </Modal>
            <MCompanyGroupsTable
                companies={(companyGroups as any[]) ?? []}
                handleEdit={handleEdit}
                deleteGroup={(id: string) => {
                    deleteGroup(id)
                }}/>
        </div>
    );
};

export default MCompanyGroups;