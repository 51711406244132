import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Space } from 'antd';
import SuitableUsers from '../../components/SuitableUsers/SuitableUsers';
import { useLazyGetApiTargetAudienceCompanyTargetAudiencesQuery, usePostApiTargetAudienceSaveTargetAudienceMutation } from '../../api/services/targetAudience';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setError, setLoading } from '../../features/app/appSlice';
import { t } from 'i18next';
import { TargetAudienceDto } from '../../api/services/company';
import moment from 'moment';

const { Column } = Table;

interface Audience {
  id: number;
  number: string;
  name: string;
  size: number;
}

interface FilterRow {
  action: string;
  variable: string;
  value: string[];
}

const TargetAudienceSelection: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [selectedAudience, setSelectedAudience] = useState<TargetAudienceDto | null>(null);
  const [filters, setFilters] = useState<FilterRow[]>([]);
  const [form] = Form.useForm();
  const [resultsVisible, setResultsVisible] = useState<boolean>(false);
  const [fetchTargetAudience, { data }] = useLazyGetApiTargetAudienceCompanyTargetAudiencesQuery()
  const [saveTargetAudienceFilter] = usePostApiTargetAudienceSaveTargetAudienceMutation()
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth?.user);

  useEffect(() => {
    if (user) {
      fetchFilterDatas()
    }
  }, [user])

  const fetchFilterDatas = async () => {
    try {
      dispatch(setLoading(true));

      await fetchTargetAudience().unwrap()
    } catch (err) {
      dispatch(setError(t('Filtreler yüklenirken hata oluştu')));
    } finally {
      dispatch(setLoading(false));
    }
  }

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedAudience(null);
    form.resetFields();
    setResultsVisible(false);
  };

  const onFinish = async (values: any) => {
    const dataToSend = { ...values, filters };

    try {
      dispatch(setLoading(true));
      await saveTargetAudienceFilter({
        resolveSuitableUsersRequest: {
          id: selectedAudience?.id ?? undefined,
          filters: dataToSend.filters,
          targetAudienceName: dataToSend.name,
          targetAudienceNo: dataToSend.number
        }
      }).unwrap()
    } catch (err) {
      dispatch(setError(t('Filtreler kaydedilirken hata oluştu')));
    } finally {
      dispatch(setLoading(false));
    }

    fetchFilterDatas()
    if (selectedAudience?.id == null) {
      handleCancel();
    }
  };

  const handleEdit = (record: TargetAudienceDto) => {
    setSelectedAudience(record);
    form.resetFields();
  };

  useEffect(() => {
    if (selectedAudience != null) {
      showModal();
    }
  }, [selectedAudience])

  const handleFilterChange = (filters: FilterRow[]) => {
    setFilters(filters);
    setResultsVisible(true);
  };

  const getAudienceFilterNo = () => {
    return `TA-${moment().format("yyyy-MM-DD-mm")}`;
  }

  const handleModalClose = () => {
    setFilters([])
    setSelectedAudience(null);
    setResultsVisible(false);
  }

  return (
    <div>
      <Button type="primary" onClick={showModal}>
        Hedef Kitle Ekle
      </Button>
      <Table dataSource={data} rowKey="id" style={{ marginTop: 16 }} onRow={(record) => ({
        onClick: () => handleEdit(record),
      })}>
        <Column title="Hedef Kitle No" dataIndex="targetAudienceNo" key="targetAudienceNo" />
        <Column title="Hedef Kitle Adı" dataIndex="targetAudienceName" key="targetAudienceName" />
      </Table>
      <Modal
        title={selectedAudience ? "Hedef Kitle Güncelle" : "Hedef Kitle Ekle"}
        visible={visible}
        onCancel={handleCancel}
        onClose={handleModalClose}
        afterClose={handleModalClose}
        destroyOnClose={true}
        footer={null}
        width={800}
        bodyStyle={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={selectedAudience ? {
            number: selectedAudience.targetAudienceNo,
            name: selectedAudience.targetAudienceName,
          } : {
            number: getAudienceFilterNo()
          }}
        >
          <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
            <Form.Item
              name="number"
              label="Hedef Kitle No"
              rules={[{ required: true, message: 'Lütfen hedef kitle numarasını girin' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="Hedef Kitle Adı"
              rules={[{ required: true, message: 'Lütfen hedef kitle adını girin' }]}
            >
              <Input />
            </Form.Item>
          </Space>
          <SuitableUsers initialData={selectedAudience} onFilterChange={handleFilterChange} />
          {resultsVisible && (
            <Form.Item style={{ textAlign: 'right' }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  Kaydet
                </Button>
                <Button onClick={handleCancel}>
                  İptal
                </Button>
              </Space>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default TargetAudienceSelection;
