import React, {useEffect, useState} from 'react';
import {Alert, Avatar, Button, Card, Col, Divider, List, Modal, Row, Select, Statistic, Tabs} from "antd";
import {useLazyGetApiUserTeamMembersQuery, UserDto} from "../../api/services/user";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {useLazyGetApiFeedbackGetTeamFeedbacksByUserIdQuery} from "../../api/services/Feedback";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import {PerformanceResultTypes} from "../Reports/Components/PerformanceResultType";
import ReportDetailTab, {ReportDetailItemType} from "../Reports/Components/ReportDetailTab";
import {data, IDetailItem} from "../Reports/data";
import {ExclamationCircleOutlined, UserOutlined, CheckCircleOutlined} from "@ant-design/icons";
import ResultSelection from "./Components/ResultSelection";
import {
    PerformanceCardDto,
    PeriodDto, useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery,
    useLazyGetApiPeriodAllQuery,
    useLazyGetApiPeriodGetActivePeriodsQuery
} from "../../api/services/period";
import dayjs from "dayjs";
import {tab} from "@testing-library/user-event/dist/tab";
import {useLazyGetApiPerformanceCardsGetTeamMembersForByPeriodIdQuery} from "../../api/services/performanceCards";
import {
    ManagerEvaluationDto,
    useGetApiManagerEvaluationGetCompletionInfoQuery,
    useLazyGetApiManagerEvaluationGetCompletionInfoQuery,
    useLazyGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery,
    usePostApiManagerEvaluationMarkCompletionMutation,
    usePostApiManagerEvaluationSaveManagerEvaluationMutation
} from "../../api/services/ManagerEvaluation";
import evaluationDetail from "../CalibrationEvaluation/EvaluationDetail";

const {confirm, error} = Modal;

export interface IManagerEvaluation {

}

const ManagerEvaluation: React.FC<IManagerEvaluation> = ({}) => {
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch()
    const [getTeamUsers, {data: teamUsers}] = useLazyGetApiPerformanceCardsGetTeamMembersForByPeriodIdQuery()
    const [getCardForPeriod] = useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery()
    const [getManagerEvaluations, {data: oldEvaluations}] = useLazyGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery()
    const [getCompletion] = useLazyGetApiManagerEvaluationGetCompletionInfoQuery()
    const [markCompletion] = usePostApiManagerEvaluationMarkCompletionMutation()
    const [saveEvaluation] = usePostApiManagerEvaluationSaveManagerEvaluationMutation()
    const [selectedUser, setSelectedUser] = useState<UserDto | undefined>()
    const [selectedItem, setSelectedItem] = useState<IDetailItem | undefined>()
    const [fetchPeriods, {data: Periods}] = useLazyGetApiPeriodAllQuery()
    const [selectedPeriod, setSelectedPeriod] = useState<PeriodDto | undefined>();
    const [performanceCard, setPerformanceCard] = useState<PerformanceCardDto | undefined>(undefined)
    const [selectedEvaluation, setSelectedEvaluation] = useState<ManagerEvaluationDto | undefined>(undefined)
    const [isEvaluating, setIsEvaluating] = useState(false)
    const [evaluationCompleted, setEvaluationCompleted] = useState(false)
    const [periodFinished, setPeriodFinished] = useState(false)

    useEffect(() => {
        if (user) {
            getActivePeriods()
        }
    }, [user])

    const getCompletionInfo = async (periodId: string) => {
        try {
            dispatch(setLoading(true));
            var completionInfo = await getCompletion({
                managerId: user?.id ?? "",
                periodId: periodId
            }).unwrap()

            setEvaluationCompleted(completionInfo != null)
        } catch (err: any) {
            setEvaluationCompleted(false)
        } finally {
            dispatch(setLoading(false));
        }
    }

    const markEvCompleted = async () => {
        try {
            dispatch(setLoading(true));
            await markCompletion({
                managerEvaluationCompletionDto: {
                    managerId: user?.id ?? "",
                    periodId: selectedPeriod?.id ?? "",
                }
            }).unwrap()

            getCompletionInfo(selectedPeriod?.id ?? "")
        } catch (err: any) {

        } finally {
            dispatch(setLoading(false));
        }
    }

    const getActivePeriods = async () => {
        try {
            dispatch(setLoading(true));
            var periods = await fetchPeriods().unwrap()

            //Varsa Biten periyodların ilkini seç
            var finished = periods.filter(k => dayjs(k.endDate).isBefore())
            if (finished.length > 0) {
                var first = finished[0]
                setSelectedPeriod(first);
                checkSelectedPeriod(first)
                getCompletionInfo(first.id ?? "")
                FetchTeamUsers(first.id ?? "")
            }
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const FetchTeamUsers = async (periodId: string) => {
        try {
            dispatch(setLoading(true));
            var users = await getTeamUsers(
                {
                    periodId: periodId,
                }
            ).unwrap()

            var fi = (users as UserDto[])?.[0]
            setSelectedUser(fi)

        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Kullanıcılar çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const FetchCardForPeriod = async () => {
        if (selectedUser == undefined || selectedPeriod == undefined)
            return

        try {
            var cards = await getCardForPeriod({
                employeeId: selectedUser?.id ?? "",
                periodId: selectedPeriod?.id ?? "",
            }).unwrap()

            if (cards.length > 0) {
                var card = cards[0]
                setPerformanceCard(card)
            } else {
                setPerformanceCard(undefined)
            }

        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Periyoda ait kart çekilirken hata oluştu')));
        }
    }

    const FetchEvaluationDetails = async (selectedPeriod: PeriodDto) => {
        if (selectedPeriod == undefined)
            return

        try {
            setIsEvaluating(true)
            var evaluations = await getManagerEvaluations({
                managerId: user?.id ?? "",
                periodId: selectedPeriod?.id ?? "",
                
            }).unwrap()

            // @ts-ignore
            var selectedEvaluation = (evaluations as any[]).find(k => k.userId === selectedUser?.id ?? "")
            setSelectedEvaluation(selectedEvaluation)
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Periyoda ait kart çekilirken hata oluştu')));
        } finally {
            setIsEvaluating(false)
        }
    }

    const SaveEvaluation = async (how: string | null, what: string | null) => {
        if (selectedPeriod == undefined)
            return

        try {
            setIsEvaluating(true)
            await saveEvaluation({
                managerEvaluationDto: {
                    userId: selectedUser?.id ?? "",
                    managerId: user?.id ?? "",
                    periodId: selectedPeriod?.id ?? "",
                    cardId: performanceCard?.id ?? "",
                    how: how,
                    what: what
                }
            }).unwrap()

            FetchEvaluationDetails(selectedPeriod)
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Periyoda ait kart çekilirken hata oluştu')));
        } finally {
            setIsEvaluating(false)
        }
    }

    const fetchEvaluations = async (selectedPeriod: PeriodDto) => {
        FetchEvaluationDetails(selectedPeriod)
    }

    useEffect(() => {
        if (selectedUser) {
            FetchCardForPeriod()
            setSelectedItem({
                id: selectedUser.id ?? "",
                name: `${selectedUser.firstName ?? ""} ${selectedUser.lastName ?? ""}`,
                title: selectedUser.title ?? "",
                periodId: selectedPeriod?.id ?? "",
                performanceResults:
                    {
                        how: 1,
                        calibratedHow: 2,
                        what: 3,
                        calibratedWhat: 4
                    }

            })

            var selectedEvaluation = ((oldEvaluations as any[]) ?? []).find(k => k.userId === selectedUser?.id ?? "")
            setSelectedEvaluation(selectedEvaluation)
        }
    }, [selectedUser]);

    const checkSelectedPeriod = (selectedPeriod: PeriodDto) => {
        setEvaluationCompleted(false)
        getCompletionInfo(selectedPeriod.id ?? "")

        if (dayjs(selectedPeriod.endDate).isBefore()) {
            setPeriodFinished(true)
            FetchCardForPeriod()
            fetchEvaluations(selectedPeriod)

            FetchTeamUsers(selectedPeriod.id ?? "")
        } else {
            setPeriodFinished(false)
        }
    }

    var tabs = [
        {
            label: `Performans Boyutu`,
            key: "ps1",
            children: <ResultSelection selectedItem={selectedItem}
                                       evaluating={isEvaluating}
                                       card={performanceCard}
                                       evaluationCompleted={evaluationCompleted}
                                       selectedEvaluation={selectedEvaluation}
                                       howSelected={(ev, id) => {
                                           SaveEvaluation(id, null)
                                       }}
                                       whatSelected={(ev, id) => {
                                           SaveEvaluation(null, id)
                                       }}
            />
        },
        {
            label: `Performans Kartları`,
            key: "ps2",
            children: <ReportDetailTab type={ReportDetailItemType.performanceCards}
                                       selectedItem={selectedItem}/>
        },
        {
            label: `1-1 Görüşmeler`,
            key: "ps3",
            children: <ReportDetailTab type={ReportDetailItemType.OntoOneMeetins}
                                       selectedItem={selectedItem}/>
        },
        {
            label: `Geri Bildirimler`,
            key: "ps4",
            children: <ReportDetailTab type={ReportDetailItemType.Feedbacks}
                                       selectedItem={selectedItem}/>
        },
    ]

    const showCompleteConfirm = () => {
        if (validateCompletion()) return

        confirm({
            title: "Dönem Tamamlama",
            icon: <ExclamationCircleOutlined/>,
            content: "Yaptığınız değerlendirmeler kaydedilerek dönem tamamlanacaktır. Onaylıyor musunuz?",
            okText: t('yes'),
            okType: 'danger',
            cancelText: t('no'),
            onOk() {
                markEvCompleted()
            },
        });
    };

    const validateCompletion = () => {
        var users = (teamUsers ?? []) as UserDto[]
        var hasError = false
// debugger

        // if (users.length != (oldEvaluations as any[]).length) {
        //     hasError = true
        // }
        if (!hasError)
            for (const oldEvaluation of (oldEvaluations as any[])) {
                if (oldEvaluation.managerWhat == null || oldEvaluation.managerHow == null) {
                    hasError = true
                    break
                }
            }

        if (hasError) {
            error({
                title: "Dönem Tamamlama",
                icon: <ExclamationCircleOutlined/>,
                content: "Bütün değerlendirmeleri henüz yapmadınız. Değerlendirme sonrasında dönemi tamamlayabilirsiniz.",
                okText: t('OK'),
                okType: 'danger',
            });
        }
        return hasError
    }
    const isEvaluated = (userId: string) => {
        var userEv = ((oldEvaluations as any[]) ?? []).find(k => k.userId === userId && k.periodId == selectedPeriod?.id)
        return (userEv?.managerHow != undefined && userEv?.managerWhat != undefined)
    }

    const isInEvaluationPeriod = () => {
        return dayjs(selectedPeriod?.startDateFirstManager).isBefore() &&
            dayjs(selectedPeriod?.endDateFirstManager).isAfter()
    }
    return (<>
            <div className={"flex flex-col h-full"}>
                <div className={"flex flex-row justify-between min-h-[50px]"}>
                    <div>
                        <div className={"flex flex-row items-baseline"}>
                            <text className={"font-semibold mr-2"}>Seçili Dönem:</text>
                            <Select
                                placeholder="Dönem Seçin"
                                onChange={(value) => {
                                    var v = (Periods ?? []).find(k => k.id == value)
                                    setSelectedPeriod(v)
                                    checkSelectedPeriod(v!)
                                }}
                                style={{width: 300}}
                                value={selectedPeriod?.id} // Seçili eleman
                                showSearch={true}
                                filterOption={(input, option) =>
                                    `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                                }
                                options={((Periods ?? []) as PeriodDto[]).map(k => {
                                        return {value: k.id, label: `${k.periodName}`};
                                    }
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        {
                            !isInEvaluationPeriod() &&
                            !evaluationCompleted &&
                            periodFinished &&
                            <>
                                <Alert message={"Değerlendirme Döneminde değilsiniz"}
                                       type="info"/>
                            </>
                        }
                        {
                            evaluationCompleted &&
                            <>
                                <Alert message={"Değerlendirme Tamamlandı"}
                                       type="info"/>
                            </>
                        }
                        {Periods && periodFinished && !evaluationCompleted &&
                            isInEvaluationPeriod() &&
                            <Button type="primary"
                                    htmlType="submit"
                                    className={"ml-2"}
                                    onClick={() => {
                                        showCompleteConfirm()
                                    }}>
                                Tamamla
                            </Button>
                        }
                    </div>
                </div>
                {
                    periodFinished &&
                    <div className={"flex flex-row mt-5 w-full h-full"}>
                        <Row gutter={[16, 30]} className={"w-full h-full"}>
                            <Col span={6} className={"bg-[#E5E1DA] rounded-md p-3 h-[calc(100%-30px)] "}>
                                <List
                                    className={"h-full overflow-scroll no-scrollbar"}
                                    itemLayout="horizontal"
                                    dataSource={(teamUsers ?? []) as UserDto[]}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            style={{padding: '0px'}}
                                            onClick={() => {
                                                setSelectedUser(item)
                                            }}>
                                            <List.Item.Meta
                                                style={{backgroundColor: selectedUser?.id === item.id ? "#89A8B2" : "transparent"}}
                                                className={`px-2 py-2 cursor-pointer transform rounded-md`}
                                                avatar={<Avatar
                                                    style={{color: isEvaluated(item.id ?? "") ? "#00FF00" : "#ffffff"}}
                                                    icon={isEvaluated(item.id ?? "") ?
                                                        <CheckCircleOutlined/> :
                                                        <UserOutlined/>}/>}
                                                title={`${item.firstName} ${item.lastName}`}
                                                description={`${item.title ?? "-"}`}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Col>

                            <Col span={18}>
                                <>
                                    {selectedUser && teamUsers &&
                                        <div className={"flex flex-row items-center ml-2"}>
                                            <Avatar icon={<UserOutlined/>} size={"large"}/>
                                            <h2 className={"ml-2"}>{selectedUser?.firstName} {selectedUser?.lastName} ({selectedUser?.title ?? ""})</h2>
                                        </div>
                                    }
                                    {teamUsers && (teamUsers as UserDto[]).length > 0 &&
                                        <Tabs
                                            className={"mt-3"}
                                            type="card"
                                            items={tabs}
                                        />
                                    }
                                </>
                            </Col>
                        </Row>
                    </div>
                }
                {
                    Periods && !periodFinished &&
                    <div className={"h-full w-full flex justify-center items-center"}>
                        <Alert message={"Seçili dönem tamamlanmadığı için değerlendirme yapamazsınız."}
                               type="warning"
                               className={"w-2/3 text-center"}/>
                    </div>
                }
            </div>

        </>
    )
};

export default ManagerEvaluation;