import React, {useEffect, useState} from 'react';
import {Button, Card, ColorPicker, Form, message, Modal} from "antd";
import {usePostApiUserInitiateResetPasswordMutation} from "../../../api/services/user";
import {usePostApiMCompanyUpdateadminpasswordMutation} from "../../../api/services/MCompany";
import {CopyOutlined} from "@ant-design/icons";
import {useCopyToClipboard} from "usehooks-ts";

export interface IMCompanyDetailsUsers {
    companyId: string
}

const MCompanyDetailsUsers: React.FC<IMCompanyDetailsUsers> = ({companyId}) => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [triggerResetPassword] = usePostApiMCompanyUpdateadminpasswordMutation()
    const [showManagerUserPopup, setShowManagerUserPopup] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [_, copy] = useCopyToClipboard()

    const resetAdminPassword = async () => {
        try {
            var adminPassword = await triggerResetPassword({
                companyId: companyId
            }).unwrap()
            setNewPassword((adminPassword as any).createdPass ?? "")

        } catch (e) {
            messageApi.open({
                type: 'error',
                content: 'Şifre sıfırlanırken hata oluştu.',
            });
        }
    }

    useEffect(() => {
        if(newPassword != "") {
            setShowManagerUserPopup(true)
        }
    }, [newPassword]);
    
    return (<>
        {contextHolder}
        <Modal title="Yönetici Kullancısı"
               open={showManagerUserPopup}
               onCancel={() => {
                   setShowManagerUserPopup(false)
               }}
               footer={null}>
            <p>Yönetici kullanıcısı şifresi aşağıdaki gibi oluşturuldu. Sıfırlayana dek tekrar gösterilmeyecek.</p>
            <p className={"mt-2"}><span className={"font-semibold"}>E Posta: </span> admin@co-hr.com.tr</p>
            <p className={"mt-5 h-[40px] w-full rounded-lg bg-amber-200 flex flex-row justify-between items-center p-2"}>
                <span>{newPassword}</span>
                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        copy(newPassword)
                        messageApi.open({
                            type: 'success',
                            content: 'Şifre Kopyalandı',
                        });

                    }}>
                    <CopyOutlined/>
                </Button>
            </p>
        </Modal>

        <Card title={
            <div className={"flex flex-row justify-between items-center"}>
                <span>Yönetici Kullanıcısı</span>

            </div>
        }
        >
            <div className="flex flex-row justify-between items-center">
                <span className={"font-semibold"}>admin@co-hr.com.tr</span>
                <div>
                    <Button type={"primary"} onClick={() => {
                        resetAdminPassword()
                    }}> Şifreyi Sıfırla </Button>
                </div>
            </div>
        </Card>
    </>)
};

export default MCompanyDetailsUsers;