import {Button, Card, Collapse, Progress, Slider, Space, Tooltip} from "antd";
import {ActivePerformanceCardDto, UserKrDetailsDto, UserKrUpdateDto} from "../../../api/services/performanceCards";
import {
    EditOutlined,
    EyeInvisibleOutlined,
    HistoryOutlined,
    CheckCircleOutlined, UpOutlined, DownOutlined, RiseOutlined
} from '@ant-design/icons';
import {useEffect, useState} from "react";
import KRInputModal from "./Modal/KRInputModal";
import ProgressInputModal from "./Modal/ProgressInputModal";
import * as React from "react";
import {PanelProps} from "antd/es/splitter/interface";
import moment from "moment/moment";
import {PerformanceCardGroupType} from "../PerformanceCardGroupType";
import {hasProgressType, OKRProgressType} from "../../../helper/progressHelper";

export interface IPerformanceCardKR {
    card: ActivePerformanceCardDto | undefined
    index: number
    kr: UserKrDetailsDto | undefined
    update: () => void
    progressUpdate: () => void
    type: PerformanceCardGroupType
    isPeriodFinished: boolean
    progressTypes: OKRProgressType[]
    isTeamOKR: boolean
}

const PerformanceCardKR: React.FC<IPerformanceCardKR> = ({
                                                             progressTypes,
                                                             index,
                                                             card,
                                                             kr,
                                                             update,
                                                             progressUpdate,
                                                             type,
                                                             isPeriodFinished,
                                                             isTeamOKR
                                                         }) => {

    const [progressInputActive, setProgressnputActive] = useState(false)
    const [progress, setProgress] = useState<UserKrUpdateDto[]>([])
    const [updateProgress, setUpdateProgress] = useState<UserKrUpdateDto | null>(null)
    const [lastProgress, setLastProgress] = useState<number>(0)

    useEffect(() => {
        if (kr) {
            var updates = kr.updates ?? []
            var progresses = [...updates]
                .sort(function (left, right) {
                    return moment.utc(left.createdDate).diff(moment.utc(right.createdDate))
                })
            setProgress(updates)
            setLastProgress(progresses[progresses.length - 1]?.progressUpdate ?? 0)
        }
    }, [kr])


    useEffect(() => {
        if (updateProgress)
            setProgressnputActive(true)
    }, [updateProgress])

    const getExpandIcon = (props: any) => {
        // collapsible: ((progress.length > 0) ? "icon" : "disabled"),
        if (props.collapsible == "icon") {
            if (props.isActive)
                return <div className={"mt-[12px]"}><UpOutlined/></div>
            else
                return <div className={"mt-[12px]"}><DownOutlined/></div>
        } else {
            return <div className={"w-[13px]"}/>
        }
    }

    return (
        <>
            <Collapse
                ghost
                className={"w-full"}
                expandIcon={getExpandIcon}
                items={[
                    {
                        styles: {
                            header: {
                                padding: 0,
                                paddingTop: 5
                            },
                            body: {
                                paddingRight: 0,
                                paddingLeft: 50,
                            }
                        },
                        collapsible: ((progress.length > 0) ? "icon" : "disabled"),
                        label: <div
                            className="flex flex-row justify-between items-start bg-[#B3C8CF]  rounded-md overflow-hidden">
                            <div className="flex flex-row my-1">
                                <div className="bg-[#89A8B2] min-w-[30px] max-w-[30px] -my-[5px]">
                                    <h2 className="text-center text-white mt-[5px]">{index + 1}</h2>
                                </div>
                                <h2 className="ml-2 max-w-[400px]">{kr?.description}</h2>
                            </div>
                            <div className="flex flex-row items-center">
                                {type == PerformanceCardGroupType.Active &&
                                    <Progress percent={Number((lastProgress).toFixed(2))}
                                              percentPosition={{align: 'start', type: 'outer'}}
                                              className="w-[200px]"/>
                                }
                                <div className="flex flexp-row ml-2">
                                    {type == PerformanceCardGroupType.Active &&
                                        hasProgressType(progressTypes, OKRProgressType.personel) &&
                                        !isPeriodFinished &&
                                        ((isTeamOKR && kr?.creatorId == kr?.userId)  ||
                                            !isTeamOKR) &&
                                        <Tooltip title="İlerleme Ekle">
                                            <Button type="primary" onClick={() => {
                                                if (lastProgress < 100) {
                                                    setProgressnputActive(true)
                                                }
                                            }} className="text-black bg-transparent border-0 rounded-none shadow-none">
                                                <div className="flex flex-col justify-center items-center">
                                                    <CheckCircleOutlined
                                                        style={{width: (kr?.updates ?? []).length > 0 ? 10 : 20}}/>
                                                    {(kr?.updates ?? []).length > 0 &&
                                                        <text
                                                            className="text-[8px]">{(progress ?? []).length} Kez</text>
                                                    }
                                                </div>
                                            </Button>
                                        </Tooltip>
                                    }
                                    <Tooltip title={moment(kr?.createdDate).format('DD.MM.YYYY')}>
                                        <Button type="primary" onClick={() => {
                                        }} className="text-black bg-transparent border-0 rounded-none shadow-none">
                                            <HistoryOutlined/>
                                        </Button>
                                    </Tooltip>
                                    {kr?.isPrivate &&
                                        <Tooltip title="Gizli">
                                            <Button type="primary" onClick={() => {
                                            }} className="text-black bg-transparent border-0 rounded-none" shadow-none>
                                                <EyeInvisibleOutlined/>
                                            </Button>
                                        </Tooltip>
                                    }
                                    {type == PerformanceCardGroupType.Active &&
                                        hasProgressType(progressTypes, OKRProgressType.personel) &&
                                        !isPeriodFinished &&
                                        ((isTeamOKR && kr?.creatorId == kr?.userId)  ||
                                            !isTeamOKR) &&
                                        <Tooltip title="Düzenle">
                                            <Button type="primary" onClick={() => {
                                                update()
                                            }} className="text-black bg-transparent border-0 rounded-none shadow-none">
                                                <EditOutlined/>
                                            </Button>
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                        </div>,
                        children: <>
                            <Space direction="vertical" size={"small"} className="w-full">
                                {[...progress]
                                    .sort(function (left, right) {
                                        return moment.utc(left.createdDate).diff(moment.utc(right.createdDate))
                                    })
                                    .map((k: UserKrUpdateDto, i) => (
                                        <Card title={null}
                                              bordered={false}
                                              onClick={() => {
                                                  setUpdateProgress(k)
                                              }}
                                              style={{boxShadow: "none"}}
                                              className="w-full mt-2 bg-orange-300 rounded-md overflow-hidden"
                                              styles={{
                                                  body: {
                                                      padding: 5
                                                  },
                                                  header: {
                                                      height: 30,
                                                      minHeight: 40,
                                                      border: 0,
                                                      padding: 0,
                                                  }
                                              }}>
                                            <div
                                                className={"flex justify-between flex-row mx-1 items-baseline cursor-pointer"}>
                                                <p className="px-2">{k.description}</p>
                                                <div className={"flex flex-row items-center"}>
                                                    <div className={"flex flex-row"}>
                                                        <RiseOutlined/>
                                                        <p className={"text-[10px] font-bold ml-1"}>{k.progressUpdate}%</p>
                                                    </div>
                                                    <p className={"text-[10px] ml-5"}>{moment(k.createdDate).format("DD.MM.YYYY")}</p>
                                                </div>
                                            </div>
                                        </Card>
                                    ))}
                            </Space>
                        </>

                    }
                ]}
            />

            <ProgressInputModal
                initialData={updateProgress}
                isActive={progressInputActive}
                setActive={(status) => {
                    setProgressnputActive(status)

                    if (!status) {
                        setUpdateProgress(null)
                    }
                }}
                initialProgress={kr?.progress ?? 0}
                kr={kr}
                newProgressAdded={prg => {
                    setProgress([...progress, prg])
                    setProgressnputActive(false)
                    setLastProgress(prg.progressUpdate ?? 0)
                    progressUpdate()
                }}/>
        </>
    );
}

export default PerformanceCardKR;
