import {Alert, Button, Card, Progress, Slider, Space} from "antd";
import {
    ActivePerformanceCardDto,
    useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery,
    useLazyGetApiPerformanceCardsGetPerformanceCardDetailsByCardIdQuery,
    UserOkrDetailsDto
} from "../../../api/services/performanceCards";
import PerformanceCardGroup from "./PerformanceCardGroup";
import {PerformanceCardGroupType} from "../PerformanceCardGroupType";
import OKRInputModal from "./Modal/OKRInputModal";
import React, {useEffect, useState} from "react";
import KRInputModal from "./Modal/KRInputModal";
import ProgressInputModal from "./Modal/ProgressInputModal";
import {t} from "i18next";
import {useSelector, useDispatch} from "react-redux";
import {setLoading, setError} from "../../../features/app/appSlice";
import {RootState} from "../../../app/store";
import {WeightenedOKRId} from "../../../data/constants";
import dayjs from "dayjs";

export interface IPerformanceCardModal {
    card: ActivePerformanceCardDto | null
}

const PerformanceCardModal: React.FC<IPerformanceCardModal> = ({card}) => {
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch();
    const [fetchCardDetail, {data: CardDetails}] = useLazyGetApiPerformanceCardsGetPerformanceCardDetailsByCardIdQuery()
    const [totalPercent, setTotalPercent] = useState(0)
    const [periodFinished, setPeriodFinished] = useState(false)

    useEffect(() => {
        if (card) {
            getCardDetails()

            if (dayjs(card.endDate).isBefore()) {
                setPeriodFinished(true);
            }
        }
    }, [card])

    const getCardDetails = async () => {
        try {
            dispatch(setLoading(true));

            await fetchCardDetail({
                cardId: card?.cardId ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Kartlar sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        if (CardDetails) {
            calculateTotalPercent()
        }
    }, [CardDetails])

    const calculateTotalPercent = () => {
        var okrWeightId = CardDetails?.performanceSystem?.okrDetails?.[0].okrWeightId
        var isWeightened = okrWeightId == WeightenedOKRId
        var percent = 0
        if (isWeightened) {
            var total0 = CardDetails?.okRs?.filter(k => k.status == 0)
                .reduce((n, newVal, index) => (n + ((newVal.progress ?? 0) * ((newVal.weight ?? 0) / 100))), 0) ?? 0

            var total1 = CardDetails?.okRs?.filter(k => k.status == 1)
                .reduce((n, newVal, index) => (n + ((newVal.progress ?? 0) * ((newVal.weight ?? 0) / 100))), 0) ?? 0
            percent = total0 + total1
        } else {
            var total0 = CardDetails?.okRs?.filter(k => k.status == 0)
                .reduce((n, newVal, index) => (n + (newVal.progress ?? 0)), 0) ?? 0

            var total1 = CardDetails?.okRs?.filter(k => k.status == 1)
                .reduce((n, newVal, index) => (n + (newVal.progress ?? 0)), 0) ?? 0

            var count = CardDetails?.okRs?.filter(k => k.status == 0 || k.status == 1).length ?? 0

            percent = (total0 + total1) / count
        }


        setTotalPercent(Number(percent.toFixed(2)))
    }

    return (
        <div>
            <Space direction="vertical" size="middle" className="w-full mt-3">
                <div className="flex flex-row mb-2 justify-between">
                    <div>
                        {
                            periodFinished &&
                            <>
                                <Alert message={`Bu kartın süresi ${dayjs(card?.endDate).format("DD.MM.YYYY")} tarihinde tamamlandı.`} 
                                       type="warning"/>
                            </>
                        }
                    </div>
                    <div className="flex flex-row items-center">
                        {
                            (CardDetails?.okRs ?? []).length > 0 &&
                            <>
                                <h4 className="mr-2 font-semibold">OKR İlerlemesi</h4>
                                <Progress type="circle" percent={totalPercent} className={"text-[8px]"} size={50}/>
                            </>
                        }

                    </div>
                </div>

                {
                    <PerformanceCardGroup type={PerformanceCardGroupType.Active}
                                          isPeriodFinished={periodFinished}
                                          card={card}
                                          CardDetails={CardDetails}
                                          needsRecalculation={(okrs: UserOkrDetailsDto[]) => {
                                              calculateTotalPercent()
                                          }}
                                          progressUpdate={() => {
                                              getCardDetails()
                                          }}
                    />
                }

                {
                    (CardDetails?.okRs?.filter(k => k.status == 1)?.length ?? 0) > 0 &&
                    <PerformanceCardGroup type={PerformanceCardGroupType.Completed} card={card}
                                          isPeriodFinished={periodFinished}
                                          CardDetails={CardDetails}/>

                }

                {
                    (CardDetails?.okRs?.filter(k => k.status == 2)?.length ?? 0) > 0 &&
                    <PerformanceCardGroup type={PerformanceCardGroupType.Cancelled} card={card}
                                          isPeriodFinished={periodFinished}
                                          CardDetails={CardDetails}/>
                }
            </Space>
        </div>
    );
}

export default PerformanceCardModal;
