import React, {useEffect, useState} from 'react';
import {Button, Card, DatePicker, Modal, Space, Spin, Table} from "antd";
import {useLazyGetApiMCompanyGroupsCompanygroupsQuery} from "../../../api/services/MCompanyGroup";
import {useLazyGetApiLogsQuery} from "../../../api/services/Logs";
import {t} from "i18next";
import moment from "moment";
import {DatePickerProps} from "antd/lib";
import dayjs from "dayjs";

export interface ILogViewer {

}

const LogViewer: React.FC<ILogViewer> = ({}) => {
    const [fetchLogs, {
        data: logs,
        isLoading: loading,
        error
    }] = useLazyGetApiLogsQuery()
    const [selectedLog, setSelectedLog] = useState<any>(undefined)

    useEffect(() => {
        var date = moment().subtract(2).toDate()
        getLogs(dayjs().format('YYYY-MM-DD'))
    }, []);

    const getLogs = async (dt: string) => {
        await fetchLogs({
            date: dt
        }).unwrap()
    }

    const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (date != null)
            getLogs(dayjs(date).format('YYYY-MM-DD'))
    };

    const handleCancel = () => {
        setSelectedLog(undefined);
    };

    const columns = [
        {
            title: "Date", dataIndex: 'timestamp', key: 'timestamp',
            render: (_: any, record: any) => `${moment(record.timestamp).format("DD.MM.YYYY HH:mm:SS")}`
        },
        {title: "Level", dataIndex: 'level', key: 'timestamp'},
        {
            title: "Mesage", dataIndex: 'message', key: 'message',
            render: (_: any, record: any) => `${record.message.slice(0, 50)}...`
        },
    ]
    return (<>
        <div>
            <div className={"mb-3"}>
                <span className={"font-semibold mr-2"}>Date: </span>
                <DatePicker onChange={onDateChange}
                            defaultValue={dayjs()}
                            allowClear={false}


                />
            </div>
            <Table
                dataSource={logs as any[] ?? []}
                columns={columns}
                rowKey="id"
                rowClassName={"min-h-[60px] h-[60px] max-h-[60px]"}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            setSelectedLog(record)
                        }
                    };
                }}
            />

            <Modal
                title={"Log Detail"}
                width={1200}
                onCancel={handleCancel}
                onClose={handleCancel}
                closable={true}
                maskClosable={false}
                destroyOnClose={true}
                open={selectedLog != undefined}
                footer={null}
            >
                {selectedLog &&
                    <>
                        <Card className={"flex flex-row"}>
                            <span className={"font-semibold mr-2"}>Date:</span>
                            {moment(selectedLog.timestamp).format("DD.MM.YYYY HH:mm:SS")}
                        </Card>

                        <Card className={"flex flex-row mt-2"}>
                            <span className={"font-semibold mr-2"}>Level:</span>
                            {selectedLog.level}
                        </Card>

                        <Card className={"flex flex-col mt-2"}>
                            <h2 className={"font-semibold"}>Message:</h2>
                            {selectedLog.message}
                        </Card>

                        <Card className={"flex flex-col mt-2"}>
                            <h2 className={"font-semibold"}>Exception:</h2>
                            <p className={"mt-2 h-[300px] overflow-scroll"}>{selectedLog.exception}</p>
                        </Card>
                    </>
                }
            </Modal>
        </div>

    </>)
};

export default LogViewer;