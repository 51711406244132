import React, {useEffect, useState} from 'react';
import {Table, Button, Form} from 'antd';
import ScaleModal from '../../components/ScaleSelection/ScaleModal';
import {ScaleType, ScaleTypeNames} from '../../models/ScaleSelection/ScaleType';
import {Scale} from '../../models/ScaleSelection/Scale';
import {
    ScaleDto,
    useLazyGetApiScaleQuery,
    usePostApiScaleMutation,
    usePutApiScaleByIdMutation
} from '../../api/services/scale';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../app/store';
import {setError, setLoading} from '../../features/app/appSlice';
import {t} from 'i18next';
import moment from 'moment';

const {Column} = Table;

const ScaleSelection: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedScale, setSelectedScale] = useState<ScaleDto | null>(null);
    const [selectedScaleData, setSelectedScaleData] = useState<ScaleDto | null>(null);
    const [fetchScales, {data: scaleList}] = useLazyGetApiScaleQuery()
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [saveScale] = usePostApiScaleMutation()
    const [updateScale] = usePutApiScaleByIdMutation()


    useEffect(() => {
        if (user)
            getScaleData()
    }, [user])


    const getScaleData = () => {
        fetchScales({
            companyId: user?.companyId ?? "",
        })
    }


    const saveScaleData = async (values: any) => {
        if (selectedScaleData == null) {
            try {
                dispatch(setLoading(true));
                await saveScale({
                    scaleDto: {
                        scaleName: values.scaleName,
                        scaleType: values.scaleType,
                        scaleNumber: values.scaleNumber,
                        scaleDetails: [...(values.evaluationType_1.scaleDetails), ...(values.evaluationType_2.scaleDetails)],
                        evaluationMetrics: values.evaluationMetric,
                        companyId: user?.companyId ?? ""
                    }
                }).unwrap()

                getScaleData();
                handleCancel();
            } catch (err: any) {
                if (err.status != "PARSING_ERROR")
                    dispatch(setError(t('Skala kaydedilirken hata oluştu')));
            } finally {
                dispatch(setLoading(false));
            }

        } else {
            try {
                dispatch(setLoading(true));
                await updateScale({
                    id: selectedScaleData.id ?? "",
                    scaleDto: {
                        scaleName: values.scaleName,
                        scaleType: values.scaleType,
                        scaleNumber: values.scaleNumber,
                        scaleDetails: [...(values.evaluationType_1.scaleDetails), ...(values.evaluationType_2.scaleDetails)],
                        companyId: user?.companyId ?? "",
                        evaluationMetrics: values.evaluationMetric,
                    }
                }).unwrap()

                getScaleData();
                handleCancel();
            } catch (err: any) {
                if (err.status != "PARSING_ERROR")
                    dispatch(setError(t('Skala güncellenirken hata oluştu')));
            } finally {
                dispatch(setLoading(false));
            }
        }
    }

    const showModal = (record: ScaleDto | null) => {
        if (record) {
            setSelectedScale(record);
            var item = scaleList?.find(k => k.id == record.id);
            if (item)
                setSelectedScaleData(item);
        } else {
            setSelectedScale(null);
            setSelectedScaleData(null);
        }
        setIsModalVisible(true);

        setTimeout(() => {
            form.resetFields();
        }, 50);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedScale(null);
        setSelectedScaleData(null);
    };

    const handleSave = async (values: any) => {
        await saveScaleData(values);
    };

    return (
        <div>
            <Button type="primary" onClick={() => showModal(null)}>
                Skala Ekle
            </Button>
            <Table
                dataSource={scaleList}
                rowKey="id"
                style={{marginTop: 16}}
                onRow={(record) => ({
                    onClick: () => showModal(record),
                })}
            >
                <Column title="Skala No" dataIndex="scaleNumber" key="id"/>
                <Column title="Skala Adı" dataIndex="scaleName" key="name"/>
                <Column title="Skala Tipi"
                        dataIndex="scaleType"
                        key="type"
                        render={(text) => ScaleTypeNames[text]}/>
            </Table>
            <ScaleModal
                form={form}
                visible={isModalVisible}
                onCancel={handleCancel}
                onSave={handleSave}
                initialValues={selectedScaleData}
            />
        </div>
    );
};

export default ScaleSelection;
