import React, {useEffect, useState} from 'react';
import {Alert, Button, Popconfirm, Select, Table} from "antd";
import {PeriodDto, useLazyGetApiPeriodAllQuery} from "../../api/services/period";
import {setError, setLoading} from "../../features/app/appSlice";
import dayjs from "dayjs";
import {t} from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {useDeleteApiMEvaluationMutation, useLazyGetApiMEvaluationQuery} from "../../api/services/MEvaluation";
import moment from "moment/moment";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import { Switch } from 'antd';

export interface IManagerEvaluationSettings {

}

const ManagerEvaluationSettings: React.FC<IManagerEvaluationSettings> = ({}) => {
    const [selectedPeriod, setSelectedPeriod] = useState<PeriodDto | undefined>();
    const [fetchPeriods, {data: Periods}] = useLazyGetApiPeriodAllQuery()
    const [fetchEvaluationStatuses, {data: EvaluationStatuses}] = useLazyGetApiMEvaluationQuery()
    const [resetEvaluation] = useDeleteApiMEvaluationMutation()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.auth?.user);

    useEffect(() => {
        if (user) {
            getActivePeriods()
        }
    }, [user])

    const getActivePeriods = async () => {
        try {
            dispatch(setLoading(true));
            var periods = await fetchPeriods().unwrap()

            //Varsa Biten periyodların ilkini seç
            var finished = periods.filter(k => dayjs(k.endDate).isBefore())
            if (finished.length > 0) {
                var first = finished[0]
                setSelectedPeriod(first);
                getEvaluationStatuses(first)
            } else {
                if (periods.length > 0) {
                    var first = periods[0]
                    setSelectedPeriod(first);
                    getEvaluationStatuses(first)
                }
            }
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getEvaluationStatuses = async (period: PeriodDto) => {
        try {
            dispatch(setLoading(true));
            var periods = await fetchEvaluationStatuses({
                periodId: period?.id ?? "",
                companyId: user?.companyId ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Değerlendirme durumları sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const resetEvaluationStatus = async (managerId: string) => {
        try {
            dispatch(setLoading(true));
            await resetEvaluation({
                managerId: managerId ?? "",
                periodId: selectedPeriod?.id ?? ""
            }).unwrap()
            
            await getEvaluationStatuses(selectedPeriod!)
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Değerlendirme sıfırlanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }
    
    const resetEvaluationS = async (value: any) => {
        await resetEvaluationStatus(value.managerId ?? "")
    };
    
    const columns = [

        {
            title: t('Yönetici Ad Soyad'), dataIndex: 'name', key: 'name',
            render: (_: any, record: any) => `${record.firstName} ${record.lastName}`,
        },
        {
            title: "",
            width: 300,
            render: (_: any, record: any) => {
                return (
                    <div className={"flex flex-row"}>
                        {record.status == 2 && //onaylanmış
                            <Button type={"primary"} onClick={() => resetEvaluationS(record)}>Değerlendirmeyi Sıfırla</Button>  
                        }
                        {record.status != 2 && 
                            <span>{record.message}</span>
                        }
                    </div>
                )
            }
        }
    ];


    return (<>
            <div className={"flex flex-col h-full"}>
                <div className={"flex flex-col justify-between min-h-[50px]"}>
                    <div>
                        <div className={"flex flex-row items-baseline"}>
                            <text className={"font-semibold mr-2"}>Seçili Dönem:</text>
                            <Select
                                placeholder="Dönem Seçin"
                                onChange={(value) => {
                                    var v = (Periods ?? []).find(k => k.id == value)
                                    setSelectedPeriod(v)
                                    getEvaluationStatuses(v!)
                                }}
                                style={{width: 300}}
                                value={selectedPeriod?.id} // Seçili eleman
                                showSearch={true}
                                filterOption={(input, option) =>
                                    `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                                }
                                options={((Periods ?? []) as PeriodDto[]).map(k => {
                                        return {value: k.id, label: `${k.periodName}`};
                                    }
                                )}
                            />
                        </div>
                    </div>
                    <div className={"mt-3"}>
                        <Table
                            dataSource={(EvaluationStatuses as any[]) ?? []}
                            columns={columns}
                            rowKey="id"
                        />
                    </div>
                </div>
            </div>

        </>
    )
};

export default ManagerEvaluationSettings;