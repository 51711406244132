import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Drawer, Form, Spin, Modal, notification, Card, Tabs} from 'antd';
import {t} from 'i18next';
import CompanyTable from './CompanyTable';
import moment from 'moment';
import {setError, setLoading} from "../../../features/app/appSlice";
import {useParams} from "react-router-dom";
import {
    useLazyGetApiMCompanyGroupsCompanygroupsByIdQuery,
    useLazyGetApiMCompanyGroupsCompanygroupsQuery
} from "../../../api/services/MCompanyGroup";
import {TabsProps} from "antd/lib";
import MCompanyGroupLicence from "./MCompanyGroupLicence";
import MCompanyGroupCompanies from "./MCompanyGroupCompanies";

interface RouteParams {
    groupId: string;
}


const Companies: React.FC = () => {
    const dispatch = useDispatch();
    const {groupId} = useParams<RouteParams>();
    const [fetchCompanyGroup, {
        data: companyGroup,
        isLoading: companyGroupLoading,
        error: companyGroupError
    }] = useLazyGetApiMCompanyGroupsCompanygroupsByIdQuery()

    useEffect(() => {
        if (groupId)
            loadCompanieyGroup()
    }, [groupId]);
    const loadCompanieyGroup = async () => {
        try {
            dispatch(setLoading(true));
            await fetchCompanyGroup({
                id: groupId
            }).unwrap()
        } catch (err) {
            dispatch(setError(t('sirketleri-cekerken-problem-olustu')));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Şirketler',
            children: <MCompanyGroupCompanies groupId={groupId} />,
        },
        {
            key: '2',
            label: 'Lisans Yönetimi',
            children: <MCompanyGroupLicence/>
        }
    ];

    return (
        <div>
            <Card>
                <div className={"flex flex-row"}>
                    <img alt={`${(companyGroup as any)?.groupName}`}
                         src={`${process.env.REACT_APP_API_BASE_URL}/api/image/images/companygroups/${(companyGroup as any)?.logo}`}
                         style={{maxWidth: 'auto', maxHeight: "50px", aspectRatio: "auto"}}/>
                    <div className={"ml-5 font-medium"}>
                        <p>{(companyGroup as any)?.groupName}</p>
                        <p>{moment((companyGroup as any)?.startDate).format("DD.MM.YYYY")}</p>
                    </div>
                </div>

            </Card>

            <Tabs defaultActiveKey="1" items={items} className={"mt-3"}/>

        </div>
    );
};

export default Companies;
