import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../app/store';
import {setLoading, setError} from '../../features/app/appSlice';
import {Table, Button, Form, notification, Drawer, Spin, Input, Modal} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {fetchUsers} from '../../features/users/userSlice';
import {
    useDeleteApiUserByIdMutation,
    useLazyGetApiUserByCompanyByCompanyIdQuery,
    useLazyGetApiUserByCompanyGroupByCompanyGroupIdQuery,
    usePostApiUserMutation,
    usePutApiUserByIdMutation,
    UserDto
} from '../../api/services/user';
import {t} from 'i18next';
import {ExclamationCircleOutlined, FileExcelOutlined} from '@ant-design/icons';
import UserForm from './UserForm';
import UserTable from './UserTable';
import moment from 'moment';
import UserUploadModal from './UserUploadModal';
import * as XLSX from 'xlsx';
import {useLazyGetApiCompanySyncUsersFromSystemQuery} from "../../api/services/company";

const Users: React.FC = () => {
    const dispatch = useDispatch();
    const [getUsers, {data: users, isLoading: loading, error}] = useLazyGetApiUserByCompanyByCompanyIdQuery()
    const [createUser, {
        data: createdUser,
        isSuccess: userCreationSuccess,
        isLoading: userCreating,
        error: creationError
    }] = usePostApiUserMutation()
    const [deleteUser, {
        isSuccess: userDeletionSuccess,
        isLoading: userDeleting,
        error: deletionError
    }] = useDeleteApiUserByIdMutation()
    const [updateUser, {
        isSuccess: userUpdateSuccess,
        isLoading: userUpdating,
        error: updateError
    }] = usePutApiUserByIdMutation()
    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState<UserDto[]>([])

    const user = useSelector((state: RootState) => state.auth?.user);

    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UserDto | null>(null);
    const [form] = Form.useForm();
    const {confirm} = Modal;
    const [syncUsersFromService] = useLazyGetApiCompanySyncUsersFromSystemQuery()

    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
        form.resetFields();
    };

    const loadUsers = async () => {
        try {
            dispatch(setLoading(true));
            await getUsers({
                companyId: user?.company?.id ?? ""
            }).unwrap()
        } catch (err) {
            dispatch(setError(t('failed-to-fetch-users')));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleDelete = async (id: any) => {
        try {
            dispatch(setLoading(true));
            await deleteUser({
                id: id
            }).unwrap()
        } catch (err) {
            dispatch(setError(t('failed-to-delete-user')));
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (user)
            loadUsers();
    }, [user]);

    const onFinish = (values: any) => {

        const createUserFunc = async () => {
            dispatch(setLoading(true));
            try {
                values.userName = values.email;
                await createUser({
                    createUserDto: values
                })
            } catch (err) {
                dispatch(setError(t('failed-to-add-user')));
            } finally {
                dispatch(setLoading(false));
                form.resetFields()
            }
        };

        const updateUserFunc = async (values: any) => {

            try {
                dispatch(setLoading(true));
                values.userName = values.email;
                await updateUser({
                    id: selectedUser?.id ?? "",
                    updateUserDto: values
                }).unwrap()
            } catch (err) {
                dispatch(setError(t('failed-to-add-user')));
            } finally {
                dispatch(setLoading(false));
            }
        };

        if (!isEditMode)
            createUserFunc()
        else
            updateUserFunc(values)
    };

    useEffect(() => {
        if (userCreationSuccess) {
            notification.success({message: t('user-added-successfully')});
            closeDrawer();
            loadUsers();
        }
    }, [userCreationSuccess])

    useEffect(() => {
        if (userDeletionSuccess) {
            loadUsers();
        }
    }, [userDeletionSuccess])

    useEffect(() => {
        if (userUpdateSuccess) {
            closeDrawer();
            loadUsers();
        }
    }, [userUpdateSuccess])

    const handleEdit = (user: UserDto) => {
        setSelectedUser(user);
        setIsEditMode(true);
        form.setFieldsValue({
            ...user,
            startDate: moment(user.startDate),
        });
        showDrawer();
    };

    const handleAdd = () => {
        setIsEditMode(false);
        showDrawer();
    }

    if (loading) return null;
    if (error) return <div>{t('error-loading-users')}</div>;

    const handleUpload = (file: File) => {
        console.log('Yüklenen dosya:', file);
        // Burada dosya işleme mantığını ekleyebilirsiniz.
    };


    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredUsers ?? []);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Kullanıcılar');
        XLSX.writeFile(workbook, 'Kullanıcılar.xlsx');
    };

    const fetchFromService = async () => {
        try {
            dispatch(setLoading(true));
            await syncUsersFromService().unwrap()

            await loadUsers();
        } catch (err) {
            dispatch(setError(t('failed-to-fetch-users')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    return (
        <div>
            <div className={"flex flex-row justify-between"}>
                <div>
                    <Button type="primary" style={{marginBottom: 16}} onClick={handleAdd}>
                        {t('add-user')}
                    </Button>
                    <Button type="primary" style={{marginBottom: 16, marginLeft: 16}}
                            onClick={() => setUploadModalVisible(true)}>
                        {"Excel'den yükle"}
                    </Button>
                    <Button type="primary" style={{marginBottom: 16, marginLeft: 16}}
                            onClick={() => fetchFromService()}>
                        Servisten Yükle
                    </Button>
                </div>
                <Button type="primary" style={{marginBottom: 16, marginLeft: 16}} onClick={() => exportToExcel()}>
                    <FileExcelOutlined/>
                </Button>
            </div>
            <Drawer
                title={isEditMode ? "Edit User" : "Add New User"}
                width={720}
                onClose={closeDrawer}
                visible={isDrawerVisible}
                destroyOnClose
                bodyStyle={{paddingBottom: 80}}
            >
                <Spin spinning={loading}>
                    <UserForm selectedUser={selectedUser} form={form} isEditMode={isEditMode} onFinish={onFinish}
                              allUsers={users ?? []}/>
                    <Form.Item>
                        <Button type="primary" onClick={form.submit}>
                            Save
                        </Button>
                    </Form.Item>
                </Spin>
            </Drawer>
            <UserUploadModal
                visible={uploadModalVisible}
                onClose={() => setUploadModalVisible(false)}
                onUpload={handleUpload}
                onUpdate={loadUsers}
            />
            <UserTable
                users={users ?? []}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                onFilter={(users) => {
                    setFilteredUsers(users)
                }}
            />
        </div>
    );
};

export default Users;
