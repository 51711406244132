import {Button, Card, Progress, Space, Tooltip} from "antd";
import {PerformanceCardDetailsDto, UserKrDetailsDto, UserOkrDetailsDto} from "../../../api/services/performanceCards";
import {EditOutlined, EyeInvisibleOutlined, HistoryOutlined} from '@ant-design/icons';
import {useEffect, useState} from "react";
import {WeightenedOKRId} from "../../../data/constants";
import moment from "moment";
import {PerformanceCardGroupType} from "../../MyPerformanceCardsScreen/PerformanceCardGroupType";
import TeamPerformanceCardKR from "./TeamPerformanceCardKR";
import {hasProgressType, OKRProgressType} from "../../../helper/progressHelper";
import * as React from "react";
import KRInputModal from "../../MyPerformanceCardsScreen/Components/Modal/KRInputModal";

export interface ITeamPerformanceCardOKR {
    CardDetails: PerformanceCardDetailsDto | undefined
    index: number
    okr: UserOkrDetailsDto | undefined
    update: () => void
    weight?: number | null | undefined
    progressUpdate: () => void
    type: PerformanceCardGroupType
    isPeriodFinished: boolean
    progressTypes: OKRProgressType[]
    readonly?: boolean
}

const TeamPerformanceCardOKR: React.FC<ITeamPerformanceCardOKR> = ({
                                                                       readonly,
                                                                       progressTypes,
                                                                       isPeriodFinished,
                                                                       CardDetails,
                                                                       index,
                                                                       okr,
                                                                       update,
                                                                       weight,
                                                                       progressUpdate,
                                                                       type
                                                                   }) => {
    const [krInputActive, setKrInputActive] = useState(false)
    const [isWeightened, setIsWeightened] = useState(false)
    const [updateCard, setUpdateCard] = useState<UserKrDetailsDto | null>(null)
    const [krs, setKrs] = useState<UserKrDetailsDto[]>([])

    useEffect(() => {
        if (updateCard)
            setKrInputActive(true)
    }, [updateCard])

    useEffect(() => {
        if (okr) {
            setKrs(okr.kRs ?? [])

            var okrWeightId = CardDetails?.performanceSystem?.okrDetails?.[0].okrWeightId
            setIsWeightened(okrWeightId == WeightenedOKRId)
        }
    }, [okr])

    return (
        <Card title={
            <div className="flex flex-row justify-between bg-[#B3C8CF] rounded-md overflow-hidden h-full">
                <div className="flex flex-row items-start">
                    <div className="bg-[#89A8B2] w-[30px] h-full">
                        <h2 className="text-center text-white mt-1">{index + 1}</h2>
                    </div>
                    <div className={"max-w-[400px] h-fit m-1 mx-2"}>
                        <p className="text-white text-wrap min-h-[30px] h-full">{okr?.description} </p>
                    </div>

                    <div className="flex flex-row items-start h-full">

                        {isWeightened ?
                            <Progress type="circle"
                                      className={"mt-1"}
                                      strokeColor={"#00FF00"}
                                      trailColor="#00000030"
                                      format={(percent) => `${percent}%`}
                                      status={"normal"}
                                      percent={okr?.weight ?? 0}
                                      size={30}/>
                            : <></>}
                    </div>
                </div>
                <div className="flex flex-row items-start max-h-[40px]">
                    <div className="flex flex-row items-center h-full">
                        {
                            krs.length > 0 && type == PerformanceCardGroupType.Active &&
                            <Progress percent={Number((okr?.progress ?? 0).toFixed(2))} strokeColor={"#00FF00"}
                                      trailColor="#00000030" percentPosition={{align: 'start', type: 'outer'}}
                                      className="w-[200px]"/>
                        }


                        <div className="flex flex-row ml-2 h-full items-center">
                            {okr?.isPrivate == true &&
                                <Tooltip title="Gizli">
                                    <Button type="primary" onClick={() => {
                                    }} className="text-black bg-transparent border-0 rounded-none shadow-none">
                                        <EyeInvisibleOutlined/>
                                    </Button>
                                </Tooltip>
                            }
                            <Tooltip title={moment(okr?.createdDate).format('DD.MM.YYYY')}>
                                <Button type="primary" onClick={() => {
                                }} className="text-black bg-transparent border-0 rounded-none shadow-none">
                                    <HistoryOutlined/>
                                </Button>
                            </Tooltip>
                            {type == PerformanceCardGroupType.Active &&
                                hasProgressType(progressTypes, OKRProgressType.manager) &&
                                !readonly &&
                                !isPeriodFinished &&
                                <Tooltip title="Düzenle">
                                    <Button type="primary" onClick={() => {
                                        update()
                                    }} className="text-black bg-transparent border-0 rounded-none shadow-none">
                                        <EditOutlined/>
                                    </Button>
                                </Tooltip>
                            }
                            {type == PerformanceCardGroupType.Active &&
                                hasProgressType(progressTypes, OKRProgressType.manager) &&
                                !readonly &&
                                !isPeriodFinished &&
                                <Button type="primary"
                                        onClick={() => {
                                            setKrInputActive(true)
                                        }}
                                        className="text-white bg-gray-500 border-0 rounded-none h-full shadow-none">
                                    KR Ekle
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
              bordered={false}
              className="w-full mt-2 pr-0"
              style={{borderRadius: 0, boxShadow: "none", background: "transparent"}}
              styles={{
                  body: {
                      paddingLeft: 10,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 10
                  },
                  header: {
                      minHeight: 40,
                      border: 0,
                      padding: 0,
                  }
              }}>

            <Space direction="vertical" size={"small"} className="w-full">
                {[...krs]
                    .sort(function (left, right) {
                        return moment.utc(left.createdDate).diff(moment.utc(right.createdDate))
                    })
                    .map((k: UserKrDetailsDto, i) => (
                        <TeamPerformanceCardKR index={i}
                                               readonly={readonly}
                                               card={CardDetails}
                                               kr={k}
                                               progressTypes={progressTypes}
                                               isPeriodFinished={isPeriodFinished}
                                               type={type}
                                               update={() => {
                                                   setUpdateCard(k)
                                               }}
                                               progressUpdate={() => {
                                                   progressUpdate()
                                               }}/>
                    ))}
            </Space>

            <KRInputModal
                CardDetails={CardDetails}
                initialValue={updateCard}
                isActive={krInputActive}
                setActive={(status) => {
                    setKrInputActive(status)
                    if (!status) {
                        setUpdateCard(null)
                    }
                }}
                OKR={okr}
                newOKRAdded={kr => {
                    setKrs([...krs, kr])
                    setKrInputActive(false)
                    progressUpdate()
                    setUpdateCard(null)
                }}
                okrUpdated={kr => {
                    var oldKR = krs.find(k => k.krId == kr.id)
                    var filteredKRs = krs.filter(k => k.krId != kr.id)
                    kr.createDate = oldKR?.createdDate
                    setKrs([...filteredKRs, kr])
                    setKrInputActive(false)
                    setUpdateCard(null)
                }}
                updateScreen={() => {
                    setUpdateCard(null)
                    setKrInputActive(false)
                    progressUpdate()
                }}
            />
        </Card>
    );
}

export default TeamPerformanceCardOKR;
