import {usePostApiAuthLoginMutation} from "../../api/services/auth";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {useLazyGetApiTenantGetTenantInfoQuery} from "../../api/services/Tenant";
import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Alert, Button, Form, Input, message, Spin} from "antd";
import {
    usePostApiUserCompletePasswordResetMutation,
    usePostApiUserInitiateResetPasswordMutation
} from "../../api/services/user";
import {setAccessToken, setRefreshToken, setTenantInfo, toggleLoggedIn} from "../../app/persistedSlice";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import {LockOutlined, UserOutlined} from "@ant-design/icons";

interface RouteParams {
    id: string;
    token: string;
}


const ResetPasswordPage: React.FC = () => {
    const tenantInfo = useSelector((state: RootState) => state.persisted?.tenantInfo);
    const [fetchTenantInfo] = useLazyGetApiTenantGetTenantInfoQuery()
    const dispatch = useDispatch();
    const history = useHistory();
    const {location} = useHistory()
    const [ready, setReady] = useState(false);
    const [loginErrorState, setLoginErrorState] = useState("")
    const [loginForm] = Form.useForm();
    const [triggerResetPassword] = usePostApiUserCompletePasswordResetMutation()

    const {token} = useParams<RouteParams>();

    useEffect(() => {
        var id: string = location.pathname.split("/")?.[1]

        getTenantInfo(id)
            .then(k => {
                if (!k) {
                    history.push(`/404`);
                } else {
                    setReady(true);
                }
            })
    }, [location]);

    const getTenantInfo = async (id: string) => {
        try {
            var tentantData = await fetchTenantInfo({
                companyId: id
            }).unwrap()
            dispatch(setTenantInfo(tentantData));
            return true
        } catch (err: any) {
            return null
        }
    }

    const onFinish = async (values: any) => {
        setLoginErrorState("")
        if (token.trim() === "") {
            setError("Şifre Sıfırlanırken hata oluştu")
            return
        }

        try {
            dispatch(setLoading(true));
            await triggerResetPassword({
                completePasswordResetDto: {
                    token: token,
                    newPassword: values.password
                }
            }).unwrap()
            message.success('Şifreniz başarı ile değiştirildi. Giriş ekranına yönlendiriliyorsunuz.');
            history.push(`/${tenantInfo.id}/login`);
        } catch (err: any) {
            dispatch(setError("Şifre Sıfırlanırken hata oluştu"));
        } finally {
            dispatch(setLoading(false));
        }
    };


    if (!ready) {
        return <Spin percent={"auto"} size="large" fullscreen={true}/>
    }


    return (
        <div className="flex justify-center items-center h-screen flex-col">
            <div className="flex flex-col items-center mb-5">
                {tenantInfo &&
                    <img alt={`${tenantInfo?.groupName}`} src={`${process.env.REACT_APP_API_BASE_URL}/api/image/images/companies/${(tenantInfo as any)?.loginIcon}`} width={200}
                         height={"auto"}/>
                }
                <h1 className={"text-[20px]"}>{tenantInfo?.GroupName}</h1>
            </div>
            {loginErrorState != "" &&
                <Alert
                    message={t('error')}
                    description={loginErrorState}
                    type="error"
                    showIcon
                    className='w-1/3 mb-3'
                />
            }
            <Form
                name="login"
                form={loginForm}
                onFinish={onFinish}
                className="login-form w-1/3 max-w-[300]"
            >
                <Form.Item
                    name="password"
                    rules={[{required: true, message: t('please-input-your-password')}]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        placeholder={t('password-0')}/>
                </Form.Item>

                <Form.Item
                    name="passwordRepeat"
                    rules={[{required: true, message: t('please-input-your-password')},
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Şifreler uyuşmuyor!'));
                            },
                        }),]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        placeholder={"Şifre (Tekrar)"}/>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button w-full">
                        Şifremi Sıfırla
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ResetPasswordPage;
