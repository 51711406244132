import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "antd";
import {Pie} from "@ant-design/charts";
import {RootState} from "../../../app/store";
import {useSelector} from "react-redux";

const PlotMaps = {};

const PieChart1: React.FC = () => {
    const [data, setData] = useState({});
    const themeColors = useSelector((state: RootState) => state.app?.themeColors);
    
    useEffect(() => {
        setData([
            {"title": "Hedefin Üzerinde Başarı", "ratio": 55.6},
            {"title": "Hedefe Uygun Başarı", "ratio": 33.4},
            {"title": "Riskli Hedef", "ratio": 11},
        ])
    }, []);

    if (!Object.keys(data).length) {
        return null;
    }

    const LeftConfig = {
        angleField: 'ratio',
        colorField: 'title',
        // @ts-ignore
        data: data,
        label: {
            text: (d: any) => `${d.ratio}%`,
            position: "inside",
            style: {
                fontWeight: 'bold',
                fill: "#003262",
            },
        },
        scale: {
            color: { range: ['#00CCCC', '#D1E231', '#C0C0C0'] },
        },
        style: {
            stroke: '#fff',
            inset: 1,
            radius: 10,
        },
        legend: {
            color: {
                title: true,
                position: 'bottom',
                rowPadding: 5,
            },
        },
        tooltip: {
            title: 'title',
            items: [{name: '', field: "ratio", valueFormatter: (d: any) => `${d} %`}],
        },
        interaction: {
            elementHighlight: true,
        },
        state: {
            inactive: {opacity: 0.5},
        },
    };

    return (<>
        <Card title={
            <div>
                <h3>OKR Durumları (Hedef İlerlemelere Göre)</h3>
            </div>
        }
              styles={{
                  header: {
                      backgroundColor: themeColors?.primaryColor ?? ""
                  }
              }}
              bordered={true}>


            <Pie {...LeftConfig} />

        </Card>

    </>)
};

export default PieChart1;