import React, {useEffect} from 'react';
import {Button, Card, Checkbox, Form, message} from "antd";
import {setError, setLoading} from "../../../features/app/appSlice";
import {t} from "i18next";
import {useDispatch} from "react-redux";
import {
    CompanySettingDto,
    useLazyGetApiMCompanySettingsByCompanyIdQuery,
    usePostApiMCompanySettingsMutation
} from "../../../api/services/MCompany";
import {CopyOutlined} from "@ant-design/icons";
import {useCopyToClipboard} from "usehooks-ts";

export interface IMCompanyDetailsInfo {
    companyId: string

}

const MCompanyDetailsInfo: React.FC<IMCompanyDetailsInfo> = ({companyId}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [userSyncUrlForm] = Form.useForm();
    const [baseUrlForm] = Form.useForm();
    const dispatch = useDispatch();
    const [fetchSettings] = useLazyGetApiMCompanySettingsByCompanyIdQuery()
    const [saveSetting] = usePostApiMCompanySettingsMutation()
    const [_, copy] = useCopyToClipboard()
    
    useEffect(() => {
        if (companyId)
            getInfoSettings()
    }, [companyId]);

    const getInfoSettings = async () => {
        try {
            dispatch(setLoading(true));
            var settings = await fetchSettings({
                companyId: companyId,
                keys: ["BaseUrl", "UserSyncUrl"],
            }).unwrap()

            baseUrlForm.setFieldsValue({"BaseUrl": (settings as any[])?.find(k => k.key == "BaseUrl")?.value});
            userSyncUrlForm.setFieldsValue({"UserSyncUrl": (settings as any[])?.find(k => k.key == "UserSyncUrl")?.value});
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('E Posta çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const saveSettings = async (key: string, value: string) => {
        try {
            dispatch(setLoading(true));
            await saveSetting({
                body: [
                    {
                        companyId: companyId,
                        key: key,
                        value: value
                    } as CompanySettingDto,
                ]
            }).unwrap()
            await getInfoSettings()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Info ayarları kaydedilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    return (<>
        {contextHolder}
        <Card
            title={
                <div className={"flex flex-row justify-between items-center"}>
                    <span>Erişim Adresi</span>
                    <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                copy(`${window.location.origin.toString()}/${companyId}`)
                                messageApi.open({
                                    type: 'success',
                                    content: 'Adres Kopyalandı',
                                });

                            }}>
                            <CopyOutlined/>
                        </Button>
                </div>
            }
        >
            <p className={"font-semibold"}>{`${window.location.origin.toString()}/${companyId}`}</p>
        </Card>
            <Card
                className={"mt-3"}
                title={
                    <div className={"flex flex-row justify-between items-center"}>
                        <span>Base Adres</span>
                        <Button type={"primary"} onClick={() => {
                            baseUrlForm.submit()
                        }}> Kaydet </Button>
                    </div>
                }
            >
                <Form form={baseUrlForm} onFinish={async (v) => {
                    await saveSettings("BaseUrl", v.BaseUrl)
                }}>
                    <Form.Item
                        className={"mb-0"}
                        label={"Base Adres"}
                        name={[`BaseUrl`]}
                    >
                        <input className={"border-black border-[1px] rounded-md p-2 w-full"}/>
                    </Form.Item>
                </Form>
            </Card>
            <Card
                className={"mt-3"}
                title={
                    <div className={"flex flex-row justify-between items-center"}>
                        <span>Kullanıcı Eşitleme Adresi</span>
                        <Button type={"primary"} onClick={() => {
                            userSyncUrlForm.submit()
                        }}> Kaydet </Button>
                    </div>
                }
            >
                <Form form={userSyncUrlForm} onFinish={async (v) => {
                    await saveSettings("UserSyncUrl", v.UserSyncUrl)
                }}>
                    <Form.Item
                        className={"mb-0"}
                        label={"Kullanıcı Eşitleme Adresi"}
                        name={[`UserSyncUrl`]}
                    >
                        <input className={"border-black border-[1px] rounded-md p-2 w-full"}/>
                    </Form.Item>
                </Form>
            </Card>

    </>)
};

export default MCompanyDetailsInfo;