import { useEffect } from 'react';

export function useFavicon(faviconUrl: string) {
    useEffect(() => {
        let link = document.querySelector("link[rel*='icon']");
        if (!link) {
            link = document.createElement('link');
            // @ts-ignore
            link.rel = 'icon';
            document.head.appendChild(link);
        }
        // @ts-ignore
        link.href = faviconUrl;
    }, [faviconUrl]);
}
