import {Button, Card, Progress} from "antd";
import {PerformanceCardDetailsDto, StrategicOkrMaster, UserOkrDetailsDto} from "../../../api/services/performanceCards";
import PerformanceCardOKR from "./PerformanceCardOKR";
import {PerformanceCardGroupType} from "../PerformanceCardGroupType";
import {useEffect, useState} from "react";
import OKRInputModal from "./Modal/OKRInputModal";
import moment from "moment";
import {
    LinkedOKRId,
    ManagerProgressId,
    PersonelProgressId,
    WeightenedOKRId
} from "../../../data/constants";
import {hasProgressType, OKRProgressType} from "../../../helper/progressHelper";

export interface IPerformanceCardGroup {
    card: PerformanceCardDetailsDto | null
    CardDetails: PerformanceCardDetailsDto | undefined
    type: PerformanceCardGroupType
    needsRecalculation?: (okrs: UserOkrDetailsDto[]) => void
    progressUpdate?: () => void
    isPeriodFinished: boolean
}

const PerformanceCardGroup: React.FC<IPerformanceCardGroup> = ({
                                                                   type,
                                                                   card,
                                                                   CardDetails,
                                                                   needsRecalculation,
                                                                   progressUpdate,
                                                                   isPeriodFinished
                                                               }) => {
    const [okrInputActive, setOkrInputActive] = useState(false)
    const [isWeightened, setIsWeightened] = useState(false)
    const [isLinked, setIsLinked] = useState(false)
    const [okrs, setOkrs] = useState<UserOkrDetailsDto[] | undefined>(undefined)
    const [updateCard, setUpdateCard] = useState<UserOkrDetailsDto | null>(null)
    const [strategicOKR, setStrategicOKR] = useState<StrategicOkrMaster | undefined>()
    const [progressType, setProgressType] = useState<OKRProgressType[]>([])
    
    useEffect(() => {
        if (updateCard)
            setOkrInputActive(true)
    }, [updateCard])

    useEffect(() => {
        if (CardDetails) {
            var okrs: UserOkrDetailsDto[] = []
            if (type == PerformanceCardGroupType.Active) {
                okrs = CardDetails?.okRs?.filter(k => k.status == 0) ?? []
            } else if (type == PerformanceCardGroupType.Completed) {
                okrs = CardDetails?.okRs?.filter(k => k.status == 1) ?? []
            } else {
                okrs = CardDetails?.okRs?.filter(k => k.status == 2) ?? []
            }

            setOkrs(okrs)

            var okrWeightId = CardDetails.performanceSystem?.okrDetails?.[0].okrWeightId
            setIsWeightened(okrWeightId == WeightenedOKRId)

            var okrLinkedId = CardDetails.performanceSystem?.okrDetails?.[0].okrRelationshipId
            setIsLinked(okrLinkedId == LinkedOKRId)

            var strategicOKR = CardDetails.strategicOkrs
            setStrategicOKR(strategicOKR)

            calculateProgressType()
        }
    }, [CardDetails])

    const calculateProgressType = () => {
        var ptype = CardDetails?.performanceSystem?.okrDetails?.[0].okrProgressId

        if (ptype == PersonelProgressId)
            setProgressType([OKRProgressType.personel])
        else if (ptype == ManagerProgressId)
            setProgressType([OKRProgressType.manager])
        else {
            setProgressType([OKRProgressType.manager, OKRProgressType.personel])
        }
    }
    
    const getHeaderColor = () => {
        if (type == PerformanceCardGroupType.Active) {
            return "#89A8B2";
        } else if (type == PerformanceCardGroupType.Completed) {
            return "#5fbf6c";
        } else {
            return "#737373"
        }
    }

    const calculateTotalProgress = () => {
        if (isWeightened) {
            var total = (okrs ?? []).reduce((n, newVal, index) => (n + (newVal.progress ?? 0) * ((newVal.weight ?? 0) / 100)), 0)
            return Number((total).toFixed(2))
        } else {
            var total = (okrs ?? []).reduce((n, newVal, index) => (n + (newVal.progress ?? 0)), 0)
            return Number((total).toFixed(2))
        }

    }

    const calculateTotalWeight = () => {
        var weight = (okrs ?? []).reduce((n, newVal, index) => (n + (newVal.weight ?? 0)), 0)
        return weight
        
    }

    const getNotCancelledOKRs = () => {
        return CardDetails?.okRs?.filter(k => k.status === 0 || k.status === 1) ?? []
    }
    return (
        <div>
            <Card title={
                <div style={{background: getHeaderColor()}}
                     className="flex flex-row justify-between items-center rounded-md overflow-hidden">
                    <div className="flex flex-row h-[30px]">
                        {
                            okrs && (okrs.length > 0) &&
                            <h2 className="text-white ml-3 mr-5">{type}</h2>
                        }
                        {
                            okrs && (okrs.length == 0) &&
                            !isPeriodFinished &&
                            <h2 className="text-white ml-3 mr-5">Bir OKR ekleme ile başlayabilirsiniz</h2>
                        }
                        {isWeightened && 
                            (type == PerformanceCardGroupType.Active || type == PerformanceCardGroupType.Completed) &&
                            okrs && (okrs.length > 0) &&
                            <Progress
                                type="circle"
                                strokeColor={calculateTotalWeight() > 100 ? undefined : "#00FF00"} // exception olunca kendi kırmızı gelir
                                trailColor="#00000030"
                                format={() => `${calculateTotalWeight()}%`}
                                status={calculateTotalWeight() > 100 ? "exception" : "normal"}
                                percent={Math.min(calculateTotalWeight(), 100)}
                                size={30}
                            />
                        }
                    </div>
                    <div className="flex flex-row items-center">
                        {
                            okrs && okrs.length > 0 && type == PerformanceCardGroupType.Active &&
                            <Progress percent={calculateTotalProgress()}
                                      strokeColor={"#FF0000"}
                                      trailColor="#00000030"
                                      percentPosition={{align: 'start', type: 'outer'}} className="w-[200px] mr-2"/>
                        }

                        {type == PerformanceCardGroupType.Active &&
                            hasProgressType(progressType, OKRProgressType.personel) &&
                            okrs &&
                            !isPeriodFinished &&
                            <Button type="text" onClick={() => {
                                setOkrInputActive(true)
                            }} className="ml-2 text-white bg-red-500 border-0 rounded-none">
                                OKR Ekle
                            </Button>
                        }
                    </div>
                </div>
            }
                  bordered={false}
                  className="w-full"
                  style={{borderRadius: 0, boxShadow: "none", background: "transparent"}}
                  styles={{
                      body: {
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingTop: 0,
                          boxShadow: "none",
                          background: "transparent"
                      },

                      header: {
                          height: 30,
                          minHeight: 40,
                          padding: 0,
                          border: 0
                      }
                  }}>
                {okrs &&
                    okrs
                        .sort(function (left, right) {
                            return moment.utc(left.createdDate).diff(moment.utc(right.createdDate))
                        })
                        .map((k: UserOkrDetailsDto, i) => (
                            <PerformanceCardOKR index={i} okr={k}
                                                progressTypes={progressType}
                                                isPeriodFinished={isPeriodFinished}
                                                type={type}
                                                CardDetails={CardDetails}
                                                update={() => {
                                                    setUpdateCard(k)
                                                }}
                                                progressUpdate={() => {
                                                    if (progressUpdate) {
                                                        progressUpdate()
                                                    }
                                                }}/>
                        ))
                }
            </Card>
            <OKRInputModal
                CardDetails={CardDetails}
                okrs={getNotCancelledOKRs()}
                isLinked={isLinked}
                isWeightened={isWeightened}
                initialValue={updateCard}
                isActive={okrInputActive}
                setActive={(status) => {
                    setOkrInputActive(status);
                    setUpdateCard(null)
                }}
                cardId={CardDetails?.cardAssignmentId ?? ""}
                newOKRAdded={okr => {
                    setOkrs([...(okrs ?? []), okr])
                    setOkrInputActive(false)
                    calculateTotalProgress()
                    if (progressUpdate) {
                        progressUpdate()
                    }
                    setUpdateCard(null)
                }}
                okrUpdated={okr => {
                    var oldOKR = (okrs ?? []).find(k => k.okrId == okr.okrId)
                    var filteredOKRs = (okrs ?? []).filter(k => k.okrId != okr.okrId)
                    okr.createdDate = oldOKR?.createdDate

                    setOkrs([...filteredOKRs, okr])
                    setOkrInputActive(false)
                    calculateTotalProgress()
                    if (progressUpdate) {
                        progressUpdate()
                    }
                    setUpdateCard(null)
                }}
                okrDeleted={() => {
                    setOkrInputActive(false)
                    calculateTotalProgress()
                    if (progressUpdate) {
                        progressUpdate()
                    }
                    setUpdateCard(null)
                }}
                systemId={CardDetails?.performanceSystem?.id ?? ""}
                periodId={CardDetails?.period?.id ?? ""}
            />
        </div>
    );
}

export default PerformanceCardGroup;
