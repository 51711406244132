import React, {useEffect, useState} from 'react';
import {ConfigProvider, Spin} from "antd";
import {usePostApiAuthLoginMutation} from "../api/services/auth";
import {useLazyGetApiTenantGetTenantInfoQuery} from "../api/services/Tenant";
import {setError, setLoading, setTheme, setThemeColors} from "../features/app/appSlice";
import {t} from "i18next";
import {Redirect, useHistory} from "react-router-dom";
import {setTenantInfo} from "../app/persistedSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../app/store";
import {getTextColorFor} from "../helper/colorHelper";
import {useDocumentTitle} from "usehooks-ts";
import {useFavicon} from "../hooks/useFavicon";

export interface ITenantManager {
    children: React.ReactNode;
}

const TenantManager: React.FC<ITenantManager> = ({children}) => {
    const [ready, setReady] = useState(false);
    const [fetchTenantInfo] = useLazyGetApiTenantGetTenantInfoQuery()
    const {location} = useHistory()
    const dispatch = useDispatch();
    const theme = useSelector((state: RootState) => state.app?.themeConfig);
    const tenantInfo = useSelector((state: RootState) => state.persisted?.tenantInfo);

    useDocumentTitle(tenantInfo?.name ?? "", {preserveTitleOnUnmount: true});
    useFavicon(`${process.env.REACT_APP_API_BASE_URL}/api/image/images/companies/${(tenantInfo as any)?.faviconName}`)

    const getTenantInfo = async (id: string) => {
        try {
            var tentantData = await fetchTenantInfo({
                companyId: id
            }).unwrap()

            setTenantColors((tentantData as any)?.settings ?? [])

            dispatch(setTenantInfo(tentantData));
            return true
        } catch (err: any) {
            return null
        }
    }

    const setTenantColors = (settings: any[]) => {
        let primaryColor = settings.find(k => k.key === "primaryColor")?.value ?? "#89A8B2"
        let secondaryColor = settings.find(k => k.key === "secondaryColor")?.value ?? "#B3C8CF"
        let ternaryColor = settings.find(k => k.key === "ternaryColor")?.value ?? "#F1F0E8"

        dispatch(setThemeColors({
            primaryColor: primaryColor,
            secondaryColor: secondaryColor,
            ternaryColor: ternaryColor
        }))

        dispatch(setTheme({
            token: {
                "colorBgElevated": ternaryColor,
                "colorPrimary": primaryColor,
                "colorPrimaryBg": primaryColor,
                "colorPrimaryBgHover": primaryColor,
                "colorPrimaryHover": primaryColor,
                "colorText": getTextColorFor(primaryColor),
                "colorBorder": secondaryColor,
                "colorPrimaryBorder": secondaryColor,
                "fontFamily": "Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
            },
            components: {
                Layout: {
                    "siderBg": primaryColor,
                    "triggerColor": "#000000",
                    "bodyBg": ternaryColor,
                    "headerBg": primaryColor,
                    "headerColor": secondaryColor,
                },
                Menu: {
                    "darkItemBg": primaryColor,
                    "darkItemColor": getTextColorFor(primaryColor),
                    "darkItemHoverColor": getTextColorFor(primaryColor),
                    "darkItemSelectedColor": getTextColorFor(primaryColor),
                    "darkPopupBg": primaryColor,

                },
                Button: {
                    "defaultColor": getTextColorFor(primaryColor),
                    "defaultActiveColor": getTextColorFor(primaryColor),
                    "primaryColor": getTextColorFor(primaryColor),
                },
                Progress: {
                    "circleTextFontSize": "10px"
                },
                Slider: {
                    "handleColor": "green",
                },
                Tabs: {
                    "itemActiveColor": getTextColorFor(primaryColor),
                    "itemSelectedColor": getTextColorFor(primaryColor),
                    "itemHoverColor": getTextColorFor(primaryColor),
                    "itemColor": getTextColorFor(primaryColor),
                },
                DatePicker: {
                    "cellHoverBg": secondaryColor,
                    "cellActiveWithRangeBg": secondaryColor,
                    "hoverBg": secondaryColor,
                },
            },
        }))

    }

    useEffect(() => {
        var id: string = location.pathname.split("/")?.[1]
        getTenantInfo(id)
            .then(k => {
                if (k) {
                    setReady(true);
                } else {
                    setReady(true)
                }
            })
    }, [location]);

    if (!ready) {
        return <Spin percent={"auto"} size="large" fullscreen={true}/>
    }
    return <>{children}</>;
};

export default TenantManager;