// src/components/PerformanceGoalEntry/PerformanceGoalEntryModal.tsx
import React, {useEffect, useState} from 'react';
import {Modal, Form, Button, Popover, FormInstance, Alert} from 'antd';
import StrategicOKRGoalTable from './StrategicOKRGoalTable';
import FormItemCombobox from '../../components/FormItemCombobox';
import {InfoCircleOutlined} from '@ant-design/icons';
import {useLazyGetApiPeriodGetActivePeriodsQuery} from '../../api/services/period';
import {DataItem} from '../../models/DataItem';
import {t} from 'i18next';
import {useSelector, useDispatch} from 'react-redux';
import {setLoading, setError} from '../../features/app/appSlice';
import {RootState} from '../../app/store';
import {ParameterType, usePostApiParameterGetParametersMutation} from '../../api/services/parameters';
import {usePostApiPerformanceGoalSavePerformanceGoalsMutation} from '../../api/services/PerformanceGoal';
import {PerformanceSystemDto, useLazyGetApiPerformanceSystemQuery} from '../../api/services/performanceSystem';
import {
    StrategicOkrMasterDto,
    usePostApiStrategicOkrSaveStrategicOkrMutation,
    usePutApiStrategicOkrUpdateStrategicOkrByIdMutation
} from '../../api/services/StrategicOKR';
import {WeightenedOKRId} from '../../data/constants';

interface StrategicOKREntryModalProps {
    visible: boolean;
    onClose: () => void;
    onChange: () => void;
    initialValues?: any;
    form: FormInstance
    generalStrategicOKRs: StrategicOkrMasterDto[] | undefined;
}

const StrategicOKREntryModal: React.FC<StrategicOKREntryModalProps> = ({
                                                                           initialValues,
                                                                           visible,
                                                                           onClose,
                                                                           onChange,
                                                                           form,
                                                                           generalStrategicOKRs
                                                                       }) => {

    const [fetchActivePeriods, {data: activePeriods}] = useLazyGetApiPeriodGetActivePeriodsQuery()
    const [getParameters, {data: parameters}] = usePostApiParameterGetParametersMutation()
    const [saveStrategicOkrOnService] = usePostApiStrategicOkrSaveStrategicOkrMutation()
    const [updateStrategicOkrOnService] = usePutApiStrategicOkrUpdateStrategicOkrByIdMutation()
    const [fetchPerformanceSystems, {data: performanceSystems}] = useLazyGetApiPerformanceSystemQuery()
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch();
    const [SelectedPerformanceSystem, setSelectedPerformanceSystem] = useState<PerformanceSystemDto | undefined>()
    const [errorMessage, setErrorMessage] = useState("")
    const [parameterErrorMessage, setParameterErrorMessage] = useState("")
    const [isSuitable, setIsSuitable] = useState<boolean>(true)

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues)

            if (initialValues?.systemdId) {
                setSelectedPerformanceSystem(performanceSystems?.find(k => k.id == initialValues.systemdId))
            }
        }
    }, [initialValues])

    useEffect(() => {
        if (user) {
            getActivePeriods()
            getPerformanceSystems()
            getParameters({
                companyId: user.companyId,
                body: [
                    7 as ParameterType, //HEDEF_OLCUM_KAYNAGI
                ]
            })
        }
    }, [user])

    const getActivePeriods = async () => {
        try {
            dispatch(setLoading(true));
            await fetchActivePeriods().unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getPerformanceSystems = async () => {
        try {
            dispatch(setLoading(true));
            await fetchPerformanceSystems({
                companyId: user?.companyId ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Performans sistemleri sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const isFormValid = (values: any) => {
        if (values.periodId == undefined) {
            setErrorMessage("Bir performans dönemi seçmelisiniz.")
            return false
        }

        if (values.systemdId == undefined) {
            setErrorMessage("Bir performans sistemi seçmelisiniz.")
            return false
        }

        var totalWeight = values.rows.reduce((sum: number, row: any) => sum + (Number(row.weight) || 0), 0);

        if (SelectedPerformanceSystem?.okrDetails?.[0].okrWeightId == WeightenedOKRId && totalWeight != 100) {
            setErrorMessage("Hedef Ağırlık Toplamı 100% olmalı.")
            return false
        }


        var totalOKR = values.rows.length
        var performanceSystem = performanceSystems?.find(k => k.id == initialValues.systemdId)
        var requiredOKRCountDetails = performanceSystem?.performanceSystemRanges?.find(k => k.rangeType == 1)
        if (requiredOKRCountDetails != undefined &&
            requiredOKRCountDetails!.minValue != undefined &&
            requiredOKRCountDetails?.maxValue != undefined) {
            if ((totalOKR < requiredOKRCountDetails!.minValue ?? 0) || (totalOKR > requiredOKRCountDetails?.maxValue ?? 0)) {
                setErrorMessage(`Seçilen Performans sistemine göre Stratejik OKR Sayısı en az ${requiredOKRCountDetails!.minValue}; en çok ${requiredOKRCountDetails!.maxValue} adet olmalı.`)
                return false
            }
        }

        return true
    }

    const saveStrategicOKr = async (values: any) => {
        if (!isFormValid(values)) return
        
        setErrorMessage("")
        setParameterErrorMessage("")
        
        try {
            dispatch(setLoading(true));

            if (initialValues?.id == undefined) {
                await saveStrategicOkrOnService({
                    saveStrategicOkrdto: {
                        companyId: user?.companyId ?? "",
                        performancePeriodId: values.periodId,
                        performanceSystemId: values.systemdId,
                        okrDetails: values.rows.map((k: any) => ({
                            strategicOKR: k.okr,
                            okrWeight: k.weight,
                            targetSource: k.goalMeasurementSource,
                            targetResult: k.targetResult
                        }))
                    }
                }).unwrap()
            } else {
                await updateStrategicOkrOnService({
                    id: initialValues.id,
                    saveStrategicOkrdto: {
                        companyId: user?.companyId ?? "",
                        performancePeriodId: values.periodId,
                        performanceSystemId: values.systemdId,
                        okrDetails: values.rows.map((k: any) => ({
                            strategicOKR: k.okr,
                            okrWeight: k.weight,
                            targetSource: k.goalMeasurementSource,
                            targetResult: k.targetResult
                        }))
                    }
                }).unwrap()
            }

            onChange()
            onClose()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Performans hedefi kaydedilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const onComplete = () => {
        form.submit()
    }

    const onSave = (values: any) => {
        saveStrategicOKr(values)
    }

    const checkSelectedParamsSuitable = () => {
        var periodId = form.getFieldValue("periodId")
        var systemId = form.getFieldValue("systemdId")

        if (periodId && systemId) {
            var items = generalStrategicOKRs?.filter(k => k.performanceSystem?.id == systemId && k.period?.id == periodId)
            if (items?.length ?? 0 > 0) {
                setIsSuitable(false)
                setParameterErrorMessage("Aynı Performans Dönem ve Sisteminde tanımlı Stratejik OKR bulunmaktadır.")
            } else {
                setIsSuitable(true)
                setParameterErrorMessage("")
            }
        }

    }

    const content = (
        <div style={{width: 300}}>
            <h4>Sayfa Bilgisi</h4>
            <p>Bu sayfada performans sistemlerini tanımlayabilir ve yönetebilirsiniz.</p>
            <p>OKR veya KPI bazlı performans sistemlerini burada tanımlayarak, ilgili parametreleri
                belirleyebilirsiniz.</p>
            <p>Sistemin nasıl çalıştığını ve hangi parametrelerin gerekli olduğunu burada yapılandırabilirsiniz.</p>
        </div>
    );

    const closeModal = () => {
        setErrorMessage("")
        setParameterErrorMessage("")
        onClose()
    }
    return (
        <Modal
            visible={visible}
            destroyOnClose
            maskClosable={false}
            title={
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span>{initialValues ? "Stratejik OKR Güncelle" : "Stratejik OKR Girişi"}</span>
                    {
                        errorMessage != "" &&
                        <Alert message={errorMessage} type="error" closable onClick={() => {
                            setErrorMessage("")
                        }}/>
                    }
                    <Popover content={content} title="Sayfa Bilgisi" trigger="click">
                        <Button
                            shape="circle"
                            icon={<InfoCircleOutlined/>}
                            style={{
                                marginRight: 30,
                                marginTop: -8,
                                zIndex: 1000,
                            }}
                        />
                    </Popover>
                </div>
            }
            onCancel={() => {
                setErrorMessage("")
                setParameterErrorMessage("")
                closeModal()    
            }}
            footer={[
                <Button key="cancel" onClick={closeModal}>
                    İptal
                </Button>,
                <>
                    {isSuitable &&
                        <Button key="save" type="primary" onClick={onComplete}>
                            Kaydet
                        </Button>
                    }
                </>

            ]}
            width={1200}
            bodyStyle={{maxHeight: '70vh', overflowY: 'auto'}}
        >
            <Form form={form} layout="vertical"
                  onFinish={onSave}>
                <div className='flex flex-row'>
                    <FormItemCombobox name="periodId" label="Performans Dönemi" placeholder="Performans Dönemi Seçiniz"
                                      onChange={() => {
                                          checkSelectedParamsSuitable()
                                      }}
                                      datas={(activePeriods ?? []).map(k => ({
                                          id: k.id,
                                          name: k.periodName
                                      } as DataItem))}
                                      style={{width: "300px"}}/>
                    <FormItemCombobox name="systemdId"
                                      label="Performans Sistemi"
                                      placeholder="Performans Sistemi Seçiniz"
                                      onChange={(e: DataItem) => {
                                          setSelectedPerformanceSystem(performanceSystems?.find(k => k.id == e.id))
                                          checkSelectedParamsSuitable()
                                      }}
                                      datas={(performanceSystems ?? []).filter(k => k.performanceSystemType == 1).map(k => ({
                                          id: k.id,
                                          name: k.systemName
                                      } as DataItem))}
                                      style={{width: "300px", marginLeft: 20}}/>
                    {parameterErrorMessage && parameterErrorMessage != "" &&
                        <Alert message={parameterErrorMessage}
                               className={"ml-2 h-[35px] mt-[30px]"}
                               type="error"
                               />
                    }
                </div>
                <StrategicOKRGoalTable goal={initialValues} selectedPerformanceSystem={SelectedPerformanceSystem}/>
            </Form>
        </Modal>
    );
};

export default StrategicOKREntryModal;
