import React, {useEffect, useState} from 'react';
import {Form, Input, Button, Alert, Checkbox, Typography, Spin, Modal, Table, message} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {useLazyGetApiAuthMeQuery, usePostApiAuthLoginMutation} from '../api/services/auth';
import {
    setAccessToken,
    setAuthorizationInfo,
    setRefreshToken,
    setTenantInfo,
    toggleLoggedIn
} from '../app/persistedSlice';
import {setError, setLoading} from '../features/app/appSlice';
import {setUserInfo} from '../features/auth/authSlice';
import {t} from 'i18next';
import {InboxOutlined, LockOutlined, UserOutlined} from '@ant-design/icons';
import {Params} from "../components/PrivateRoute";
import {RootState} from "../app/store";
import {useLazyGetApiTenantGetTenantInfoQuery} from "../api/services/Tenant";
import {usePostApiUserInitiateResetPasswordMutation} from "../api/services/user";

const Login: React.FC = () => {
    const [login, {
        data: loginData,
        isLoading,
        isSuccess: isLoginSuccess,
        error: loginError
    }] = usePostApiAuthLoginMutation()
    const tenantInfo = useSelector((state: RootState) => state.persisted?.tenantInfo);
    const [fetchTenantInfo] = useLazyGetApiTenantGetTenantInfoQuery()
    const dispatch = useDispatch();
    const history = useHistory();
    const {location} = useHistory()
    const [ready, setReady] = useState(false);
    const [loginErrorState, setLoginErrorState] = useState("")
    const [showResetPassword, setShowResetPassword] = useState(false)
    const [resetPasswordForm] = Form.useForm();
    const [loginForm] = Form.useForm();
    const [triggerResetPassword] = usePostApiUserInitiateResetPasswordMutation()

    useEffect(() => {
        var id: string = location.pathname.split("/")?.[1]

        getTenantInfo(id)
            .then(k => {
                if (!k) {
                    history.push(`/404`);
                } else {
                    setReady(true);
                }
            })
    }, [location]);

    const getTenantInfo = async (id: string) => {
        try {
            var tentantData = await fetchTenantInfo({
                companyId: id
            }).unwrap()
            dispatch(setTenantInfo(tentantData));
            return true
        } catch (err: any) {
            return null
        }
    }

    const onFinish = async (values: any) => {
        setLoginErrorState("")

        await login({
            loginModel: {
                companyId: tenantInfo.id,
                username: values.email,
                password: values.password
            }
        })
    };

    useEffect(() => {
        dispatch(setLoading(isLoading))
    }, [isLoading])

    useEffect(() => {
        if (isLoginSuccess && loginData) {
            if (loginData?.result?.succeeded ?? false) {
                dispatch(setAccessToken(loginData.token ?? ""))
                dispatch(setRefreshToken(loginData.refreshToken ?? ""))
                dispatch(toggleLoggedIn(true))
                dispatch(setAuthorizationInfo(loginData.userAuthorizationInfo))
                
                history.push(`/${tenantInfo.id}/`);
            } else {
                if (loginData?.result?.isLockedOut || loginData?.result?.isNotAllowed) {
                    setLoginErrorState("Hesabınız kilitlenmiştir. Tekrar açmak için hesabınızı sıfırlayınız.")
                } else {
                    setLoginErrorState(t('please-check-your-login-informations'))
                }
            }
        }
    }, [isLoginSuccess])

    useEffect(() => {
        if (loginError) {
            setLoginErrorState(t('please-check-your-login-informations'))
            // dispatch(setError(loginError.))
            console.log(loginError)
        }
    }, [loginError])

    if (!ready) {
        return <Spin percent={"auto"} size="large" fullscreen={true}/>
    }

    const onResetFormFinish = async (values: any) => {
        try {
            await triggerResetPassword({
                initiatePasswordResetDto: {
                    email: values.email,
                }
            }).unwrap()
            setShowResetPassword(false);
            message.success('Şifre sıfırlama yönergeleri e-posta adresinize gönderilmiştir.');
        } catch (err: any) {
            message.error('Şifre sıfırlanırken bir problem oluştu.');
        }
    }
    const handleClose = () => {
        setShowResetPassword(false);
    };

    const handleResetPassword = () => {
        resetPasswordForm.submit()
    };

    const cancelApproveButtons =
        (
            <div style={{marginTop: 16, textAlign: 'right'}}>
                <Button onClick={handleClose} style={{marginRight: 8}}>
                    İptal
                </Button>
                <Button type="primary" onClick={handleResetPassword}>
                    Şifremi Resetle
                </Button>
            </div>
        )
    const resetPassword = () => {
        setShowResetPassword(true)
        resetPasswordForm.setFieldValue("email", loginForm.getFieldValue("email"))
    }

    return (
        <div className="flex justify-center items-center h-screen flex-col">
            <Modal
                visible={showResetPassword}
                title="Şifre Sıfırlama"
                onCancel={handleClose}
                destroyOnClose={true}
                footer={cancelApproveButtons}
                height={150}
            >
                <Spin spinning={false}>
                    <Form
                        form={resetPasswordForm}
                        onFinish={onResetFormFinish}>
                        <Form.Item
                            name="email"
                            rules={[{required: true, message: t('please-input-your-email')}]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                                   placeholder={t('e-mail')}/>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            <div className="flex flex-col items-center mb-5">
                {tenantInfo &&
                    <img alt={`${tenantInfo?.groupName}`}
                         src={`${process.env.REACT_APP_API_BASE_URL}/api/image/images/companies/${(tenantInfo as any)?.loginIcon}`}
                         width={200}
                         height={"auto"}/>
                }
                <h1 className={"text-[20px]"}>{tenantInfo?.GroupName}</h1>
            </div>
            {loginErrorState != "" &&
                <Alert
                    message={t('error')}
                    description={loginErrorState}
                    type="error"
                    showIcon
                    className='w-1/3 mb-3'
                />
            }
            <Form
                name="login"
                form={loginForm}
                onFinish={onFinish}
                className="login-form w-1/3 max-w-[300]"
            >
                <Form.Item
                    name="email"
                    rules={[{required: true, message: t('please-input-your-email')}]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder={t('e-mail')}/>
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{required: true, message: t('please-input-your-password')}]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        placeholder={t('password-0')}/>
                </Form.Item>

                <Form.Item>

                    <a className="login-form-forgot float-right" onClick={() => {
                        resetPassword();
                    }}>
                        Şifremi Sıfırla
                    </a>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button w-full">
                        {t('log-in')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login;
