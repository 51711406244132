import React, { useState } from 'react';
import { Modal, Upload, Button, Typography, message, Spin, Table, Form, Checkbox, Tooltip } from 'antd';
import { UploadOutlined, InboxOutlined, CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useLazyGetApiUserByCompanyGroupByCompanyGroupIdQuery, usePostApiUserImportAndCompareMutation, usePostApiUserProcessImportedUsersMutation } from '../../api/services/user';
import {useDispatch, useSelector} from 'react-redux';
import { setLoading, setError } from '../../features/app/appSlice';
import { t } from 'i18next';
import {RootState} from "../../app/store";

const { Link, Text } = Typography;
const { Dragger } = Upload;

interface UserUploadModalProps {
    visible: boolean;
    onClose: () => void;
    onUpload: (file: File) => void;
    onUpdate: () => void;
}

const UserUploadModal: React.FC<UserUploadModalProps> = ({ visible, onClose, onUpload, onUpdate }) => {
    const [importExcell, { data: users, isLoading: excellLoading, error }] = usePostApiUserImportAndCompareMutation()
    const [processUsers, { data: processResult, isLoading: processIsLoading, error: processError }] = usePostApiUserProcessImportedUsersMutation()
    const [loading, setFileLoading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [blob, setBlob] = useState<Blob | null>(null);
    const [userData, setUserData] = useState<any[]>([]);
    const [companyData, setCompanyData] = useState<any[]>([]);
    const [unaddableUserData, setUnaddableUserData] = useState<any[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth?.user);
    
    const beforeUpload = (file: File) => {
        const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isXlsx) {
            message.error('Sadece .xlsx uzantılı dosyalar yüklenebilir!');
        }
        const isLt25M = file.size / 1024 / 1024 < 25;
        if (!isLt25M) {
            message.error('Dosya 25MB\'den küçük olmalıdır!');
        }
        return isXlsx && isLt25M;
    };

    const handleFileChange = (info: any) => {
        const file = info.file;
        setFile(file);

        info.file.status = 'done';

        message.success(`${info.file.name} dosyası seçildi.`);
    };

    const uploadExcel = async () => {
        try {
            const formData = new FormData();
            formData.append('file', file!);
            formData.append('companyId', user?.companyId ?? ""!);
            await importExcell({
                companyId: user?.companyId ?? "",
                // @ts-ignore
                body: formData
            }).unwrap()
                .then(items => {
                    onUpload(file!);
                    setFileLoading(false);
                    setUploaded(true);

                    const importedUserData = items.addableUsers?.map(k => {
                        return {
                            key: k.employeeNumber,
                            ...k
                        }
                    }) ?? [];

                    setUserData(importedUserData)
                    setSelectedRows(importedUserData.map(data => data.key)); // Tüm satırları seçili yap 

                    //Map company data
                    const importedCompanyData = items.missingCompanies?.map(k => {
                        return {
                            key: k.email,
                            ...k
                        }
                    }) ?? [];
                    setCompanyData(importedCompanyData)

                    //Map unaddable users
                    const unaddableUserData = items.usersLinkedToMissingCompanies?.map(k => {
                        return {
                            key: k.email,
                            ...k
                        }
                    }) ?? [];
                    setUnaddableUserData(unaddableUserData)
                })
        } catch (err) {
            dispatch(setError(t('dosyayi-yueklerken-hata-olustu')));
        } finally {
            dispatch(setLoading(false));
            setFileLoading(false);
        }
    };

    const handleUploadClick = () => {
        if (!file) {
            message.error('Lütfen bir dosya seçin!');
            return;
        }

        setFileLoading(true);

        uploadExcel();
    };

    const handleConfirm = async () => {
        try {
            const selectedUsers = userData.filter((data) => selectedRows.includes(data.key));
            await processUsers({
                importResultDto: {
                    addableUsers: selectedUsers
                }
            }).unwrap()
        } catch (err) {
            dispatch(setError(t('kullanicilar-islenirken-hata-olustu')));
        } 
        
        handleClose()
        onUpdate()
        message.success('Seçili veriler onaylandı!');
    };

    const companyColumns = [
        {
            title: 'Şirket',
            dataIndex: 'companyName',
            key: 'companyName',
        }
    ]

    const unaddableUserColumns = [
        {
            title: 'Şirket',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'Adı',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Soyadı',
            dataIndex: 'lastName',
            key: 'lastName',
        }
    ]

    const userColumns = [
        {
            title: 'Sicil No',
            dataIndex: 'employeeNumber',
            key: 'employeeNumber',
        },
        {
            title: 'Adı',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Soyadı',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Durum',
            dataIndex: 'status',
            key: 'status',
            render: (text: string) => {
                let icon;
                let tooltipText;

                if (text === 'add') {
                    icon = <CheckCircleOutlined style={{ color: 'green' }} />;
                    tooltipText = "Bu kayıt sistemde bulunmamaktadır. Seçmeniz durumunda yeni eklenecektir.";
                } else if (text === 'still') {
                    icon = <MinusCircleOutlined style={{ color: 'orange' }} />;
                    tooltipText = "Bu kayıt zaten bulunmaktadır. Detaylarında güncelleme varsa seçmeniz durumunda güncelleme yapılacak.";
                } else if (text === 'delete') {
                    icon = <CloseCircleOutlined style={{ color: 'red' }} />;
                    tooltipText = "Bu kayıt yüklemiş olduğunuz dosyada bulunmadığı için seçmeniz durumunda silinecektir.";
                }

                return (
                    <Tooltip title={tooltipText}>
                        {icon}
                    </Tooltip>
                );
            },
        },
    ];

    const handleClose = () => {
        form.resetFields();  // Formu resetle
        setFile(null);       // Seçili dosyayı temizle
        setUploaded(false);  // Yükleme durumunu sıfırla
        setSelectedRows([]); // Seçili satırları sıfırla
        onClose();           // Modalı kapat
    };

    const cancelApproveButtons =
        (
            <div style={{ marginTop: 16, textAlign: 'right' }}>
                <Button onClick={handleClose} style={{ marginRight: 8 }}>
                    İptal
                </Button>
                <Button type="primary" onClick={handleConfirm}>
                    Onayla
                </Button>
            </div>
        )


    return (
        <Modal
            visible={visible}
            title="Excel'den Yükle"
            onCancel={handleClose}
            footer={(uploaded && !loading) ? cancelApproveButtons : null}
            bodyStyle={{ maxHeight: '65vh', overflowY: 'auto', paddingRight: '16px' }}
        >
            <Form form={form}>
                <div style={{ position: 'relative' }}>
                    {loading && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                zIndex: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Spin tip="Yükleniyor..." />
                        </div>
                    )}
                    {!uploaded && (
                        <>
                            <p>
                                Yükleme için Excel şemasını <Link href="/public_files/sample_user_file.xlsx" target="_blank">buradan</Link> indirebilirsiniz.
                            </p>
                            <Dragger
                                name="file"
                                beforeUpload={beforeUpload}
                                customRequest={(info) => {
                                    handleFileChange(info);
                                }}
                                accept=".xlsx"
                                showUploadList={false}
                                maxCount={1}
                                style={{ width: '100%', padding: '20px', border: '2px dashed #1890ff', borderRadius: '4px', marginTop: 10 }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Dosyayı sürükleyin veya buraya tıklayın</p>
                                <p className="ant-upload-hint">Yalnızca .xlsx dosyaları yüklenebilir ve dosya boyutu 25MB'den küçük olmalıdır.</p>
                            </Dragger>
                            <Button
                                type="primary"
                                style={{ marginTop: 16, width: '100%' }}
                                onClick={handleUploadClick}
                            >
                                Yükle
                            </Button>
                        </>
                    )}
                    {uploaded && !loading && (
                        <>
                            <Spin spinning={processIsLoading}>
                                <Text type="secondary">
                                    Aşağıda yüklediğiniz dosya ile kayıtlar karşılaştırılarak bir sonuç ürettik. Sonuçların yanında yapılacak işlemleri görüntüleyebilirsiniz. Onayladığınız değişiklikleri seçerek onayla butonuna basabilirsiniz.
                                </Text>

                                {companyData.length > 0 &&
                                    <p style={{ marginTop: 20 }}>
                                        <Text type="warning" >
                                            Yüklenen dosyada sistemde olmayan şirketlere bağlı kullanıcılar bulduk. Şirketler ekranından yeni kayıt oluşturarak bu kullanıcıları ekleyebilirsiniz.
                                        </Text>
                                        <Table
                                            dataSource={companyData}
                                            columns={companyColumns}
                                            pagination={false}
                                            style={{ marginTop: 16 }}
                                        />
                                    </p>
                                }

                                {unaddableUserData.length > 0 &&
                                    <p style={{ marginTop: 20 }}>
                                        <Text type="warning" >
                                            Eklenemeyen kullanıcılar
                                        </Text>
                                        <Table
                                            dataSource={unaddableUserData}
                                            columns={unaddableUserColumns}
                                            pagination={false}
                                            style={{ marginTop: 16 }}
                                        />
                                    </p>
                                }

                                {userData.length > 0 &&
                                    <p style={{ marginTop: 20 }}>
                                        {unaddableUserData.length > 0 &&
                                            <Text type="success" >
                                                Eklenebilen kullanıcılar
                                            </Text>
                                        }
                                        <Table
                                            dataSource={userData}
                                            columns={userColumns}
                                            rowSelection={{
                                                type: 'checkbox',
                                                selectedRowKeys: selectedRows,
                                                onChange: setSelectedRows,
                                                selections: true,
                                            }}
                                            pagination={false}
                                            style={{ marginTop: 16 }}
                                        />
                                    </p>
                                }
                            </Spin>
                        </>
                    )}
                </div>
            </Form>
        </Modal >
    );
};

export default UserUploadModal;
