import React, { useEffect, useState } from 'react';
import PerformanceSystemTables from './PerformanceSystemTables';
import { PerformanceSystemTypeIndex } from '../../enums/PerformanceSystemType';
import FormItemCombobox from '../FormItemCombobox';
import { PerformanceSystemDto, PerformanceSystemRangeDto, RangeType } from '../../api/services/performanceSystem';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { ParameterDto } from '../../api/services/parameters';
import { DataItem } from '../../models/DataItem';
import { extractParam } from '../../helper/paramHelper';

interface PerformanceSystemFormProps {
  systemType: PerformanceSystemTypeIndex | null;
  initialValues?: PerformanceSystemDto | null;
  parameters?: ParameterDto[];
}

const PerformanceSystemForm: React.FC<PerformanceSystemFormProps> = ({ parameters, systemType, initialValues }) => {
  const user = useSelector((state: RootState) => state.auth?.user);
  const dispatch = useDispatch();


  const [okrWeighting, setOkrWeighting] = useState<PerformanceSystemRangeDto | null>(null);
  const [okrActionData, setOkrActionData] = useState<PerformanceSystemRangeDto[]>([]);
  const [okrWeightData, setOkrWeightData] = useState<PerformanceSystemRangeDto[]>([]);
  const [kpiActionData, setKpiActionData] = useState<PerformanceSystemRangeDto[]>([]);
  const [kpiWeightData, setKpiWeightData] = useState<PerformanceSystemRangeDto[]>([]);
  let OKRWeightId = '6ad87b41-2e7c-49ad-9919-f997c4b03807'

  const [userRoles, setUserRoles] = useState<DataItem[]>([])
  const [okrShapes, setOkrShapes] = useState<DataItem[]>([])
  const [okrRelations, setOkrRelations] = useState<DataItem[]>([])
  const [okrTypes, setOkrTypes] = useState<DataItem[]>([])
  const [okrWeightings, setOkrWeightings] = useState<DataItem[]>([])
  const [okrPrivacies, setOkrPrivacies] = useState<DataItem[]>([])
  const [okrTransparencies, setOkrTransparencies] = useState<DataItem[]>([])
  const [okrProgressions, setOkrProgressions] = useState<DataItem[]>([])

  const [kpiGoalCalculationTypes, setKpiGoalCalculationTypes] = useState<DataItem[]>([])
  const [kpiGoalCalculationSource, setKpiGoalCalculationSource] = useState<DataItem[]>([])
  const [kpiCompentencySelections, setKpiCompentencySelections] = useState<DataItem[]>([])
  const [kpiGoalTypes, setKpiGoalTypes] = useState<DataItem[]>([])
  const [kpiRelations, setKpiRelations] = useState<DataItem[]>([])


  useEffect(() => {
    if (parameters) {
      setUserRoles(extractParam(parameters, 2))

      setKpiGoalCalculationTypes(extractParam(parameters, 6))
      setKpiGoalCalculationSource(extractParam(parameters, 7))
      setKpiCompentencySelections(extractParam(parameters, 8))
      setKpiGoalTypes(extractParam(parameters, 5))
      setKpiRelations(extractParam(parameters, 11))

      setOkrShapes(extractParam(parameters, 10))
      setOkrRelations(extractParam(parameters, 11))
      setOkrTypes(extractParam(parameters, 12))
      setOkrWeightings(extractParam(parameters, 13))
      setOkrPrivacies(extractParam(parameters, 14))
      setOkrTransparencies(extractParam(parameters, 15))
      setOkrProgressions(extractParam(parameters, 16))
      // debugger
    }
  }, [parameters])

  useEffect(() => {
    if (initialValues) {
      setOkrActionData((initialValues.performanceSystemRanges ?? []).filter(k => (k.rangeType == 1 as RangeType) || (k.rangeType == 2 as RangeType) || (k.rangeType == 3 as RangeType)));
      setOkrWeightData((initialValues.performanceSystemRanges ?? []).filter(k => (k.rangeType == 4 as RangeType) || (k.rangeType == 5 as RangeType) || (k.rangeType == 6 as RangeType) || (k.rangeType == 7 as RangeType)));

      var details = initialValues.okrDetails != null ? initialValues.okrDetails[0] : null
      if (details) {
        handleOkrWeightingChange({ name: "", id: details.okrWeightId ?? "" } as DataItem)
      }
      setOkrWeighting((initialValues.performanceSystemRanges ?? []).find(k => k.rangeType == 1 as RangeType) ?? null);

      setKpiActionData((initialValues.performanceSystemRanges ?? []).filter(k => (k.rangeType == 8 as RangeType) || (k.rangeType == 9 as RangeType) || (k.rangeType == 10 as RangeType) || (k.rangeType == 11 as RangeType)));
      setKpiWeightData((initialValues.performanceSystemRanges ?? []).filter(k => (k.rangeType == 12 as RangeType) || (k.rangeType == 13 as RangeType) || (k.rangeType == 14 as RangeType) || (k.rangeType == 15 as RangeType)));
    }
  }, [initialValues]);

  useEffect(() => {
    if (systemType === PerformanceSystemTypeIndex.OKR && !initialValues) {
      setOkrActionData([
        { id: "1", rangeType: 1, minValue: 0, maxValue: 0 },
        { id: "2", rangeType: 2, minValue: 0, maxValue: 0 },
        { id: "3", rangeType: 3, minValue: 0, maxValue: 0 },
      ]);

      if (okrWeighting?.id === OKRWeightId) {
        setOkrWeightData([
          { id: "4", rangeType: 4, minValue: 0, maxValue: 0 },
          { id: "5", rangeType: 5, minValue: 0, maxValue: 0 },
          { id: "6", rangeType: 6, minValue: 0, maxValue: 0 },
          { id: "7", rangeType: 7, minValue: 0, maxValue: 0 },
        ]);
      }
    }
    else if (systemType === PerformanceSystemTypeIndex.KPI && !initialValues) {

      setKpiActionData([
        { id: "8", rangeType: 8, minValue: 0, maxValue: 5 },
        { id: "9", rangeType: 9, minValue: 0, maxValue: 6 },
        { id: "10", rangeType: 10, minValue: 0, maxValue: 3 },
        { id: "11", rangeType: 11, minValue: 0, maxValue: 3 },
      ]);

      setKpiWeightData([
        { id: "12", rangeType: 12, minValue: 0, maxValue: 0 },
        { id: "13", rangeType: 13, minValue: 0, maxValue: 0 },
        { id: "14", rangeType: 14, minValue: 0, maxValue: 0 },
        { id: "15", rangeType: 15, minValue: 0, maxValue: 0 },
      ]);
    }
  }, [systemType, initialValues?.performanceSystemType, okrWeighting, initialValues]);

  const handleOkrWeightingChange = (value: DataItem) => {
    
    setOkrWeighting(value);
    if (!initialValues) {
      if (value.id === OKRWeightId) {
        setOkrWeightData([
          { id: "4", rangeType: 4, minValue: 0, maxValue: 0 },
          { id: "5", rangeType: 5, minValue: 0, maxValue: 0 },
          { id: "6", rangeType: 6, minValue: 0, maxValue: 0 },
          { id: "7", rangeType: 7, minValue: 0, maxValue: 0 },
        ]);
      } else {
        setOkrWeightData([]);
      }
    }
  };


  return (
    <>
      {(systemType === 1 || initialValues?.performanceSystemType === PerformanceSystemTypeIndex.OKR) &&
        <>
          <FormItemCombobox name="okrShape" label="OKR Şekli" datas={okrShapes} />
          <FormItemCombobox name="okrRelation" label="OKR İlişkisi" datas={okrRelations} />
          <FormItemCombobox name="okrTypes" label="OKR Tipleri" datas={okrTypes} multiSelectEnabled />
          <FormItemCombobox name="okrWeighting" label="OKR Ağırlığı" datas={okrWeightings} onChange={e => handleOkrWeightingChange(e)} />
          <FormItemCombobox name="okrPrivacy" label="OKR Gizliliği" datas={okrPrivacies} />
          <FormItemCombobox name="okrTransparency" label="OKR Şeffaflığı" datas={okrTransparencies} />
          <FormItemCombobox name="okrProgression" label="OKR İlerletme" datas={okrProgressions} />
        </>
      }
      {(systemType === 2 || initialValues?.performanceSystemType === PerformanceSystemTypeIndex.KPI) &&
        <>
          <FormItemCombobox name="kpiRelation" label="Stratejik Hedeflerle İlişkisi" datas={kpiRelations} />
          <FormItemCombobox name="kpiGoalTypes" label="Hedef Tipleri" datas={kpiGoalTypes} multiSelectEnabled />
          <FormItemCombobox name="kpiCompentencySelection" label="Yetkinlik Seçimi" datas={kpiCompentencySelections} />
          <FormItemCombobox name="kpiGoalCalculationType" label="Hedef Ölçüm Tipi" datas={kpiGoalCalculationTypes} />
          <FormItemCombobox name="kpiGoalCalculationSource" label="Hedef Ölçüm Kaynağı" datas={kpiGoalCalculationSource} multiSelectEnabled />
        </>
      }
      {okrWeightData &&
        <>
          <PerformanceSystemTables
            showOkrWeightTable={(okrWeightData.length != 0) || (okrWeighting?.id === OKRWeightId)}
            okrWeightData={okrWeightData}
            okrActionData={okrActionData}
            kpiActionData={kpiActionData}
            kpiWeightData={kpiWeightData}
            initialValues={initialValues}
            parameters={parameters ?? []}
            userRoles={userRoles}
            systemType={systemType}
          />
        </>
      }
    </>
  );
};

export default PerformanceSystemForm;
