import React from 'react';

export interface IMCompanyGroupLicence {

}

const MCompanyGroupLicence: React.FC<IMCompanyGroupLicence> = ({}) => {
    return (<>
        <h2>Licence Management</h2>

    </>)
};

export default MCompanyGroupLicence;