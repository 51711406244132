import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiTenantGetTenantInfo: build.query<
      GetApiTenantGetTenantInfoApiResponse,
      GetApiTenantGetTenantInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Tenant/GetTenantInfo`,
        params: { companyId: queryArg.companyId },
      }),
    }),
    getApiTenantClearCache: build.query<
      GetApiTenantClearCacheApiResponse,
      GetApiTenantClearCacheApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Tenant/ClearCache`,
        params: { companyId: queryArg.companyId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiTenantGetTenantInfoApiResponse = unknown;
export type GetApiTenantGetTenantInfoApiArg = {
  companyId?: string;
};
export type GetApiTenantClearCacheApiResponse = unknown;
export type GetApiTenantClearCacheApiArg = {
  companyId?: string;
};
export const {
  useGetApiTenantGetTenantInfoQuery,
  useLazyGetApiTenantGetTenantInfoQuery,
  useGetApiTenantClearCacheQuery,
  useLazyGetApiTenantClearCacheQuery,
} = injectedRtkApi;
