import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiMEvaluation: build.query<
      GetApiMEvaluationApiResponse,
      GetApiMEvaluationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MEvaluation`,
        params: { periodId: queryArg.periodId, companyId: queryArg.companyId },
      }),
    }),
    deleteApiMEvaluation: build.mutation<
      DeleteApiMEvaluationApiResponse,
      DeleteApiMEvaluationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MEvaluation`,
        method: "DELETE",
        params: { managerId: queryArg.managerId, periodId: queryArg.periodId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiMEvaluationApiResponse = unknown;
export type GetApiMEvaluationApiArg = {
  periodId?: string;
  companyId?: string;
};
export type DeleteApiMEvaluationApiResponse = unknown;
export type DeleteApiMEvaluationApiArg = {
  managerId?: string;
  periodId?: string;
};
export const {
  useGetApiMEvaluationQuery,
  useLazyGetApiMEvaluationQuery,
  useDeleteApiMEvaluationMutation,
} = injectedRtkApi;
