import React, { useEffect } from 'react';
import { Table, Button, Modal, TableProps } from 'antd';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { UserDto } from '../../api/services/user';
import { t } from 'i18next';

const { confirm } = Modal;

interface UserTableProps {
    users: UserDto[];
    handleEdit: (user: UserDto) => void;
    handleDelete: (id: string) => void;
    onFilter?: (users: UserDto[]) => void;
}

const UserTable: React.FC<UserTableProps> = ({ users, handleEdit, handleDelete, onFilter }) => {
    const showDeleteConfirm = (id: string) => {
        confirm({
            title: t('are-you-sure-delete-this-user'),
            icon: <ExclamationCircleOutlined />,
            content: t('this-action-cannot-be-undone'),
            okText: t('yes'),
            okType: 'danger',
            cancelText: t('no'),
            onOk() {
                handleDelete(id);
            },
        });
    };

    useEffect(() => {
        if (users && onFilter) {
            onFilter(users)
        }
    }, [users])


    interface SelectOption<V> {
        text: string;
        value: V;
    }

    const isDate = (text: string) => {
        if (text.includes("-"))
            return moment(text).isValid()
        return false;
    }

    function getDistinctOptions<T, V>(
        list: T[],
        valueSelector: (item: T) => V | null | undefined,
        textSelector?: (item: T) => string | null | undefined
    ): SelectOption<V>[] {
        const valuesMap = new Map<V, string>();
        for (const item of list) {
            const value = valueSelector(item);
            if (value !== undefined && value !== null) {
                const text = textSelector ? textSelector(item) : String(value);
                if (isDate(text ?? "")) {
                    valuesMap.set(value, moment(text).format('DD/MM/YYYY'));
                } else {
                    valuesMap.set(value, text ?? "");
                }

            }
        }
        return Array.from(valuesMap.entries()).map(([value, text]) => ({ value, text }));
    }


    const columns = [
        // {
        //     title: t('company'),
        //     dataIndex: 'company.name',
        //     key: 'company',
        //     render: (text: string, record: any) => {
        //         return record.company.name
        //     },
        //     filters: getDistinctOptions(users, k => k.company?.name),
        //     filterMode: 'tree',
        //     filterSearch: true,
        //     onFilter: (value: any, record: UserDto) => record.company?.name?.startsWith(value as string),
        // },
        {
            title: t('employee-number'),
            dataIndex: 'employeeNumber',
            key: 'employeeNumber',
            filters: getDistinctOptions(users, k => k.employeeNumber),
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value: any, record: UserDto) => record.employeeNumber?.startsWith(value as string),
        },
        {
            title: t('first-name'),
            dataIndex: 'firstName',
            key: 'firstName',
            filters: getDistinctOptions(users, k => k.firstName),
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value: any, record: UserDto) => record.userName?.startsWith(value as string),
        },
        {
            title: t('last-name'),
            dataIndex: 'lastName',
            key: 'lastName',
            filters: getDistinctOptions(users, k => k.lastName),
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value: any, record: UserDto) => record.lastName?.startsWith(value as string),
        },
        {
            title: t('title'),
            dataIndex: 'title',
            key: 'title',
            filters: getDistinctOptions(users, k => k.title),
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value: any, record: UserDto) => record.title?.startsWith(value as string),
        },
        {
            title: t('email'),
            dataIndex: 'email',
            key: 'email',
            filters: getDistinctOptions(users, k => k.email),
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value: any, record: UserDto) => record.email?.startsWith(value as string),
        },
        {
            title: t('phone'),
            dataIndex: 'phone',
            key: 'phone',
            filters: getDistinctOptions(users, k => k.phone),
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value: any, record: UserDto) => record.phone?.startsWith(value as string),
        },
        {
            title: t('department'),
            dataIndex: 'department',
            key: 'department',
            filters: getDistinctOptions(users, k => k.department),
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value: any, record: UserDto) => record.department?.startsWith(value as string),
        },
        {
            title: t('work-location'),
            dataIndex: 'workLocation',
            key: 'workLocation',
            filters: getDistinctOptions(users, k => k.workLocation),
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value: any, record: UserDto) => record.workLocation?.startsWith(value as string),
        },
        {
            title: t('start-date'),
            dataIndex: 'startDate',
            key: 'startDate',
            render: (text: string) => moment(text).format('DD/MM/YYYY'),
            filters: getDistinctOptions(users, k => k.startDate),
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value: any, record: UserDto) => record.startDate?.startsWith(value as string),
        },
        {
            title: "Yönetici Adı",
            dataIndex: 'managerName',
            key: 'managerName',
            filters: getDistinctOptions(users, k => k.managerName),
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value: any, record: UserDto) => record.managerName?.startsWith(value as string),
        },
        {
            title: "Yönetici Soyadı",
            dataIndex: 'managerSurname',
            key: 'managerSurname',
            filters: getDistinctOptions(users, k => k.managerSurname),
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value: any, record: UserDto) => record.managerSurname?.startsWith(value as string),
        },
        {
            title: t('actions'),
            key: 'actions',
            render: (text: any, record: UserDto) => (
                <div>
                    <Button type="link" danger onClick={() => showDeleteConfirm(record.id ?? "")}>
                        Delete
                    </Button>
                </div>
            ),
        },
    ] as any;

    const onChange: TableProps<UserDto>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
        if (extra.action == "filter") {
            if (onFilter) {
                onFilter(extra.currentDataSource);
            }
        }
    };

    return (
        <Table
            dataSource={users}
            columns={columns}
            rowKey="id"
            onChange={onChange}
            onRow={(record) => {
                return {
                    onClick: () => handleEdit(record)
                };
            }}
        />
    );
};

export default UserTable;
