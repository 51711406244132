import React, {useEffect, useState} from 'react';
import {Modal, Form, Select, DatePicker, Input, Button} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {ParameterType, usePostApiParameterGetParametersMutation} from "../../api/services/parameters";
import {setError, setLoading} from "../../features/app/appSlice";
import {extractParam} from "../../helper/paramHelper";
import {t} from "i18next";
import {DataItem} from "../../models/DataItem";
import {PeriodDto, useLazyGetApiPeriodGetActivePeriodsQuery} from "../../api/services/period";
import {useLazyGetApiUserTeamMembersQuery, UserDto} from "../../api/services/user";
import {
    useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery
} from "../../api/services/performanceCards";
import {usePostApiOneToOneMeetingAddMeetingMutation} from "../../api/services/OneToOneMeeting";

const {Option} = Select;
const {TextArea} = Input;

interface OneOnOnePopupProps {
    visible: boolean;
    onClose: () => void;
    onSave: () => void;
    toManager: boolean
}

const OneOnOnePopup: React.FC<OneOnOnePopupProps> = ({visible, onClose, onSave, toManager}) => {
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch()
    const [getParameters, {data: parameters}] = usePostApiParameterGetParametersMutation()
    const [fetchActivePeriods, {data: activePeriods}] = useLazyGetApiPeriodGetActivePeriodsQuery()
    const [getTeamUsers, {data: teamUsers}] = useLazyGetApiUserTeamMembersQuery()
    const [fetchActivePerformance, {data: activePerformance}] = useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery()
    const [OKRMeetingName, setOKRMeetingName] = useState<DataItem[]>([])
    const [KPIMeetingName, setKPIMeetingName] = useState<DataItem[]>([])
    const [MeetingKind, setMeetingKind] = useState<DataItem[]>([])
    const [selectedMember, setSelectedMember] = useState<string | null>(null);
    const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);
    const [saveMeetingOnServer] = usePostApiOneToOneMeetingAddMeetingMutation()

    useEffect(() => {
        if (user) {
            getActivePeriods()
            FetcParams()
            FetchTeamUsers()
        }
    }, [user])

    const FetcParams = async () => {
        try {
            dispatch(setLoading(true));
            var parameters = await getParameters({
                companyId: user?.companyId ?? "",
                body: [
                    21 as ParameterType, //OKR Meeting Name
                    // 22 as ParameterType, //KPI Meeting Name
                    23 as ParameterType, //Meeting Kind
                ]
            }).unwrap()
            setOKRMeetingName(extractParam(parameters, 21))
            // setKPIMeetingName(extractParam(parameters, 22))
            setMeetingKind(extractParam(parameters, 23))
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Parametreler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const FetchTeamUsers = async () => {
        try {
            dispatch(setLoading(true));
            await getTeamUsers().unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Kullanıcılar çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getActivePeriods = async () => {
        try {
            dispatch(setLoading(true));
            await fetchActivePeriods().unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getActivePerformance = async (employeeId: string, periodId: string) => {
        if (!employeeId || !periodId)
            return

        try {
            dispatch(setLoading(true));
            await fetchActivePerformance({
                employeeId: employeeId,
                periodId: periodId
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Performans sistemleri sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const saveMeeting = async (values: any) => {
        var managerId = toManager ? user?.managerId : user?.id
        
        try {
            dispatch(setLoading(true));
            await saveMeetingOnServer({
                saveOneToOneMeetingDto: {
                    periodId: values.period,
                    employeeId: toManager ? user?.id : values.personel,
                    meetingTypeId: values.type,
                    meetingNameId: values.meetingName,
                    meetingDate: values.date,
                    comment: values.comment,
                },
                managerId: managerId,
                userId: user?.id
            }).unwrap()

            onSave();
            onClose();
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Görüşme kaydedilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleFinish = (values: any) => {
        saveMeeting(values);
    };

    const handleMemberChange = (value: string) => {
        setSelectedMember(value);
        getActivePerformance(value, selectedPeriod ?? "")
    };

    const handlePeriodChange = (value: string) => {
        setSelectedPeriod(value);
        getActivePerformance(selectedMember ?? "", value)
    };

    const getMeetingName = () => {
        return OKRMeetingName
        // if (activePerformance && activePerformance.length > 0) {
        //     if (activePerformance[0].performanceSystemType == "OKR") {
        //         return OKRMeetingName
        //     } else if (activePerformance[0].performanceSystemType == "KPI") {
        //         return KPIMeetingName
        //     }
        // } else {
        //     return OKRMeetingName
        // }
    }
    return (
        <Modal visible={visible} title="1-1 Görüşme Başlat" footer={null} onCancel={onClose} destroyOnClose={true}>
            <Form layout="vertical"
                  onFinish={handleFinish}
                  initialValues={{
                      managerName: `${user?.managerName} ${user?.managerSurname}`
                  }}>
                {!toManager &&
                    <Form.Item name="personel" label="Ekip Üyesi"
                               rules={[{required: true, message: 'Ekip üyesi seçin!'}]}>
                        <Select
                            placeholder="Ekip üyesi seçiniz"
                            onChange={handleMemberChange}
                            value={selectedMember || undefined} // Seçili eleman
                            showSearch={true}
                            filterOption={(input, option) =>
                                `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                            }
                            options={((teamUsers ?? []) as UserDto[]).map(k => {
                                return {value: k.id, label: `${k.firstName} ${k.lastName} ${k.title ? "(" + k.title + ")" : ""}`};
                                }
                            )}
                        />
                    </Form.Item>
                }
                {toManager &&
                    <Form.Item label="Yönetici" name="managerName">
                        <input disabled={true} className={"h-[35px] w-full rounded-md border-[2px] p-2 text-gray-400"}/>
                    </Form.Item>
                }
                <Form.Item name="period" label="Dönem" rules={[{required: true, message: 'Dönem seçiniz!'}]}>
                    <Select placeholder="Dönem seçiniz"
                            onChange={handlePeriodChange}
                            showSearch={true}
                            filterOption={(input, option) =>
                                `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                            }
                            options={((activePeriods ?? []) as PeriodDto[]).map(k => {
                                    return {value: k.id, label: `${k.periodName}`};
                                }
                            )}
                    />
                </Form.Item>
                <Form.Item name="meetingName" label="Görüşme Adı"
                           rules={[{required: true, message: 'Görüşme adı seçiniz!'}]}>
                    <Select placeholder="Görüşme adı seçiniz"
                            showSearch={true}
                            filterOption={(input, option) =>
                                `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                            }
                            options={((getMeetingName() ?? []) as DataItem[]).map(k => {
                                    return {value: k.id, label: `${k.name}`};
                                }
                            )}
                    />
                </Form.Item>
                <Form.Item name="type" label="Görüşme Şekli"
                           rules={[{required: true, message: 'Görüşme şekli seçiniz!'}]}>
                    <Select placeholder="Görüşme şekli seçiniz"
                            showSearch={true}
                            filterOption={(input, option) =>
                                `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                            }
                            options={((MeetingKind ?? []) as DataItem[]).map(k => {
                                    return {value: k.id, label: `${k.name}`};
                                }
                            )}
                    />
                </Form.Item>
                <Form.Item name="date" label="Tarih" rules={[{required: true, message: 'Tarih seçiniz!'}]}>
                    <DatePicker style={{width: '100%'}}/>
                </Form.Item>
                <Form.Item name="comment" label="Görüşme Yorumları">
                    <TextArea rows={4} placeholder="Yorumlarınızı giriniz"/>
                </Form.Item>
                <div style={{textAlign: 'right'}}>
                    <Button onClick={onClose} style={{marginRight: 8}}>
                        İptal
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Kaydet
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default OneOnOnePopup;
