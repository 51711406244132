import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCompany: build.query<GetApiCompanyApiResponse, GetApiCompanyApiArg>({
      query: () => ({ url: `/api/Company` }),
    }),
    postApiCompany: build.mutation<
      PostApiCompanyApiResponse,
      PostApiCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Company`,
        method: "POST",
        body: queryArg.createCompanyDto,
      }),
    }),
    getApiCompanyById: build.query<
      GetApiCompanyByIdApiResponse,
      GetApiCompanyByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Company/${queryArg.id}` }),
    }),
    putApiCompanyById: build.mutation<
      PutApiCompanyByIdApiResponse,
      PutApiCompanyByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Company/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateCompanyDto,
      }),
    }),
    deleteApiCompanyById: build.mutation<
      DeleteApiCompanyByIdApiResponse,
      DeleteApiCompanyByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Company/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiCompanyByGroupByGroupId: build.query<
      GetApiCompanyByGroupByGroupIdApiResponse,
      GetApiCompanyByGroupByGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Company/byGroup/${queryArg.groupId}`,
      }),
    }),
    getApiCompanySyncUsersFromSystem: build.query<
      GetApiCompanySyncUsersFromSystemApiResponse,
      GetApiCompanySyncUsersFromSystemApiArg
    >({
      query: () => ({ url: `/api/Company/syncUsersFromSystem` }),
    }),
    getApiCompanyGroup: build.query<
      GetApiCompanyGroupApiResponse,
      GetApiCompanyGroupApiArg
    >({
      query: () => ({ url: `/api/CompanyGroup` }),
    }),
    postApiCompanyGroup: build.mutation<
      PostApiCompanyGroupApiResponse,
      PostApiCompanyGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompanyGroup`,
        method: "POST",
        body: queryArg.createCompanyGroupDto,
      }),
    }),
    getApiCompanyGroupById: build.query<
      GetApiCompanyGroupByIdApiResponse,
      GetApiCompanyGroupByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/CompanyGroup/${queryArg.id}` }),
    }),
    putApiCompanyGroupById: build.mutation<
      PutApiCompanyGroupByIdApiResponse,
      PutApiCompanyGroupByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompanyGroup/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateCompanyGroupDto,
      }),
    }),
    deleteApiCompanyGroupById: build.mutation<
      DeleteApiCompanyGroupByIdApiResponse,
      DeleteApiCompanyGroupByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompanyGroup/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiDepartmentOkrGetDepartmentOkRsByCompanyGroupId: build.query<
      GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiResponse,
      GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DepartmentOKR/GetDepartmentOKRsByCompanyGroupId`,
        params: { companyId: queryArg.companyId },
      }),
    }),
    getApiDialogParametersByCompanyGroupId: build.query<
      GetApiDialogParametersByCompanyGroupIdApiResponse,
      GetApiDialogParametersByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Dialog/parameters/${queryArg.companyGroupId}`,
      }),
    }),
    getApiMCompanyCompanies: build.query<
      GetApiMCompanyCompaniesApiResponse,
      GetApiMCompanyCompaniesApiArg
    >({
      query: () => ({ url: `/api/MCompany/companies` }),
    }),
    postApiMCompanyCompanies: build.mutation<
      PostApiMCompanyCompaniesApiResponse,
      PostApiMCompanyCompaniesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/companies`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getApiMCompanyCompaniesByGroup: build.query<
      GetApiMCompanyCompaniesByGroupApiResponse,
      GetApiMCompanyCompaniesByGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/companies/byGroup`,
        params: { groupId: queryArg.groupId },
      }),
    }),
    getApiMCompanyCompaniesById: build.query<
      GetApiMCompanyCompaniesByIdApiResponse,
      GetApiMCompanyCompaniesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/MCompany/companies/${queryArg.id}` }),
    }),
    putApiMCompanyCompaniesById: build.mutation<
      PutApiMCompanyCompaniesByIdApiResponse,
      PutApiMCompanyCompaniesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/companies/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteApiMCompanyCompaniesById: build.mutation<
      DeleteApiMCompanyCompaniesByIdApiResponse,
      DeleteApiMCompanyCompaniesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/companies/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    postApiMCompanyUpdateadminpassword: build.mutation<
      PostApiMCompanyUpdateadminpasswordApiResponse,
      PostApiMCompanyUpdateadminpasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/updateadminpassword`,
        method: "POST",
        params: { companyId: queryArg.companyId },
      }),
    }),
    postApiMCompanySetting: build.mutation<
      PostApiMCompanySettingApiResponse,
      PostApiMCompanySettingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/setting`,
        method: "POST",
        body: queryArg.companySettingDto,
      }),
    }),
    postApiMCompanySettings: build.mutation<
      PostApiMCompanySettingsApiResponse,
      PostApiMCompanySettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/settings`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getApiMCompanySettingsByCompanyId: build.query<
      GetApiMCompanySettingsByCompanyIdApiResponse,
      GetApiMCompanySettingsByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/settings/${queryArg.companyId}`,
        params: { keys: queryArg.keys },
      }),
    }),
    getApiMCompanyGroupsCompanygroups: build.query<
      GetApiMCompanyGroupsCompanygroupsApiResponse,
      GetApiMCompanyGroupsCompanygroupsApiArg
    >({
      query: () => ({ url: `/api/MCompanyGroups/companygroups` }),
    }),
    postApiMCompanyGroupsCompanygroups: build.mutation<
      PostApiMCompanyGroupsCompanygroupsApiResponse,
      PostApiMCompanyGroupsCompanygroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompanyGroups/companygroups`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getApiMCompanyGroupsCompanygroupsById: build.query<
      GetApiMCompanyGroupsCompanygroupsByIdApiResponse,
      GetApiMCompanyGroupsCompanygroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompanyGroups/companygroups/${queryArg.id}`,
      }),
    }),
    putApiMCompanyGroupsCompanygroupsById: build.mutation<
      PutApiMCompanyGroupsCompanygroupsByIdApiResponse,
      PutApiMCompanyGroupsCompanygroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompanyGroups/companygroups/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteApiMCompanyGroupsCompanygroupsById: build.mutation<
      DeleteApiMCompanyGroupsCompanygroupsByIdApiResponse,
      DeleteApiMCompanyGroupsCompanygroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompanyGroups/companygroups/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiPerformanceGoalGetPerformanceGoalsByCompanyGroupId: build.query<
      GetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdApiResponse,
      GetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceGoal/GetPerformanceGoalsByCompanyGroupId`,
        params: { companyGroupId: queryArg.companyGroupId },
      }),
    }),
    getApiRolePermissionRolesWithPermissionsByCompanyId: build.query<
      GetApiRolePermissionRolesWithPermissionsByCompanyIdApiResponse,
      GetApiRolePermissionRolesWithPermissionsByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles-with-permissions/${queryArg.companyId}`,
      }),
    }),
    postApiRolePermissionAssignUsersToRoleByCompanyId: build.mutation<
      PostApiRolePermissionAssignUsersToRoleByCompanyIdApiResponse,
      PostApiRolePermissionAssignUsersToRoleByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/assign-users-to-role/${queryArg.companyId}`,
        method: "POST",
        body: queryArg.roleAssignmentDto,
      }),
    }),
    getApiRolePermissionRolesWithUsersByCompanyId: build.query<
      GetApiRolePermissionRolesWithUsersByCompanyIdApiResponse,
      GetApiRolePermissionRolesWithUsersByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles-with-users/${queryArg.companyId}`,
      }),
    }),
    getApiStrategicOkrGetStrategicOkRsByCompanyGroupId: build.query<
      GetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdApiResponse,
      GetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/StrategicOKR/GetStrategicOKRsByCompanyGroupId`,
        params: { companyId: queryArg.companyId },
      }),
    }),
    getApiTargetAudienceCompanyTargetAudiences: build.query<
      GetApiTargetAudienceCompanyTargetAudiencesApiResponse,
      GetApiTargetAudienceCompanyTargetAudiencesApiArg
    >({
      query: () => ({ url: `/api/TargetAudience/company/target-audiences` }),
    }),
    getApiUserByCompanyByCompanyId: build.query<
      GetApiUserByCompanyByCompanyIdApiResponse,
      GetApiUserByCompanyByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/byCompany/${queryArg.companyId}`,
      }),
    }),
    getApiUserByCompanyGroupByCompanyGroupId: build.query<
      GetApiUserByCompanyGroupByCompanyGroupIdApiResponse,
      GetApiUserByCompanyGroupByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/byCompanyGroup/${queryArg.companyGroupId}`,
      }),
    }),
    postApiUserGetUserParamsByCompanyGroupId: build.mutation<
      PostApiUserGetUserParamsByCompanyGroupIdApiResponse,
      PostApiUserGetUserParamsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/get-user-params/${queryArg.companyGroupId}`,
        method: "POST",
      }),
    }),
    postApiUserUpdateUserParamsByCompanyGroupId: build.mutation<
      PostApiUserUpdateUserParamsByCompanyGroupIdApiResponse,
      PostApiUserUpdateUserParamsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/update-user-params/${queryArg.companyGroupId}`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiCompanyApiResponse = /** status 200 OK */ CompanyDto[];
export type GetApiCompanyApiArg = void;
export type PostApiCompanyApiResponse = /** status 200 OK */ CompanyDto;
export type PostApiCompanyApiArg = {
  createCompanyDto: CreateCompanyDto;
};
export type GetApiCompanyByIdApiResponse = /** status 200 OK */ CompanyDto;
export type GetApiCompanyByIdApiArg = {
  id: string;
};
export type PutApiCompanyByIdApiResponse = unknown;
export type PutApiCompanyByIdApiArg = {
  id: string;
  updateCompanyDto: UpdateCompanyDto;
};
export type DeleteApiCompanyByIdApiResponse = unknown;
export type DeleteApiCompanyByIdApiArg = {
  id: string;
};
export type GetApiCompanyByGroupByGroupIdApiResponse =
  /** status 200 OK */ CompanyDto[];
export type GetApiCompanyByGroupByGroupIdApiArg = {
  groupId: string;
};
export type GetApiCompanySyncUsersFromSystemApiResponse = unknown;
export type GetApiCompanySyncUsersFromSystemApiArg = void;
export type GetApiCompanyGroupApiResponse =
  /** status 200 OK */ CompanyGroupDto[];
export type GetApiCompanyGroupApiArg = void;
export type PostApiCompanyGroupApiResponse =
  /** status 200 OK */ CompanyGroupDto;
export type PostApiCompanyGroupApiArg = {
  createCompanyGroupDto: CreateCompanyGroupDto;
};
export type GetApiCompanyGroupByIdApiResponse =
  /** status 200 OK */ CompanyGroupDto;
export type GetApiCompanyGroupByIdApiArg = {
  id: string;
};
export type PutApiCompanyGroupByIdApiResponse = unknown;
export type PutApiCompanyGroupByIdApiArg = {
  id: string;
  updateCompanyGroupDto: UpdateCompanyGroupDto;
};
export type DeleteApiCompanyGroupByIdApiResponse = unknown;
export type DeleteApiCompanyGroupByIdApiArg = {
  id: string;
};
export type GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiResponse =
  /** status 200 OK */ DepartmentOkrMasterDto[];
export type GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiArg = {
  companyId?: string;
};
export type GetApiDialogParametersByCompanyGroupIdApiResponse = unknown;
export type GetApiDialogParametersByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type GetApiMCompanyCompaniesApiResponse = unknown;
export type GetApiMCompanyCompaniesApiArg = void;
export type PostApiMCompanyCompaniesApiResponse = unknown;
export type PostApiMCompanyCompaniesApiArg = {
  body: {
    Name?: string;
    Address?: string;
    Email?: string;
    BaseUrl?: string;
    StartDate?: string;
    EndDate?: string;
    UserSyncUrl?: string;
    CompanyGroupId?: string;
    Favicon?: Blob;
    LoginIcon?: Blob;
    Logo?: Blob;
  };
};
export type GetApiMCompanyCompaniesByGroupApiResponse = unknown;
export type GetApiMCompanyCompaniesByGroupApiArg = {
  groupId?: string;
};
export type GetApiMCompanyCompaniesByIdApiResponse = unknown;
export type GetApiMCompanyCompaniesByIdApiArg = {
  id: string;
};
export type PutApiMCompanyCompaniesByIdApiResponse = unknown;
export type PutApiMCompanyCompaniesByIdApiArg = {
  id: string;
  body: {
    Name?: string;
    Address?: string;
    Email?: string;
    BaseUrl?: string;
    StartDate?: string;
    EndDate?: string;
    UserSyncUrl?: string;
    CompanyGroupId?: string;
    Favicon?: Blob;
    LoginIcon?: Blob;
    Logo?: Blob;
  };
};
export type DeleteApiMCompanyCompaniesByIdApiResponse = unknown;
export type DeleteApiMCompanyCompaniesByIdApiArg = {
  id: string;
};
export type PostApiMCompanyUpdateadminpasswordApiResponse = unknown;
export type PostApiMCompanyUpdateadminpasswordApiArg = {
  companyId?: string;
};
export type PostApiMCompanySettingApiResponse = unknown;
export type PostApiMCompanySettingApiArg = {
  companySettingDto: CompanySettingDto;
};
export type PostApiMCompanySettingsApiResponse = unknown;
export type PostApiMCompanySettingsApiArg = {
  body: CompanySettingDto[];
};
export type GetApiMCompanySettingsByCompanyIdApiResponse = unknown;
export type GetApiMCompanySettingsByCompanyIdApiArg = {
  companyId: string;
  keys?: string[];
};
export type GetApiMCompanyGroupsCompanygroupsApiResponse = unknown;
export type GetApiMCompanyGroupsCompanygroupsApiArg = void;
export type PostApiMCompanyGroupsCompanygroupsApiResponse = unknown;
export type PostApiMCompanyGroupsCompanygroupsApiArg = {
  body: {
    GroupName?: string;
    Nickname?: string;
    StartDate?: string;
    EndDate?: string;
    Favicon?: Blob;
    LoginIcon?: Blob;
    Logo?: Blob;
  };
};
export type GetApiMCompanyGroupsCompanygroupsByIdApiResponse = unknown;
export type GetApiMCompanyGroupsCompanygroupsByIdApiArg = {
  id: string;
};
export type PutApiMCompanyGroupsCompanygroupsByIdApiResponse = unknown;
export type PutApiMCompanyGroupsCompanygroupsByIdApiArg = {
  id: string;
  body: {
    GroupName?: string;
    Nickname?: string;
    StartDate?: string;
    EndDate?: string;
    Favicon?: Blob;
    LoginIcon?: Blob;
    Logo?: Blob;
  };
};
export type DeleteApiMCompanyGroupsCompanygroupsByIdApiResponse = unknown;
export type DeleteApiMCompanyGroupsCompanygroupsByIdApiArg = {
  id: string;
};
export type GetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdApiResponse =
  /** status 200 OK */ PerformanceGoalDto[];
export type GetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdApiArg = {
  companyGroupId?: string;
};
export type GetApiRolePermissionRolesWithPermissionsByCompanyIdApiResponse =
  /** status 200 OK */ RoleWithPermissionsDto[];
export type GetApiRolePermissionRolesWithPermissionsByCompanyIdApiArg = {
  companyId: string;
};
export type PostApiRolePermissionAssignUsersToRoleByCompanyIdApiResponse =
  unknown;
export type PostApiRolePermissionAssignUsersToRoleByCompanyIdApiArg = {
  companyId: string;
  roleAssignmentDto: RoleAssignmentDto;
};
export type GetApiRolePermissionRolesWithUsersByCompanyIdApiResponse =
  /** status 200 OK */ RoleWithUsersDto[];
export type GetApiRolePermissionRolesWithUsersByCompanyIdApiArg = {
  companyId: string;
};
export type GetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdApiResponse =
  /** status 200 OK */ StrategicOkrMasterDto[];
export type GetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdApiArg = {
  companyId?: string;
};
export type GetApiTargetAudienceCompanyTargetAudiencesApiResponse =
  /** status 200 OK */ TargetAudienceDto[];
export type GetApiTargetAudienceCompanyTargetAudiencesApiArg = void;
export type GetApiUserByCompanyByCompanyIdApiResponse =
  /** status 200 OK */ UserDto[];
export type GetApiUserByCompanyByCompanyIdApiArg = {
  companyId: string;
};
export type GetApiUserByCompanyGroupByCompanyGroupIdApiResponse =
  /** status 200 OK */ UserDto[];
export type GetApiUserByCompanyGroupByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type PostApiUserGetUserParamsByCompanyGroupIdApiResponse = unknown;
export type PostApiUserGetUserParamsByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type PostApiUserUpdateUserParamsByCompanyGroupIdApiResponse = unknown;
export type PostApiUserUpdateUserParamsByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type CompanyGroupDto = {
  id?: string;
  groupName?: string | null;
};
export type CompanyDto = {
  id?: string;
  name?: string | null;
  address?: string | null;
  email?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  logo?: string | null;
  faviconName?: string | null;
  loginIcon?: string | null;
  companyGroupId?: string;
  companyGroup?: CompanyGroupDto;
  adminPass?: string | null;
};
export type CreateCompanyDto = {
  name?: string | null;
  address?: string | null;
  email?: string | null;
  baseUrl?: string | null;
  startDate?: string;
  endDate?: string | null;
  userSyncUrl?: string | null;
  companyGroupId?: string;
  favicon?: Blob | null;
  loginIcon?: Blob | null;
  logo?: Blob | null;
};
export type UpdateCompanyDto = {
  name?: string | null;
  address?: string | null;
  email?: string | null;
  companyGroupId?: string;
};
export type CreateCompanyGroupDto = {
  groupName?: string | null;
  nickname?: string | null;
  startDate?: string;
  endDate?: string | null;
  favicon?: Blob | null;
  loginIcon?: Blob | null;
  logo?: Blob | null;
};
export type UpdateCompanyGroupDto = {
  groupName?: string | null;
};
export type DepartmentOkrDetailDto = {
  id?: string;
  strategicOKRDetailId?: string;
  departmentOKR?: string | null;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: string | null;
  isDeletable?: boolean;
};
export type PeriodDto = {
  id?: string | null;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  startDateFirstManager?: string;
  endDateFirstManager?: string;
  startDateFirstCalibration?: string;
  endDateFirstCalibration?: string;
  startDateSecondCalibration?: string;
  endDateSecondCalibration?: string;
  companyGroupId?: string;
};
export type StrategicOkrDetailDto = {
  id?: string;
  strategicOKR?: string | null;
  strategicOKRId?: string;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: string | null;
  period?: PeriodDto;
};
export type PerformanceSystemType = 1 | 2;
export type OkrDetailDto = {
  id?: string;
  okrShapeId?: string;
  okrRelationshipId?: string;
  okrTypeIds?: string[] | null;
  okrWeightId?: string;
  okrPrivacyId?: string;
  okrTransparencyId?: string;
  okrProgressId?: string;
  minValue?: number;
  maxValue?: number;
};
export type KpiDetailDto = {
  id?: string;
  kpiRelationshipId?: string;
  targetTypesIds?: string[] | null;
  compentencyId?: string;
  goalMeasurementTypeId?: string;
  goalTargetMeasurementSourceIds?: string[] | null;
};
export type KpiGoalCompentencyDetailsDto = {
  id?: string;
  performanceSystemId?: string;
  role?: string;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
};
export type ParameterType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;
export type Parameter = {
  id?: string;
  parameterType?: ParameterType;
  companyGroupId?: string;
  companyId?: string;
  parameterGroup?: string | null;
  parameterValue?: string | null;
  parameterCode?: string | null;
};
export type OkrDetail = {
  id?: string;
  performanceSystemId?: string;
  okrShape?: Parameter;
  okrRelationship?: Parameter;
  okrType?: Parameter[] | null;
  okrWeight?: Parameter;
  okrPrivacy?: Parameter;
  okrTransparency?: Parameter;
  okrProgress?: Parameter;
  performanceSystem?: PerformanceSystem;
};
export type KpiDetail = {
  id?: string;
  performanceSystemId?: string;
  kpiRelationship?: Parameter;
  targetTypes?: Parameter[] | null;
  compentency?: Parameter;
  goalMeasurementType?: Parameter;
  goalTargetMeasurementSource?: Parameter[] | null;
  performanceSystem?: PerformanceSystem;
};
export type KpiGoalCompentencyDetails = {
  id?: string;
  performanceSystemId?: string;
  role?: Parameter;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
  performanceSystem?: PerformanceSystem;
};
export type RangeType =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;
export type PerformanceSystemRange = {
  id?: string;
  performanceSystemId?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
  performanceSystem?: PerformanceSystem;
};
export type PerformanceSystem = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetail[] | null;
  kpiDetails?: KpiDetail[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetails[] | null;
  performanceSystemRanges?: PerformanceSystemRange[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  companyId?: string;
};
export type Period = {
  id?: string;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  startDateFirstManager?: string;
  endDateFirstManager?: string;
  startDateFirstCalibration?: string;
  endDateFirstCalibration?: string;
  startDateSecondCalibration?: string;
  endDateSecondCalibration?: string;
  companyId?: string;
};
export type StrategicOkrDetail = {
  id?: string;
  strategicOKRMasterId?: string;
  strategicOKRMaster?: StrategicOkrMaster;
  strategicOKR?: string | null;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: string | null;
};
export type StrategicOkrMaster = {
  id?: string;
  companyId?: string;
  performanceSystemId?: string;
  performancePeriodId?: string;
  createdAt?: string;
  performanceSystem?: PerformanceSystem;
  performancePeriod?: Period;
  strategicOKRDetails?: StrategicOkrDetail[] | null;
};
export type PerformanceSystemRangeDto = {
  id?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
};
export type PerformanceSystemDto = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetailDto[] | null;
  kpiDetails?: KpiDetailDto[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetailsDto[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  performanceSystemRanges?: PerformanceSystemRangeDto[] | null;
  companyId?: string;
};
export type DepartmentOkrMasterDto = {
  id?: string;
  strategicOKRId?: string;
  companyId?: string;
  functionId?: string;
  createdAt?: string;
  okrDetails?: DepartmentOkrDetailDto[] | null;
  strategicOKRDetails?: StrategicOkrDetailDto[] | null;
  performanceSystem?: PerformanceSystemDto;
  isDeletable?: boolean;
};
export type CompanySettingDto = {
  companyId?: string;
  key?: string | null;
  value?: string | null;
  exp?: string | null;
};
export type PerformanceGoalDetailDto = {
  id?: string;
  strategicGoal?: string | null;
  kpi?: string | null;
  weight?: number;
  source?: string;
  minResult?: number;
  targetResult?: number;
  maxResult?: number;
  createdAt?: string;
};
export type PerformanceGoalDto = {
  id?: string;
  companyGroupId?: string;
  periodId?: string;
  createdAt?: string;
  period?: PeriodDto;
  goalDetails?: PerformanceGoalDetailDto[] | null;
};
export type PermissionDto = {
  id?: string;
  name?: string | null;
};
export type RoleWithPermissionsDto = {
  roleId?: string;
  roleName?: string | null;
  permissions?: PermissionDto[] | null;
};
export type FilterDto = {
  action?: string | null;
  variable?: string | null;
  value?: string[] | null;
};
export type RoleAssignmentDto = {
  roleId?: string;
  filters?: FilterDto[] | null;
};
export type RoleWithUsersDto = {
  roleId?: string;
  roleName?: string | null;
  userCount?: number;
  formula?: FilterDto[] | null;
};
export type StrategicOkrMasterDto = {
  id?: string;
  companyId?: string;
  performanceSystem?: PerformanceSystemDto;
  period?: PeriodDto;
  createdAt?: string;
  deletable?: boolean;
  okrDetails?: StrategicOkrDetailDto[] | null;
};
export type TargetAudienceDto = {
  id?: string | null;
  companyId?: string;
  targetAudienceNo?: string | null;
  targetAudienceName?: string | null;
  filters?: FilterDto[] | null;
};
export type UserDto = {
  id?: string;
  userName?: string | null;
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerId?: string;
  managerNumber?: string | null;
  managerName?: string | null;
  managerSurname?: string | null;
  email?: string | null;
  phone?: string | null;
  company?: CompanyDto;
};
export const {
  useGetApiCompanyQuery,
  useLazyGetApiCompanyQuery,
  usePostApiCompanyMutation,
  useGetApiCompanyByIdQuery,
  useLazyGetApiCompanyByIdQuery,
  usePutApiCompanyByIdMutation,
  useDeleteApiCompanyByIdMutation,
  useGetApiCompanyByGroupByGroupIdQuery,
  useLazyGetApiCompanyByGroupByGroupIdQuery,
  useGetApiCompanySyncUsersFromSystemQuery,
  useLazyGetApiCompanySyncUsersFromSystemQuery,
  useGetApiCompanyGroupQuery,
  useLazyGetApiCompanyGroupQuery,
  usePostApiCompanyGroupMutation,
  useGetApiCompanyGroupByIdQuery,
  useLazyGetApiCompanyGroupByIdQuery,
  usePutApiCompanyGroupByIdMutation,
  useDeleteApiCompanyGroupByIdMutation,
  useGetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdQuery,
  useLazyGetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdQuery,
  useGetApiDialogParametersByCompanyGroupIdQuery,
  useLazyGetApiDialogParametersByCompanyGroupIdQuery,
  useGetApiMCompanyCompaniesQuery,
  useLazyGetApiMCompanyCompaniesQuery,
  usePostApiMCompanyCompaniesMutation,
  useGetApiMCompanyCompaniesByGroupQuery,
  useLazyGetApiMCompanyCompaniesByGroupQuery,
  useGetApiMCompanyCompaniesByIdQuery,
  useLazyGetApiMCompanyCompaniesByIdQuery,
  usePutApiMCompanyCompaniesByIdMutation,
  useDeleteApiMCompanyCompaniesByIdMutation,
  usePostApiMCompanyUpdateadminpasswordMutation,
  usePostApiMCompanySettingMutation,
  usePostApiMCompanySettingsMutation,
  useGetApiMCompanySettingsByCompanyIdQuery,
  useLazyGetApiMCompanySettingsByCompanyIdQuery,
  useGetApiMCompanyGroupsCompanygroupsQuery,
  useLazyGetApiMCompanyGroupsCompanygroupsQuery,
  usePostApiMCompanyGroupsCompanygroupsMutation,
  useGetApiMCompanyGroupsCompanygroupsByIdQuery,
  useLazyGetApiMCompanyGroupsCompanygroupsByIdQuery,
  usePutApiMCompanyGroupsCompanygroupsByIdMutation,
  useDeleteApiMCompanyGroupsCompanygroupsByIdMutation,
  useGetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdQuery,
  useLazyGetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdQuery,
  useGetApiRolePermissionRolesWithPermissionsByCompanyIdQuery,
  useLazyGetApiRolePermissionRolesWithPermissionsByCompanyIdQuery,
  usePostApiRolePermissionAssignUsersToRoleByCompanyIdMutation,
  useGetApiRolePermissionRolesWithUsersByCompanyIdQuery,
  useLazyGetApiRolePermissionRolesWithUsersByCompanyIdQuery,
  useGetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdQuery,
  useLazyGetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdQuery,
  useGetApiTargetAudienceCompanyTargetAudiencesQuery,
  useLazyGetApiTargetAudienceCompanyTargetAudiencesQuery,
  useGetApiUserByCompanyByCompanyIdQuery,
  useLazyGetApiUserByCompanyByCompanyIdQuery,
  useGetApiUserByCompanyGroupByCompanyGroupIdQuery,
  useLazyGetApiUserByCompanyGroupByCompanyGroupIdQuery,
  usePostApiUserGetUserParamsByCompanyGroupIdMutation,
  usePostApiUserUpdateUserParamsByCompanyGroupIdMutation,
} = injectedRtkApi;
