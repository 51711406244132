import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Popover, Row, Select, Spin, Statistic} from "antd";
import ReportDetail from "./Components/ReportDetail";
import {
    PeriodDto,
    useLazyGetApiPeriodAllQuery,
    useLazyGetApiPeriodGetActivePeriodsQuery, useLazyGetApiPeriodWithCardsQuery
} from "../../api/services/period";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import dayjs from "dayjs";
import {
    useLazyGetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdQuery
} from "../../api/services/performanceCards";
import {useLazyGetApiPerformanceMatrixGetPerformanceMatrixQuery} from "../../api/services/PerformanceMatrix";
import moment from "moment";


const Reports: React.FC = () => {
    const [selectedBlock, setSelectedBlock] = useState<any | undefined>()
    const [selectedPeriod, setSelectedPeriod] = useState<PeriodDto | undefined>();
    const [fetchActivePeriods, {data: activePeriods}] = useLazyGetApiPeriodWithCardsQuery()
    const [fetchCardsForPeriod, {data: cards}] = useLazyGetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdQuery()
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch()
    const [fetchPerformanceMatrix, {data: performanceMatrix}] = useLazyGetApiPerformanceMatrixGetPerformanceMatrixQuery()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (user) {
            getActivePeriods()
        }
    }, [user])


    const getActivePeriods = async () => {
        try {
            setIsLoading(true)
            var periods = await fetchActivePeriods().unwrap()

            var finished = periods.filter(k => dayjs(k.endDate).isBefore())
            if (finished.length > 0) {
                var first = finished[0]
                setSelectedPeriod(first);
                await getCardsForPeriod(first.id ?? "")
                await getPerformanceMatrix(first.id ?? "")
            } else {
                var firstPeriod = periods?.[0]
                setSelectedPeriod(firstPeriod);
                await getCardsForPeriod(firstPeriod.id ?? "")
                await getPerformanceMatrix(firstPeriod.id ?? "")
            }

            setIsLoading(false)
        } catch (err: any) {
            if (err.status != "PARSING_ERROR") {
                dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
                setIsLoading(false)
            }
        }
    }

    const getPerformanceMatrix = async (periodId: string) => {
        return fetchPerformanceMatrix({
            periodId: periodId
        }).unwrap()
    }

    const getCardsForPeriod = async (periodId: string) => {
        return fetchCardsForPeriod({
            periodId: periodId,
        }).unwrap()
    }
    const showDetail = (block: string) => {
        setSelectedBlock(block)
    }

    var getLegendTemplate = (color: string, title: string, desc: string, point: string, range: string) => (
        <Popover content={
            <>
                <p className={"font-medium"}>{desc}</p>
                <p className={"font-medium"}>{point}%</p>
                <p className={"font-light"}>{range}</p>
            </>
        }
                 overlayStyle={{maxWidth: 200}}
                 title={title}
                 overlayInnerStyle={{backgroundColor: color}}>
            <Card style={{backgroundColor: color}} className={"cursor-pointer min-w-[150px]"}>
                <p className={"font-semibold text-center"}>{title}</p>
            </Card>
        </Popover>
    )

    const getLegendData = () => {
        if (cards && cards.length > 0) {
            return cards?.[0].scale?.evaluationMetrics
        }

        return []
    }

    const getCellData = (rowIndex: number, colIndex: number) => {
        var cells = (performanceMatrix as any)?.cells
        if (cells && cells.length > 0) {
            var cell = cells.find((k: any) => k.whatId == rowIndex && k.howId == colIndex)

            return cell
        }

        return null
    }

    return (<>
        <Spin spinning={isLoading} className={"min-h-full"}>
            {isLoading && (<div className={"h-[300px]"}></div>)}
            {!isLoading &&
                <>
                    <div className={"flex flex-col"}>
                        <div className={"flex flex-row items-baseline"}>
                            <text className={"font-semibold mr-2"}>Seçili Dönem:</text>
                            <Select
                                placeholder="Dönem Seçin"
                                onChange={async (value) => {
                                    var v = (activePeriods ?? []).find(k => k.id == value)
                                    setSelectedPeriod(v)
                                    await getCardsForPeriod(v?.id ?? "")
                                    await getPerformanceMatrix(v?.id ?? "")
                                }}
                                style={{width: 300}}
                                value={selectedPeriod?.id || undefined} // Seçili eleman
                                showSearch={true}
                                filterOption={(input, option) =>
                                    `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                                }
                                options={((activePeriods ?? []) as PeriodDto[]).map(k => {
                                        return {value: k.id, label: `${k.periodName}`};
                                    }
                                )}
                            />
                        </div>
                        {
                            moment(selectedPeriod?.endDateSecondCalibration).isAfter() &&
                            <>
                                <Row className={"h-[400px] p-3"}>
                                    <Col span={24} className={"bg-[#E5E1DA] rounded-md h-full p-3"}>
                                        <div className={"h-full w-full flex justify-center items-center"}>
                                            <Alert message={"Henüz değerlendirmeler tamamlanmadı"}
                                                   type="warning"
                                                   className={"w-2/3 text-center"}/>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                        {
                            moment(selectedPeriod?.endDateSecondCalibration).isBefore() &&
                            <>
                                <div className="flex mt-8 align-center justify-center">
                                    <table
                                        className="border-collapse text-center rounded-lg overflow-hidden border-1 border-black ">
                                        <thead>
                                        <tr>
                                            <th rowSpan={2}
                                                className="diagonal border border-gray-300 bg-[#00906e] font-bold text-white p-2 w-[150px]"
                                            >
                                                <div className="text-top">BECERİLER</div>
                                                <div className="text-bottom">İŞ<br/> SONUÇLARI</div>
                                            </th>
                                            <th
                                                rowSpan={2}
                                                className="border border-gray-300 bg-[#00906e] font-bold p-2 text-white"
                                            >
                                                DEĞERLENDİRME KATEGORİSİ
                                            </th>

                                            <th
                                                colSpan={[...((performanceMatrix as any)?.whats ?? [])].length}
                                                className="border border-gray-300 bg-[#00906e] font-bold p-2 text-white"
                                            >
                                                "NASIL YAPTIK"
                                            </th>
                                            <th
                                                rowSpan={2}
                                                className="border border-gray-300 bg-[#00906e] font-bold p-2 text-white"
                                            >
                                                MAX KOTA<br/>(NE YAPTIK)
                                            </th>
                                        </tr>
                                        <tr>
                                            {[...((performanceMatrix as any)?.hows ?? [])]
                                                .sort((a, b) => a.maxScore - b.maxScore)
                                                .map((k: any) =>
                                                    <th className="border border-gray-300 bg-[#c8ede5] p-2">
                                                        {k.result}
                                                    </th>
                                                )}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {[...((performanceMatrix as any)?.whats ?? [])]
                                            .sort((a, b) => b.maxScore - a.maxScore)
                                            .map((row: any, rowIndex: number) => (
                                                <tr>
                                                    {rowIndex == 0 &&
                                                        <td
                                                            rowSpan={[...((performanceMatrix as any)?.whats ?? [])].length}
                                                            className="border border-gray-300 bg-[#c8ede5] font-bold p-2"
                                                        >
                                                            "NE YAPTIK"
                                                        </td>
                                                    }
                                                    <td className="border border-gray-300 bg-[#c8ede5] p-2 h-[80px] w-[150px]  font-bold">{row.result}</td>
                                                    {[...((performanceMatrix as any)?.hows ?? [])]
                                                        .sort((a, b) => a.maxScore - b.maxScore)
                                                        .map((col: any, colIndex: number) => {
                                                            var data = getCellData(row.id, col.id)

                                                            return (
                                                                <td
                                                                    className="border h-[80px] w-[160px]">
                                                                    <Button style={{
                                                                        backgroundColor: data?.color ?? "",
                                                                        borderColor: "white",
                                                                        color: "black"
                                                                    }}
                                                                            className={"w-full h-full rounded-none transform hover:scale-110 hover:text-black hover:rounded-md hover:z-50"}
                                                                            onClick={() => {
                                                                                if (data.users.length > 0)
                                                                                    showDetail(data)
                                                                            }}>

                                                                        <div
                                                                            className={"flex flex-col items-center justify-center"}>
                                                                            <span className={"font-semibold text-[16px]"}>{((data?.count / (data?.ratio == 0 ? 1 : data?.ratio)) * 100).toFixed(2)}%</span>
                                                                            <span className={"text-[12px] -mt-1"}>{data?.count} Kişi</span>
                                                                        </div>
                                                                    </Button>

                                                                </td>
                                                            )
                                                        })
                                                    }

                                                    <td className="border border-gray-300 p-2 h-[80px] w-[150px]  font-bold">{row.quotaResult}%</td>
                                                </tr>
                                            ))}
                                        <tr>
                                            <td
                                                colSpan={2}
                                                className="border border-gray-300 bg-[#c8ede5] font-bold p-2 h-[80px]"
                                            >
                                                MAX KOTA (NASIL YAPTIK)
                                            </td>

                                            {[...((performanceMatrix as any)?.hows ?? [])]
                                                .sort((a, b) => a.maxScore - b.maxScore)
                                                .map((k: any) =>
                                                    <td className="border border-gray-300 p-2 font-bold">{k.quotaResult}%</td>
                                                )}

                                            <td className="border border-gray-300 p-2 font-bold">%100</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex flex-row mt-8 justify-between">
                                    {
                                        [...(getLegendData() ?? [])]
                                            .sort((k, l) => (k.order ?? 0) - (l.order ?? 0))
                                            .map(k => {
                                                return getLegendTemplate(k.color ?? "", k.personelType ?? "", k.description ?? "", k.ratio ?? "", k.pointRange ?? "")
                                            })
                                    }
                                </div>
                            </>
                        }
                    </div>
                </>}
        </Spin>

        <ReportDetail
            isModalActive={selectedBlock !== undefined}
            toggleModal={() => setSelectedBlock(undefined)}
            blockDetails={selectedBlock}
            matrix={performanceMatrix}
            period={selectedPeriod}
        />
    </>)
};

export default Reports;