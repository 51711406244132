import React, {useEffect, useState} from 'react';
import {Button, Card, ColorPicker, Form} from "antd";
import {useForm} from "antd/es/form/Form";
import {
    CompanySettingDto,
    useLazyGetApiMCompanySettingsByCompanyIdQuery,
    usePostApiMCompanySettingsMutation
} from "../../../api/services/MCompany";
import {setError, setLoading} from "../../../features/app/appSlice";
import {t} from "i18next";
import {useDispatch} from "react-redux";

export interface IMCompanyDetailsTheme {
    companyId: string;
}

const MCompanyDetailsTheme: React.FC<IMCompanyDetailsTheme> = ({companyId}) => {
    const defaultColors = ["#89A8B2", "#B3C8CF", "#F1F0E8"]
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [fetchSettings, {data: settings}] = useLazyGetApiMCompanySettingsByCompanyIdQuery()
    const [saveSetting] = usePostApiMCompanySettingsMutation()
    const [update, setUpdate] = useState(false)
    useEffect(() => {
        if (companyId)
            getColors()
    }, [companyId]);

    const getColors = async () => {
        try {
            dispatch(setLoading(true));
            await fetchSettings({
                companyId: companyId,
                keys: ["primaryColor", "secondaryColor", "ternaryColor"]
            }).unwrap()
            setUpdate(!update)
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Renkler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }
    const saveColors = async () => {
        try {
            dispatch(setLoading(true));
            await saveSetting({
                body: [
                    {
                        companyId: companyId,
                        key: "primaryColor",
                        value: form.getFieldValue("primaryColor")
                    } as CompanySettingDto,
                    {
                        companyId: companyId,
                        key: "secondaryColor",
                        value: form.getFieldValue("secondaryColor")
                    } as CompanySettingDto,
                    {
                        companyId: companyId,
                        key: "ternaryColor",
                        value: form.getFieldValue("ternaryColor")
                    } as CompanySettingDto
                ]
            }).unwrap()
            await getColors()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Renkler kaydedilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getColor = (key: string) => {
        let val = (settings as any[])?.find(k => k.key == key)?.value

        if (key === "primaryColor") {
            if (val) {
                form.setFieldsValue({"primaryColor": val});
                return val;
            } else
                return defaultColors[0]
        } else if (key === "secondaryColor") {
            if (val) {
                form.setFieldsValue({"secondaryColor": val});
                return val;
            } else
                return defaultColors[1]
        } else if (key === "ternaryColor") {
            if (val) {
                form.setFieldsValue({"ternaryColor": val});
                return val;
            } else
                return defaultColors[2]
        }
    }
    return (<>
        <Card title={
            <div className={"flex flex-row justify-between items-center"}>
                <span>Şirket Renkleri</span>
                <div>
                    <Button type={"primary"} onClick={() => {
                        saveColors()
                    }}> Kaydet </Button>
                </div>
            </div>
        }
        >
            <Form form={form} layout={"inline"}>
                <Form.Item
                    label={"Birincil Renk"}
                    name={[`primaryColor`]}
                    initialValue={getColor("primaryColor")}
                >
                    <ColorPicker
                        defaultFormat={"hex"}
                        onChangeComplete={(color) => {
                            var strColor = color.toHexString()
                            form.setFieldsValue({"primaryColor": strColor});
                        }}
                        showText/>
                </Form.Item>
                <Form.Item
                    label={"İkincil Renk"}
                    name={["secondaryColor"]}
                    initialValue={getColor("secondaryColor")}
                >
                    <ColorPicker defaultFormat={"hex"}
                                 onChangeComplete={(color) => {
                                     var strColor = color.toHexString()
                                     form.setFieldsValue({"secondaryColor": strColor});
                                 }}
                                 showText/>
                </Form.Item>
                <Form.Item
                    label={"Üçüncül Renk"}
                    name={["ternaryColor"]}
                    initialValue={getColor("ternaryColor")}
                >
                    <ColorPicker  defaultFormat={"hex"}
                                 onChangeComplete={(color) => {
                                     var strColor = color.toHexString()
                                     form.setFieldsValue({"ternaryColor": strColor});
                                 }}
                                 showText/>
                </Form.Item>
            </Form>
        </Card>

    </>)
};

export default MCompanyDetailsTheme;