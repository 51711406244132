import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiMCompanyCompanies: build.query<
      GetApiMCompanyCompaniesApiResponse,
      GetApiMCompanyCompaniesApiArg
    >({
      query: () => ({ url: `/api/MCompany/companies` }),
    }),
    postApiMCompanyCompanies: build.mutation<
      PostApiMCompanyCompaniesApiResponse,
      PostApiMCompanyCompaniesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/companies`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getApiMCompanyCompaniesByGroup: build.query<
      GetApiMCompanyCompaniesByGroupApiResponse,
      GetApiMCompanyCompaniesByGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/companies/byGroup`,
        params: { groupId: queryArg.groupId },
      }),
    }),
    getApiMCompanyCompaniesById: build.query<
      GetApiMCompanyCompaniesByIdApiResponse,
      GetApiMCompanyCompaniesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/MCompany/companies/${queryArg.id}` }),
    }),
    putApiMCompanyCompaniesById: build.mutation<
      PutApiMCompanyCompaniesByIdApiResponse,
      PutApiMCompanyCompaniesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/companies/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteApiMCompanyCompaniesById: build.mutation<
      DeleteApiMCompanyCompaniesByIdApiResponse,
      DeleteApiMCompanyCompaniesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/companies/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    postApiMCompanyUpdateadminpassword: build.mutation<
      PostApiMCompanyUpdateadminpasswordApiResponse,
      PostApiMCompanyUpdateadminpasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/updateadminpassword`,
        method: "POST",
        params: { companyId: queryArg.companyId },
      }),
    }),
    postApiMCompanySetting: build.mutation<
      PostApiMCompanySettingApiResponse,
      PostApiMCompanySettingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/setting`,
        method: "POST",
        body: queryArg.companySettingDto,
      }),
    }),
    postApiMCompanySettings: build.mutation<
      PostApiMCompanySettingsApiResponse,
      PostApiMCompanySettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/settings`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getApiMCompanySettingsByCompanyId: build.query<
      GetApiMCompanySettingsByCompanyIdApiResponse,
      GetApiMCompanySettingsByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompany/settings/${queryArg.companyId}`,
        params: { keys: queryArg.keys },
      }),
    }),
    getApiMCompanyGroupsCompanygroups: build.query<
      GetApiMCompanyGroupsCompanygroupsApiResponse,
      GetApiMCompanyGroupsCompanygroupsApiArg
    >({
      query: () => ({ url: `/api/MCompanyGroups/companygroups` }),
    }),
    postApiMCompanyGroupsCompanygroups: build.mutation<
      PostApiMCompanyGroupsCompanygroupsApiResponse,
      PostApiMCompanyGroupsCompanygroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompanyGroups/companygroups`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getApiMCompanyGroupsCompanygroupsById: build.query<
      GetApiMCompanyGroupsCompanygroupsByIdApiResponse,
      GetApiMCompanyGroupsCompanygroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompanyGroups/companygroups/${queryArg.id}`,
      }),
    }),
    putApiMCompanyGroupsCompanygroupsById: build.mutation<
      PutApiMCompanyGroupsCompanygroupsByIdApiResponse,
      PutApiMCompanyGroupsCompanygroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompanyGroups/companygroups/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteApiMCompanyGroupsCompanygroupsById: build.mutation<
      DeleteApiMCompanyGroupsCompanygroupsByIdApiResponse,
      DeleteApiMCompanyGroupsCompanygroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/MCompanyGroups/companygroups/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiMCompanyCompaniesApiResponse = unknown;
export type GetApiMCompanyCompaniesApiArg = void;
export type PostApiMCompanyCompaniesApiResponse = unknown;
export type PostApiMCompanyCompaniesApiArg = {
  body: {
    Name?: string;
    Address?: string;
    Email?: string;
    BaseUrl?: string;
    StartDate?: string;
    EndDate?: string;
    UserSyncUrl?: string;
    CompanyGroupId?: string;
    Favicon?: Blob;
    LoginIcon?: Blob;
    Logo?: Blob;
  };
};
export type GetApiMCompanyCompaniesByGroupApiResponse = unknown;
export type GetApiMCompanyCompaniesByGroupApiArg = {
  groupId?: string;
};
export type GetApiMCompanyCompaniesByIdApiResponse = unknown;
export type GetApiMCompanyCompaniesByIdApiArg = {
  id: string;
};
export type PutApiMCompanyCompaniesByIdApiResponse = unknown;
export type PutApiMCompanyCompaniesByIdApiArg = {
  id: string;
  body: {
    Name?: string;
    Address?: string;
    Email?: string;
    BaseUrl?: string;
    StartDate?: string;
    EndDate?: string;
    UserSyncUrl?: string;
    CompanyGroupId?: string;
    Favicon?: Blob;
    LoginIcon?: Blob;
    Logo?: Blob;
  };
};
export type DeleteApiMCompanyCompaniesByIdApiResponse = unknown;
export type DeleteApiMCompanyCompaniesByIdApiArg = {
  id: string;
};
export type PostApiMCompanyUpdateadminpasswordApiResponse = unknown;
export type PostApiMCompanyUpdateadminpasswordApiArg = {
  companyId?: string;
};
export type PostApiMCompanySettingApiResponse = unknown;
export type PostApiMCompanySettingApiArg = {
  companySettingDto: CompanySettingDto;
};
export type PostApiMCompanySettingsApiResponse = unknown;
export type PostApiMCompanySettingsApiArg = {
  body: CompanySettingDto[];
};
export type GetApiMCompanySettingsByCompanyIdApiResponse = unknown;
export type GetApiMCompanySettingsByCompanyIdApiArg = {
  companyId: string;
  keys?: string[];
};
export type GetApiMCompanyGroupsCompanygroupsApiResponse = unknown;
export type GetApiMCompanyGroupsCompanygroupsApiArg = void;
export type PostApiMCompanyGroupsCompanygroupsApiResponse = unknown;
export type PostApiMCompanyGroupsCompanygroupsApiArg = {
  body: {
    GroupName?: string;
    Nickname?: string;
    StartDate?: string;
    EndDate?: string;
    Favicon?: Blob;
    LoginIcon?: Blob;
    Logo?: Blob;
  };
};
export type GetApiMCompanyGroupsCompanygroupsByIdApiResponse = unknown;
export type GetApiMCompanyGroupsCompanygroupsByIdApiArg = {
  id: string;
};
export type PutApiMCompanyGroupsCompanygroupsByIdApiResponse = unknown;
export type PutApiMCompanyGroupsCompanygroupsByIdApiArg = {
  id: string;
  body: {
    GroupName?: string;
    Nickname?: string;
    StartDate?: string;
    EndDate?: string;
    Favicon?: Blob;
    LoginIcon?: Blob;
    Logo?: Blob;
  };
};
export type DeleteApiMCompanyGroupsCompanygroupsByIdApiResponse = unknown;
export type DeleteApiMCompanyGroupsCompanygroupsByIdApiArg = {
  id: string;
};
export type CompanySettingDto = {
  companyId?: string;
  key?: string | null;
  value?: string | null;
  exp?: string | null;
};
export const {
  useGetApiMCompanyCompaniesQuery,
  useLazyGetApiMCompanyCompaniesQuery,
  usePostApiMCompanyCompaniesMutation,
  useGetApiMCompanyCompaniesByGroupQuery,
  useLazyGetApiMCompanyCompaniesByGroupQuery,
  useGetApiMCompanyCompaniesByIdQuery,
  useLazyGetApiMCompanyCompaniesByIdQuery,
  usePutApiMCompanyCompaniesByIdMutation,
  useDeleteApiMCompanyCompaniesByIdMutation,
  usePostApiMCompanyUpdateadminpasswordMutation,
  usePostApiMCompanySettingMutation,
  usePostApiMCompanySettingsMutation,
  useGetApiMCompanySettingsByCompanyIdQuery,
  useLazyGetApiMCompanySettingsByCompanyIdQuery,
  useGetApiMCompanyGroupsCompanygroupsQuery,
  useLazyGetApiMCompanyGroupsCompanygroupsQuery,
  usePostApiMCompanyGroupsCompanygroupsMutation,
  useGetApiMCompanyGroupsCompanygroupsByIdQuery,
  useLazyGetApiMCompanyGroupsCompanygroupsByIdQuery,
  usePutApiMCompanyGroupsCompanygroupsByIdMutation,
  useDeleteApiMCompanyGroupsCompanygroupsByIdMutation,
} = injectedRtkApi;
