import React, {useEffect, useState} from 'react';
import {Card, Col, Divider, Row, Statistic} from "antd";
import {PerformanceResultTypes} from "./PerformanceResultType";
import {IDetailItem} from "../data";
import {setError, setLoading} from "../../../features/app/appSlice";
import dayjs from "dayjs";
import {t} from "i18next";
import {PerformanceCardDto, PeriodDto, useLazyGetApiPeriodAllQuery} from "../../../api/services/period";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {
    ManagerEvaluationDto,
    useLazyGetApiManagerEvaluationGetCompletionInfoQuery,
    useLazyGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery
} from "../../../api/services/ManagerEvaluation";
import {
    useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery,
    useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery
} from "../../../api/services/performanceCards";
import {ScaleDetailDto} from "../../../api/services/scale";

export interface IPerformanceResultsTabProps {
    selectedItem: any | undefined
}

const PerformanceResultsTabProps: React.FC<IPerformanceResultsTabProps> = ({selectedItem}) => {
    const [selectedPeriod, setSelectedPeriod] = useState<PeriodDto | undefined>();
    const [fetchActivePeriods, {data: activePeriods}] = useLazyGetApiPeriodAllQuery()
    const user = useSelector((state: RootState) => state.auth?.user);
    const dispatch = useDispatch()
    const [getCompletion] = useLazyGetApiManagerEvaluationGetCompletionInfoQuery()
    const [evaluationCompleted, setEvaluationCompleted] = useState(false)
    const [getManagerEvaluations, {data: oldEvaluations}] = useLazyGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery()
    const [selectedEvaluation, setSelectedEvaluation] = useState<ManagerEvaluationDto | undefined>(undefined)
    const [fetchPerformanceCards, {data: performanceCardList}] = useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery()
    const [getCardForPeriod] = useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery()
    const [performanceCard, setPerformanceCard] = useState<PerformanceCardDto | undefined>(undefined)
    const [translatedValue, setTranslatedValue] = useState<any | undefined>()

    useEffect(() => {
        if (selectedItem) {
            console.log(selectedItem)
            setSelectedPeriod(selectedItem.period);
            FetchEvaluationDetails(selectedItem.period)
        }
    }, [selectedItem]);

    const FetchCardForPeriod = async (sp: PeriodDto, evaluation: any) => {

        try {
            var cards = await getCardForPeriod({
                employeeId: selectedItem?.id ?? "",
                periodId: sp?.id ?? "",
            }).unwrap()

            if (cards.length > 0) {
                var card = cards[0]
                setPerformanceCard(card)

                translateEvaluations(card?.scale?.scaleDetails, evaluation)
            } else {
                setPerformanceCard(undefined)
            }

        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Periyoda ait kart çekilirken hata oluştu')));
        }
    }
    const FetchEvaluationDetails = async (selectedPeriod: PeriodDto) => {
        if (selectedPeriod == undefined)
            return

        try {
            var evaluations = await getManagerEvaluations({
                periodId: selectedPeriod?.id ?? "",
                userId: selectedItem?.id ?? "",
            }).unwrap()

            FetchCardForPeriod(selectedPeriod, evaluations)
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Periyoda ait kart çekilirken hata oluştu')));
        } finally {
        }
    }

    const checkSelectedPeriod = (selectedPeriod: PeriodDto) => {
        FetchEvaluationDetails(selectedPeriod)
    }

    const getEvaluations = () => {
        // @ts-ignore
        if (oldEvaluations && oldEvaluations?.length > 0) {
            // @ts-ignore
            return oldEvaluations[0]
        }

        return null
    }

    const translateEvaluations = (scale: ScaleDetailDto[] | undefined | null, evaluation: any) => {
        if (!scale) return

        var ev = evaluation
        if (ev.length == 0) return;

        var sorted1 = [...scale].filter(k => k.evaluationType == 1).sort((a, b) => (b.maxScore ?? 0) - (a.maxScore ?? 0))
        var sorted2 = [...scale].filter(k => k.evaluationType == 2).sort((a, b) => (b.maxScore ?? 0) - (a.maxScore ?? 0))

        var pr: any = {
            how: scale.find(k => k.id == ev[0]?.managerHow),
            calibratedHow: scale.find(k => k.id == ev[0]?.firstCalibrationHow),
            what: scale.find(k => k.id == ev[0]?.managerWhat),
            calibratedWhat: scale.find(k => k.id == ev[0]?.firstCalibrationWhat),

        }

        var calibratedHowindex = sorted2.find(k => k.id == pr.calibratedHow?.id)
        var howindex = sorted2.find(k => k.id == pr.how?.id)
        var whatindex = sorted1.find(k => k.id == pr.what?.id)
        var calibratedWhatindex = sorted1.find(k => k.id == pr.calibratedWhat?.id)


        pr = {
            ...pr, ...{
                howi: howindex ? sorted2.indexOf(howindex) + 1 : null,
                whati: whatindex ? sorted1.indexOf(whatindex)  + 1 : null,

                calibratedHowi: calibratedHowindex ? sorted2.indexOf(calibratedHowindex)  + 1 : null,
                calibratedWhati: calibratedWhatindex ? sorted1.indexOf(calibratedWhatindex) + 1 : null,
            }
        }

        setTranslatedValue(pr)
    }


    return (<>
        <div className={"flex flex-col h-full w-full"}>
            {
                dayjs(selectedPeriod?.endDateFirstManager).isBefore() &&
                <Col span={24} className={"bg-[#E5E1DA] rounded-md w-full p-3"}>
                    <div>
                        <Divider orientation="left">Performans Boyutu</Divider>
                        {getEvaluations()?.managerWhat &&
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Card bordered={false}>
                                        <Statistic
                                            title="İş Sonuçlarım (What)"
                                            value={translatedValue?.what?.result ?? ""}
                                            valueStyle={{color: '#3f8600'}}
                                            prefix={PerformanceResultTypes.GetType(translatedValue?.whati)?.icon}
                                        />
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card bordered={false}>
                                        <Statistic
                                            title="İş Sonuçlarına Nasıl Ulaştığım (How)"
                                            value={translatedValue?.how?.result ?? ""}
                                            valueStyle={{color: '#3f8600'}}
                                            prefix={PerformanceResultTypes.GetType(translatedValue?.howi)?.icon}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        }
                        {
                            !(getEvaluations()?.managerWhat) &&
                            <Card bordered={false}>
                                <Statistic
                                    valueStyle={{fontSize: 14, textAlign: "center"}}
                                    value={"Değerlendirme henüz tamamlanmadı"}
                                />
                            </Card>
                        }
                    </div>
                    <div className={"mt-[50px]"}>
                        <Divider orientation="left">Kalibre Edilmiş Performans Boyutu</Divider>
                        {getEvaluations()?.firstCalibrationWhat &&
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Card bordered={false}>
                                        <Statistic
                                            title="İş Sonuçlarım (What)"
                                            value={translatedValue?.calibratedWhat?.result ?? ""}
                                            valueStyle={{color: '#3f8600'}}
                                            prefix={PerformanceResultTypes.GetType(translatedValue?.calibratedWhati ?? 0)?.icon}
                                        />
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card bordered={false}>
                                        <Statistic
                                            title="İş Sonuçlarına Nasıl Ulaştığım (How)"
                                            value={translatedValue?.calibratedHow?.result ?? ""}
                                            valueStyle={{color: '#3f8600'}}
                                            prefix={PerformanceResultTypes.GetType(translatedValue?.calibratedHowi ?? 0)?.icon}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        }
                        {
                            !(getEvaluations()?.firstCalibrationWhat) &&
                            <Card bordered={false}>
                                <Statistic
                                    valueStyle={{fontSize: 14, textAlign: "center"}}
                                    value={"Kalibrasyon henüz tamamlanmadı"}
                                />
                            </Card>
                        }
                    </div>
                </Col>
            }
        </div>
    </>)
};

export default PerformanceResultsTabProps;